import styled from "styled-components";
import { StyledTextField } from "../../elements/text-field";
import { StyledGrid } from "../../elements/grid";
import { StyledBox } from "../../elements/box";
import { StyledButton } from "../../elements/button";
import { StyledAvatar } from "../../elements/avatar";
import { StyledTypography } from "../../elements/typography";
import CardMedia from "@material-ui/core/CardMedia";
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked";

import { palette } from "../../themes/color-palette";
export const ContainerStyledBox = styled(({ ...props }) => <StyledBox {...props} />)`
  ${({ theme }) => `

  &.precio-promocion-container {    
    margin-bottom: 10px;
  }
  
  .product-price-card {
    padding: 10px;
    min-height: 132px;
  }

  &.ahorra-mas-container {
    min-height: 50px;
    display: flex;
    justify-content: left;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  &.ahorra-mas-subcontainer {
    width: 75%;
    margin-right: 10px;    
  }

  &.input-less-more {
    display: flex;
    margin-top: 10px;
    height: 50px;
    align-items: flex-end;
  }

  &.button-agregar {
    margin-left: 5px;
    width: 100%;
  }

  &.promoicons-container {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  &.assigned-promo {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  &.notAssigned-promo {
    margin-bottom: 10px;
  }

  &.promoicon-container {
    height: 22px;
    display: flex;
    justify-content: left;
    align-items: center;
    margin-bottom: 5px;
  }

  &.aplicar-container {
    min-height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 10px;
  }

  &.dialog-ahorramas-container {
    display: flex;
    height: 40px;    
  }

  &.dialog-titulo {    
    padding: 10px;   
  }

  &.dialog-close {    
    height: 40px;   
    display: flex;
    justify-content: flex-end;
    padding-right: 10px;
  }

  &.dialog-seccionone {
    padding: 10px;
    margin-top: 10px;
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 5px;
    background-color: ${palette.gray[50]}
  }

  &.dialog-seccionagrega {
    margin-bottom: 10px;
  }

  &.dialog-marca-descripcion {
    margin-left: 5px;
  }

  &.dialog-secciontwo {
    margin-left: 10px;
    margin-right: 10px;
    padding: 10px;
  }

  &.dialog-seccionobten {
    margin-bottom: 10px;
  }

  &.dialog-seccion-productos {
    margin-left: 10px;
    margin-right: 10px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 10px;
  }

  &.dialog-seccionthree {
    margin-left: 10px;
    margin-right: 10px;
    padding: 10px;
  }

  &.producto-gratis-container {
    min-height: 50px;
    display: flex;
    justify-content: left;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  &.producto-gratis-subcontainer {
    width: 75%;
    margin-right: 10px;    
  }

  &.radio-container {
    display: flex;
    width: 60px !important; 
    min-width: 60px !important; 
    height: 90px;
    align-items: center;
    justify-content: center;
  }

  &.radio-container-selected {
    display: flex;
    width: 60px !important;
    min-width: 60px !important; 
    height: 90px;
    align-items: center;
    justify-content: center;
    background-color: ${palette.primary.main}
  }

  &.product-title-text {
    height: 38px;
    overflow: hidden;
    width: 200px;

    ${theme.breakpoints.down("md")} {
      width: 100%;
    }
    ${theme.breakpoints.down("xs")} {
      width: 150px;
    }

  }
  
  .price-preferentialprice-wording {
    display: flex;
    height: 40px;
    align-items: center;
    background-color: ${palette.gray["50"]};
    padding: 10px;
  }

  .styled--errorsharp-icon {
    width: 16px;
    height: 16px;
  }

  `}
`;

export const ContainerStyledTypography = styled(({ ...props }) => <StyledTypography {...props} />)`
  ${({ theme }) => `

  &.aplicar-text {
    color: ${palette.secondary.main};
    cursor: pointer;
  }

  &.ahorramas-text {
    margin-left: 5px;
  }

  &.productogratis-text {
    margin-left: 10px;
  }
 
  &.dialog-textsku {
    color: ${palette.gray["300"]};
    margin-top: 10px;
  }

  &.producto-gratis-sku {
    color: ${palette.gray["300"]};
  }

  &.producto-verdetalle {
    color: ${palette.secondary.main};
  }

  &.terminos-condiciones {
    color: ${palette.secondary.main};
    cursor: pointer;
  }

`}
`;

export const ContainerStyledAvatar = styled(({ ...props }) => <StyledAvatar {...props} />)`
  ${({ theme }) => `

  &.dialog-numberone {
    width: 20px;
    height: 20px;    
    background-color: #F96302 !important;
  }
`}
`;

export const ContainerStyledButton = styled(({ ...props }) => <StyledButton {...props} />)`
  ${({ theme }) => `
  

`}
`;

export const ContainerStyledTextField = styled(({ ...props }) => <StyledTextField {...props} />)`
  ${({ theme }) => `
  
  &.MuiFormControl-root.MuiTextField-root {
    margin-bottom: 10px;
  }

  &.dialog-inputProductBox {
    width: 56px;
    background-color: ${palette.background.paper};
    border: 0px solid #CCCCCC;

  }
  
`}
`;

export const ContainerStyledCardMedia = styled(({ ...props }) => <CardMedia {...props} />)`
  ${({ theme }) => `  

&.promo-remate {
  width: 49px;
  height: 22px;
}

&.promo_plp-ribbon-gr {
  width: 49px;
  height: 22px;
}

&.promo_plp-ribbon-sb {
  width: 37px;
  height: 24px;
}

&.promo_plp-ribbon-nlp {
  width: 30px;
  height: 30px;
}

&.promo_plp-ribbon-combo {
  width: 58px;
  height: 22px;
}

&.promo_plp-ribbon-fp {
  width: 82px;
  height: 22px;
}

&.promo_plp-ribbon-nxm {
  width: auto;
  height: 22px;
}

&.promo_plp-ribbon-ahp {
  width: 38px;
  height: 22px;
}

&.promo_plp-ribbon-nxms {
  width: auto;
  height: 22px;
}

&.promo_plp-ribbon-cp {
    width: 28px;
    height: 22px;
}

&.dialog-ahorramas-image {
    width: 60px;
    height: 60px;
}

&.selected-productogratis-image {
    width: 90px;
    height: 90px;
}

&.list-productogratis-image {
    width: 60px;
    height: 60px;
}

`}
`;

export const ContainerRadioCardGrid = styled(({ ...props }) => <StyledGrid {...props} />)`
  ${({ theme }) => `
    width: 100%;
    display:flex;
    height: 90px;
    align-items: center;

    margin-bottom: 10px;
    &.container{
      outline: 1px solid ${theme.palette.secondary.main};
      border-radius: 3px;
    }

    &:hover {
      outline: 1px solid ${theme.palette.primary.main};
      cursor: pointer;
    }

    &.storeChecked{
      outline: 1px solid ${theme.palette.success.main};
    }
    &.successIconRadio{
      justify-content: right;
    }
  `}
`;

export const ContainerUncheckedRadioIcon = styled(({ ...props }) => <RadioButtonUncheckedIcon {...props} />)`
  ${({ theme }) => `

    &.radioicon-style {
        color: ${palette.gray["100"]} !important;
        font-size: 20px;
    }
    &.radioicon-style-selected {
        color: ${palette.background.paper} !important;
        font-size: 20px;
    }
    
`}
`;

export const Span = styled.span`
  &.msi-text {
    font-size: 15px;
    font-weight: 600;
  }
  &.msi-disponible-text {
    margin-left: 5px;
    font-size: 15px;
    font-weight: 600;
    color: #00a950;
  }
  &.msi-precio-text {
    font-size: 12px;
    font-weight: 600;
  }
  &.msi-meses-text {
    margin-left: 5px;
    font-size: 12px;
    font-weight: 400;
  }
  &.msi-symbol {
    font-size: 8px;
    font-weight: 400;
  }
  &.msi-ver-opciones {
    margin-left: 5px;
    font-size: 12px;
    font-weight: 400;
    color: #3e7697;
    cursor: pointer;
  }
`;

export const Div = styled.div`
  &.msi-container {
    display: flex;

    svg {
      width: 28px;
      height: 22px;
    }
  }
`;
