import React, { ChangeEvent, FormEvent, useEffect, useState } from "react"
import { Divider } from "@material-ui/core"
import { StyledBox, StyledButton, StyledContainer, StyledGrid, StyledLink, StyledLinkTextSecondary, StyledMenuItem, StyledTextField, StyledTypography } from "../../../hdm"
import ProCustomStepperLabel from "../../custom-components/shared/ProCustomStepperLabel"
import SpecialTitleHDM from "../../custom-components/special-title/SpecialTItleHDM"
import { StyledSelectHdm } from "../../custom-components/styled-select"
import styled from "styled-components"
import { EMPTY_STRING, OK } from "../../../constants/common"
import { useTranslation } from "react-i18next"
import getDisplayName from "react-display-name"
import { useSelector } from "react-redux"
import { currentStoreSelector } from "../../../redux/selectors/current-store"
import { AssociatedAccounts } from "../../../interfaces/ProLoginInterfaces"
import Axios, { Canceler } from "axios";
import addressUtil from "../../../utils/addressUtil"
import { useSite } from "../../../_foundation/hooks/useSite"
import { getAssociatedAccounts } from "../../../hdm/services/getAssociatedAccounts.service"
import personService from "../../../_foundation/apis/transaction/person.service"
import * as ROUTES from "../../../constants/routes";
import { ResetPassword } from "../../widgets/reset-password"
import ChangePassword from "../../widgets/personal-information/ChangePassword"
import EspotHdm from "../../custom-components/shared/EspotHdm"
import { tabletBreakpoint } from "../../../hdm/functions/utils"
import { useMediaQuery } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import { onlineStoreRdc } from "../../../redux/selectors/hdmData"
import { TaskAltIcon } from "../../../hdm/components/svg-icons"
import { palette } from "../../../hdm/themes"
import { ButtonWithLoader } from "../../custom-components/skeleton-loader/components/ButtonWithLoader"
import { genericErrorSelector } from "../../../redux/selectors/error"
import { useNavigate } from "react-router"
import { SIGNIN } from "../../../constants/routes"

const ProForgotPassContainer = styled(({ ...props }) => <StyledContainer {...props} />)`
  ${({ theme, start, end }) => `

  &.forgot-password-conatiner {
    background-color: #FFFFFF;
    display: flex;
    flex-direction: row;
    // height: 100%;
    margin: 0;
    max-width: 1440px;
    ${theme.breakpoints.down("1023")} {
        flex-direction: column;
    }
    
  }

  .forgotpassword-form-container {
    background-color: #FFFFFF;
    display: block;
  }

  .forgotpassword-espot-container {
    background-color: #CCCCCC;
    // display: flex;
    // justify-content: center;
    // align-items: center;
    // overflow: auto;
  }

  .styled-step-image {
    display: flex;
    justify-content: center;
  }

  .styled-verification-code input {
    letter-spacing: 10px;
  }

  .styled-password-update-container {
    min-height: 100px;
  }

`}
`;

interface IForgotPasswordPro {
  isPopUp?: boolean;
  setTitle?: any
  closeHandler?: any;
  handleCloseForgotPassword?: any
}

const ForgotPasswordLayoutPro = (Props: IForgotPasswordPro) => {
  
    const isPopUp = Props.isPopUp
    const setTitle = Props.setTitle
    const closeHandler = Props.closeHandler
    const handleCloseForgotPassword = Props.handleCloseForgotPassword
    const widgetName = getDisplayName(ForgotPasswordLayoutPro);
    const { t } = useTranslation();
    const { mySite } = useSite();
    const isB2B: boolean = mySite?.isB2B;
    const theme = useTheme();
    const isTablet = useMediaQuery(theme.breakpoints.down(tabletBreakpoint()));
    const [showStep1, setShowStep1] = useState<boolean>(true);
    const [showStep2, setShowStep2] = useState<boolean>(false);
    const [showStep3, setShowStep3] = useState<boolean>(false);
    const [showStep4, setShowStep4] = useState<boolean>(false);
    const [email, setEmail] = useState<string>(EMPTY_STRING);
    const [loginEmailError, setLoginEmailError] = useState<boolean>(false);
    const [loginError, setLoginError] = useState<boolean>(false);
    const emailLabel = t("SignIn.Label.Email");

    const [logonInputProps, setLogonInputProps] = useState<any>({});
    const [canSubmitStep1, setCanSubmitStep1] = useState<boolean>(false);
    const [canSubmitStep2, setCanSubmitStep2] = useState<boolean>(false);
    const [canSubmitStep3, setCanSubmitStep3] = useState<boolean>(false);
    const [errorMessageKey, setErrorMessageKey] = useState<string>(EMPTY_STRING);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isSendingCode, setIsSendingCode] = useState<boolean>(false);
    
    const [associatedAccounts, setAssociatedAccounts] = useState<AssociatedAccounts[]>([]);
    const [associatedAccountSelected, setAssociatedAccountSelected] = useState<string>(EMPTY_STRING);
    const [idWithOrgData, setIdWidthOrgData] = useState<string>(EMPTY_STRING);
    const [proUserEmail, setProUserEmail] = useState<string>(EMPTY_STRING);
    const [errorCode, setErrorCode] = useState({ error: false, errorMsg: EMPTY_STRING });
    const [validationCode, setValidationCode] = useState(EMPTY_STRING);
    const [passwordNew, setPasswordNew] = useState(EMPTY_STRING);
    const [passwordVerify, setPasswordVerify] = useState(EMPTY_STRING);
    const [showInputError, setShowInputError] = useState({
      emailError: false,
      passwordError: false,
      passwordVerifyError: false,
      isInputPasswordEmpty: false,
    });
    const genericError: any = useSelector(genericErrorSelector);
    const navigate = useNavigate();
    
    const { emailError } = showInputError;

    const CancelToken = Axios.CancelToken;
    const cancels: Canceler[] = [];

    const payloadBase: any = {
        widget: widgetName,
        cancelToken: new CancelToken(function executor(c) {
            cancels.push(c);
        }),
    };

  useEffect(() => {
    if(!showStep2){
        setCanSubmitStep1(email?.length > 0 && addressUtil.validateEmail(email) ? false : true);
    }     
  }, [email]);

  useEffect(() => {
    if(genericError && genericError?.errorCode){
      if(genericError.errorCode === "2190"){
        setErrorCode({
          error: true,
          errorMsg: t("ForgotPassword.PRO.InvalidCode"),
        })
      }
    }
  }, [genericError])


  const handleEmail = (e) => {
      setShowInputError({
          ...showInputError,
          emailError: false
      })
      setLoginEmailError(false)
      setLoginError(false);
      setEmail(e.target.value);
      };
  
      const canContinueStep1 = (): boolean => {
      let flag: boolean = false;
      if (errorMessageKey) {
          if (
          email.trim() !== EMPTY_STRING 
          ) {
          const isEmail = addressUtil.validateEmail(email);
          flag = isEmail
          }
      } else {
          if (email.trim() !== EMPTY_STRING) {
          flag = addressUtil.validateEmail(email);
          }
      }
      return flag;
  };
    
  const handleSearchProUsers = async(event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setIsLoading(true)
    const isInputsValidated = canContinueStep1();        
    const associatedAccountsResponse = await getAssociatedAccounts({InputEmail: email, storeID: mySite?.storeID});

    if(associatedAccountsResponse === "emailError"){
        setLoginEmailError(true)
        setIsLoading(false)
        return
    }

    if(associatedAccountsResponse !== undefined && typeof associatedAccountsResponse === "object" ){
        if(associatedAccountsResponse.length === 1){
          setAssociatedAccountSelected(associatedAccountsResponse[0].value[1])
          setCanSubmitStep2(true)
        }
        setAssociatedAccounts(prevAccounts => {
        return [...prevAccounts, ...associatedAccountsResponse];
        });

    }

    if(isInputsValidated && !loginEmailError){
          setShowStep1(false)
          setShowStep2(true);
      }
      setIsLoading(false)
  };
    
  const handleSendCode = async (props: any, resendToken: boolean = false) => {
      props.preventDefault();
      setIsSendingCode(true)

      const storeID = mySite.storeID;
      const parameters: any = {
        responseFormat: "application/json",
        storeId: storeID,
        body: {
          logonId: associatedAccountSelected,
          resetPassword: "true",
          challengeAnswer: "-",
          sfaIdWithOrgData: idWithOrgData
        },
        ...payloadBase,
      };
      await personService
        .updatePerson(parameters)
        .then((res: any) => {
            if (res.statusText === OK) {
            setIsSendingCode(false)
            setShowStep1(false)
            setShowStep2(false)
            setShowStep3(true)
          }
        })
        .catch((error) => {
          console.log("error", error)
        });
        setIsSendingCode(false)
    }
    
      const handleAssociatedAccountSelectChange = (event:ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const idOrgData = associatedAccounts.find(account => account.value[1] === event?.target?.value)
        if(idOrgData?.value[0]){
          setIdWidthOrgData(idOrgData?.value[0])
          setEmail(idOrgData?.value[1])
        }
        setAssociatedAccountSelected(event?.target?.value)
        setCanSubmitStep2(true)
      }
    
      const handleEmailEdit = () => {
        setEmail(EMPTY_STRING)
        setAssociatedAccountSelected(EMPTY_STRING)
        setShowStep1(true)
        setShowStep2(false)
        setCanSubmitStep2(false)
        setAssociatedAccounts([])
        setTitle(t("ForgotPassword.PRO.Title"))
      }
    
      const handleChangePassword = async (e: any) => {
    
        e.preventDefault()
        e.stopPropagation();
        setIsLoading(true)
        const storeID = mySite?.storeID;
        const parameters: any = {
            responseFormat: "application/json",
            storeId: storeID,
            body: {
            logonId: email,
            resetPassword: "true",
            xcred_validationCode: validationCode,
            logonPassword: passwordNew,
            xcred_logonPasswordVerify: passwordVerify,
            },
            ...payloadBase,
        };
        await personService
        .updatePerson(parameters)
        .then((response) => {
            if (response.status === 200 && response?.data?.resourceName && response?.data?.resourceName === 'person' && 
                response?.data?.userId && response?.data?.userId !== '') {
                  setShowStep1(false)
                  setShowStep2(false)
                  setShowStep3(false)
                  setShowStep4(true)
                  setTimeout(() => {
                    if(isPopUp){
                      handleCloseForgotPassword()
                    }else {
                      navigate(SIGNIN)
                    }
                  }, 3000)

                  handleSendEmailPasswordsaved()
            }
        })
        .catch((err) => {
            console.log('error', err)
        });
        setIsLoading(false)
      }
    
      const handleChangeCode = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, type: string) => {
        const valor: string | null = e.target.value.trim();
    
        setErrorCode({
          error: false,
          errorMsg: "",
        });
    
        const num = Number(valor);
        if (Number.isInteger(num)) {
          if (valor.length <= 6) {
            if (valor !== validationCode) {
              setValidationCode(valor);
            }
          }
        }
      };

      const handleSendEmailPasswordsaved = () => {
        const payload = {
          logonId: email,
        };

        Axios.post(
          `/wcs/resources/store/${mySite.storeID}/hdm/mailing/sendPasswordSaved?responseFormat=json&langId=-5`,
          payload
        )
          .then((res) => {
            console.log("Update password mail sent");
          })
          .catch((error) => {
            console.log(error);
          });
      }

      const handleProCanContinueNewPassword = (data) => {
        if(data.isOk){
          setPasswordNew(data.data.passwordNew)
          setPasswordVerify(data.data.passwordVerify)
          setCanSubmitStep3(true)
        }else {
          setCanSubmitStep3(false)
        }
      }
    
      const associatedAccountItems = React.Children.toArray(
        associatedAccounts && associatedAccounts.length > 0 ? (
          associatedAccounts?.map((account, index) => 
    
          (
              <StyledMenuItem value={account?.value[1]} key={`${account?.value}${index}`} className={"styledSelect--menuItems-color"} fullWidth>
                <StyledTypography variant="bodyBaseline">{account?.value?.[0].replace('@@@', ' - ')}</StyledTypography>
              </StyledMenuItem>
          ))
      ) : (
        <StyledMenuItem value={"0"} className={"styledSelect--menuItems-color"} fullWidth>
          <StyledTypography variant="bodyBaseline">{t("AddressForm.Labels.Colony")}</StyledTypography>
        </StyledMenuItem>
      ))

    return (
        <ProForgotPassContainer className="forgot-password-conatiner">
          <StyledGrid item xs={isTablet || isPopUp ? 12 : 4} className={`forgotpassword-form-container ${isPopUp ? "" : "padding-2"}`}>

          {!isPopUp ?
          <SpecialTitleHDM titleText={showStep4 ? "Contraseña actualizada" : t("ForgotPassword.PRO.Title")} marginBottom={showStep3 ? 2 : 4} />
          :
          null
          }

          {showStep3 ?
          <>
          <StyledTypography variant="bodyBaseline" weight="regular" className={"bottom-margin-2"}>
            {t("ResetPassword.Subtitle")}
          </StyledTypography>
          <StyledTypography variant="bodySubText" weight="regular" className="color-gray200 bottom-margin-3 ">
            {t("ResetPassword.Reminder")}
          </StyledTypography>
          </>
          :
          null
          }
          {showStep1 || showStep2 ?
          <ProCustomStepperLabel 
            stepNumber={showStep1 ? 1 : 2} 
            sideText={t(`ForgotPassword.PRO.${showStep1 ? "StepOne" : "StepTwo"}`)}
          />
          :
          null
          }

          {showStep4 ?
          <StyledGrid className="styled-password-update-container">
            <StyledBox align="center">
              <StyledBox>
                <TaskAltIcon width="60" height="60" fill={palette.highlight.main}/>
              </StyledBox>
              <StyledTypography variant="bodyBaseline">
                {t("ResetPassword.PasswordUpdate")}
              </StyledTypography>              
            </StyledBox>
          </StyledGrid>
          :
          <form onSubmit={showStep1 ? handleSearchProUsers : showStep2 ? handleSendCode : handleChangePassword } noValidate>
            <StyledTextField
              isB2B={isB2B}
              disabled={!showStep1 ? true : false}
              className="bottom-margin-2"
              variant="outlined"
              margin="normal"
              fullWidth
              name="email"
              autoComplete="email"
              label={emailLabel}
              autoFocus
              onChange={handleEmail}
              value={email}
              inputProps={logonInputProps}
              error={emailError || loginEmailError}
              onBlur={() =>
                setShowInputError((prevState): any => ({
                  ...prevState,
                  emailError: !addressUtil.validateEmail(email) ? true : false,
                }))
              }
              helperText={
                emailError ? t("SignIn.Msgs.InvalidFormat")
                : loginEmailError ? t("ForgotPassword.PRO.EmailNotFound") 
                : null
              }
            />

            {associatedAccounts && associatedAccounts.length > 0 ?
              <>
                <div className="bottom-margin-2">
                    <StyledSelectHdm
                        label={t("SignInPage.ProLabels.AccountSelectDefaultLabel")}
                        name="associated-accounts"
  
                        handleChange={handleAssociatedAccountSelectChange}
                        value={associatedAccountSelected}
                        items={associatedAccountItems}
                        disabled={!showStep2}
                    />
                </div>
              </>
            : null}
            {showStep3 ?
            <StyledTextField
                className="bottom-margin-4 styled-verification-code"
                margin="normal"
                value={validationCode}
                name="verificationCode"
                label={t("ResetPassword.ValidationCodeLabel")}
                onChange={handleChangeCode}
                inputProps={{ maxLength: 40 }}
                fullWidth
                error={errorCode.error}
                helperText={errorCode.errorMsg}
            />
            :
            null
            }

            {showStep3 ?
              <ChangePassword editPassword={false} handleEditPassword={handleProCanContinueNewPassword} isProForgotPasswprd={true} />
            :
            null
            }
  
          <ButtonWithLoader
                type="submit"
                color="primary"
                variant="contained"
                disabled={showStep1 ? canSubmitStep1 : showStep2 ? !canSubmitStep2 : (!canSubmitStep3 || validationCode.trim().length === 0)}
                text={t(`ForgotPassword.PRO.ContinueButtonLabel${showStep1 ? "StepOne" : showStep2 ? "StepTwo" : showStep3 ? "StepThree" : "showStep4"}`)}
                loaderText= ""
                showLoader={isLoading || (isSendingCode && showStep2)}
            />
  
          </form>
          }
          

          {showStep1 ?
          <>
          <StyledGrid item xs={12}>
            <StyledGrid
              container
              alignItems="center"
              direction="row"
              justifyContent="center"
              className={"bottom-margin-4"}>
              <StyledGrid item>
                <StyledTypography variant="body1" component="span">
                  {t("ForgotPassword.AccountInfoRemember") + "  "}
                </StyledTypography>
                {isPopUp ?
                <StyledLinkTextSecondary variant="body1" color="secondary" component="span" onClick={() => handleCloseForgotPassword()} >
                {t("ForgotPassword.SignIn")}
                </StyledLinkTextSecondary>
                :
                <StyledTypography variant="body1" component="span">
                  <StyledLink to={ROUTES.SIGNIN}>{t("ForgotPassword.SignIn")}</StyledLink>
                </StyledTypography>
                }
              </StyledGrid>
            </StyledGrid>
          </StyledGrid>
          <StyledGrid item xs={12}>
            <Divider />
            <StyledGrid
              container
              alignItems="left"
              direction="row"
              justifyContent="left"
              className={"vertical-margin-2"}>
              <StyledGrid item>
                <StyledTypography variant="bodySubText" component="span">
                  {t("ForgotPassword.PRO.forgotEmailText1")}
                </StyledTypography>
                <StyledTypography variant="bodySubText" component="span">
                  <a href={"tel:" + t("OrderConfirmation.DeliveryMethod.CustomerSupportPhone")}>
                  {" "}
                  {t("OrderConfirmation.DeliveryMethod.CustomerSupportPhone")}
                  </a>
                </StyledTypography>
                <StyledTypography variant="bodySubText" component="span"> {t("ForgotPassword.PRO.forgotEmailText2")}
                </StyledTypography>
              </StyledGrid>
            </StyledGrid>
          </StyledGrid>
          </>
          :
          null
          }

          {showStep2 ?
          <StyledBox className="text-align-center">
            <StyledTypography variant="bodyBaseline" component="span" >
              <StyledLinkTextSecondary color="secondary" className="none-underline" onClick={handleEmailEdit}>
                {t("ForgotPassword.PRO.EmailEdit")}
              </StyledLinkTextSecondary>
            </StyledTypography>
          </StyledBox>
          :
          null
          }
          
          {showStep3 ?
          <>
          <StyledBox className="text-align-center">
            <StyledTypography variant="bodyBaseline" className="bottom-margin-1">{t("ResetPassword.PasswordNotRecieved")}</StyledTypography>
          </StyledBox>
  
          <StyledBox>
            <ButtonWithLoader
                color="primary"
                variant="outlined"
                text={t("ResetPassword.ResendVerificationCode")}
                loaderText= ""
                showLoader={isSendingCode && showStep3}
                onClick={handleSendCode}
            />
          </StyledBox>
          </>
          :
          null
          }

          </StyledGrid>
          {!isPopUp ?
          <StyledGrid item xs={isTablet ? 12 : 8} className="forgotpassword-espot-container">
            <EspotHdm espotName="PRO_ForgotPass_AdditionalInfo" />
          </StyledGrid>
          :
          null
          }
  
        </ProForgotPassContainer>
      )
}


export default ForgotPasswordLayoutPro