import styled from "styled-components";
import { StyledBox, StyledGrid } from "../../../hdm";

export const ContactUsWrapper = styled((props: any) => <StyledBox {...props} />)`
  ${({ theme }) => `
        display: flex;
        justify-content: center;
        padding-right: 10px;

        & .divider{
            border-bottom: 1px solid ${theme.palette.gray["50"]};
        }

        & .primary-color-divider{
            width: fit-content;
            border-bottom: solid 2px ${theme.palette.primary.main};
            padding-bottom: ${theme.spacing(1)}px;
        }

        & .gray-color-divider{
            border-bottom: solid 1px ${theme.palette.gray["100"]}; 
            flex-grow: 1;
            margin-bottom: ${theme.spacing(1)}px;
        }

        & .flex-center{
            display: flex;
            justify-content: flex-end;
            align-items: center;
        }
    }
    `}
`;

export const ContactUsContainer = styled((props: any) => <StyledGrid {...props} />)`
  ${({ theme }) => `
        width: 100%;
        max-width: 1440px;

        @media (max-width: 1024px){
            margin: 0 ${theme.spacing(2)}px;
        }

        & .espot-cards-container{
            gap: 8.2px;
        }

        & .espot-card{
            background-color: #ffffff;
            box-shadow: #cccccc 0 3px 6px;
            padding: ${theme.spacing(2)}px;
            @media (min-width: 1025px){
                flex-basis: 49.6%;
            }
        }

        & .contact-us-main-title{
            text-align: center;
            color: ${theme.palette.primary.main};
            font-weight: 300;
            font-size: 36px;
            width: fit-content;
            margin: auto;
            background-color: ${theme.palette.gray["50"]};
            padding: 0 ${theme.spacing(1)}px;
        }

        & .middle-line{
            border: solid 1px ${theme.palette.gray["100"]};
            transform: translateY(23px);
            z-index: -1;
            position: relative;
        }

        & .MuiGrid-grid-lg-4{
            @media (min-width: 1025px){
                max-width: 32.8%;
            }
        }

        & .vertical-margins{
            margin: ${theme.spacing(1)}px 0px;
        }

        & .margin-bottom-5{
            margin-bottom: ${theme.spacing(1)}px;
        }
    
        & .margin-bottom-10{
            margin-bottom: ${theme.spacing(2)}px;
        }

        & .margin-top-5{
            margin-top: ${theme.spacing(1)}px
        }
    }
    `}
`;
export const ContactUsChannelsContainer = styled((props: any) => <StyledGrid {...props} />)`
  ${({ theme }) => `
        & .channels-title{

        }

        & .channel-title{
            padding-top: 2px;
        }

        & .cards-container{
            // gap: ${theme.spacing(2)}px;
            gap: 8.2px;
        }
        & .channel-card{
            background-color: #ffffff;
            box-shadow: #cccccc 0 3px 6px;
            padding: ${theme.spacing(2)}px;
        }

        & .localize-icon{
            color: ${theme.palette.secondary.main};
            margin-right: ${theme.spacing(1)}px;
        }

        & svg{
            width: 22px;
            color: ${theme.palette.primary.main};
            margin-right: ${theme.spacing(2)}px;
        }

        & .hyperlink{
            cursor: pointer;
        }
    `}
`;

export const ContactHelpContainer = styled((props: any) => <StyledGrid {...props} />)`
  ${({ theme }) => `
    margin-top: ${theme.spacing(2)}px;

        & .main-card{
            background-color: #ffffff;
            box-shadow: #cccccc 0 3px 6px;
            padding: ${theme.spacing(2)}px;
        }

        & .inline-cards-container{
            grid-gap: 10px;
        }

        & .inline-card{
            border: solid ${theme.palette.gray["100"]} 1px;
            padding: ${theme.spacing(2)}px;
            cursor: pointer;
        }

        & div.inline-card:hover{
                border: solid ${theme.palette.primary.main} 1px;
        }

        & .section-description{
            text-align: justify;
        }

        & .MuiGrid-grid-xs-3{
            @media (min-width: 1025px){
                flex-basis: 24%;
            }
        } 

        & svg{
            color: ${theme.palette.primary.main};
            font-size: 15px;
        }

        & .footer-link{
            cursor: pointer;
        }
    `}
`;
