import { select } from "redux-saga/effects";
import { onlineStoreRdc } from "../../../../redux/selectors/hdmData";
import * as CryptoJS from 'crypto-js';
import axios from "axios";

import { getCookieValue, setCookieCAPI } from "./../cookieHandler";
import { getExternalId } from "../pinterest/pinterest-conversion-utils";
import { triggerConversionTikTokPageView } from "../tiktok/tiktok-conversion-utils";

export const triggerPageView = async (mySite) => {
    const eventId = setCookieCAPI("_mfbt");
    const fbc = getCookieValue("_fbc");
    const fbp = getCookieValue("_fbp");
    const userAgent = window.navigator.userAgent;
    const eventSource = window.location.href;
    const externalId = getExternalId();
    await axios
      .get(
        `/wcs/resources/store/${mySite?.storeID}/hdm/facebook/pageview?responseFormat=json&mfbt=${eventId}&fbc=${fbc}&fbp=${fbp}&userAgent=${userAgent}&eventSource=${eventSource}&externalId=${externalId}`
      )
      .then((res) => {
        if (res && res?.data) {
          console.log("Service working good ");
        }
        console.log("Response value:  " + res);
      })
      .catch((error) => {
        console.log("Error pageview: " + error);
      });

    //Tiktok trigger event:
    triggerConversionTikTokPageView(mySite?.storeID, eventId,userAgent);
  };

export const triggerFacebookCheckout = async (eventName,store, orderId, emailId) => {
    const eventId = setCookieCAPI("mfbt");
    const fbc = getCookieValue("_fbc");
    const fbp = getCookieValue("_fbp");
    const userAgent = window.navigator.userAgent;
    const eventSource = "https://" + window.location.hostname;
    const physical = getCookieValue(`WC_DefaultStore_${store}`);
    const externalId = getExternalId();
    const payload = {
        mfbt: eventId,
        fbc:fbc,
        fbp:fbp,
        userAgent:userAgent,
        eventSource:eventSource,
        eventName:eventName,
        orderId: orderId,
        physicalStoreId:physical,
        email:emailId,
        externalId:externalId
    }

    await axios
    .post(`/wcs/resources/store/${store}/hdm/facebook/checkout?responseFormat=json`,payload)
    .then((res) => {
        if (res && res?.data) {
            console.log("Service working good ");
        }
        console.log("Response value:  " + res);
        })
    .catch((error) => {
        console.log("Error purchase: " + error);
    });
};


export const triggerConversionMetaAddToCart = async (storeId, mfbt, userAgent, eventSource, products, orderId, externalId) => {
    const fbc = getCookieValue("_fbp");
    const fbp = getCookieValue("_fbc");
    const payload = {
        eventName: "AddToCart",
        mfbt: mfbt,
        fbc: (fbc != null && fbc != undefined) ? fbc : "",
        fbp: (fbp != null && fbp != undefined) ? fbp : "",
        userAgent:userAgent,
        eventSource:eventSource,
        productsPayload: products,
        orderId: orderId,
        externalId:externalId
    }
    
    await axios
    .post(`/wcs/resources/store/${storeId}/hdm/facebook/checkout?responseFormat=json`,payload )
    .then((res) => {
        if (res && res?.data) {
            console.log("Meta ATC OK ");
        }
        console.log("Meta ATC OK : " + res);
    })
    .catch((error) => {
        console.log("Error M ATC: " + error);
    });
}

/**
 * The purpose of this event is retrive user detals to be used in GTM
 * @param em 
 * @param ph 
 */

export const validateMetaAttributes = (em, ph, storeconf, isGuest) => {
    
    try{
        
        if (storeconf?.ENABLE_CAPI_DATA !== undefined
            && storeconf?.ENABLE_CAPI_DATA === 'true'){
            const metaAttr = {
                MTPE: sha256value(em),
                MTPPM: ph!=null && ph!=undefined ? sha256value("52"+ph):null,
                MTPPT: ph!=null && ph!=undefined ? sha256value("+52"+ph):null,
                type: isGuest ? "G":"R"
            }
            localStorage.setItem("MTPD", JSON.stringify(metaAttr));
        }
    }catch(e){
        console.log(`Error creating MTPD`)
    }
    
}

export const removeGuestMetaAttributes = () =>{
    const url = window.location.href;
    if(!url.includes('checkout') && !url.includes('thank-you')){
        try{
            const MTP = JSON.parse(localStorage.getItem('MTPD') || '{}');
            if(MTP.type != 'R'){
                localStorage.removeItem("MTPD");
            }
        }catch(e){console.log(e);}
    }
}

export const removeMetaAttributes = (storeconf) => {
    
    if (storeconf?.ENABLE_CAPI_DATA !== undefined
        && storeconf?.ENABLE_CAPI_DATA === 'true'){
        try{
            setTimeout(async () => {
                localStorage.removeItem("MTPD");
            },2000);
        }catch(e){
            console.log(`Error removing MTPD`)
        }
    }
}

const sha256value = (crypoValue) => {
    return CryptoJS.SHA256(crypoValue).toString(CryptoJS.enc.Hex);
};