import CardMedia from "@material-ui/core/CardMedia";
import styled from "styled-components";
// import { ContainerStyledCardMedia } from "./containers-product-card";
import { ContainerStyledBox } from "./containers-product-card";

import * as ROUTES from "../../../constants/routes";
import { useLocation } from "react-router";

export const ContainerStyledCardMedia = styled(({ ...props }) => <CardMedia {...props} />)`
  ${({ theme }) => `  
 
  &.promo-remate {
    width: 49px;
    height: 22px;
  }

  &.promo_plp-ribbon-gr {
    width: 49px;
    height: 22px;
  }
  
  &.promo_plp-ribbon-sb {
    width: 37px;
    height: 24px;
  }
  
  &.promo_plp-ribbon-nlp {
    width: 30px;
    height: 30px;
  }
  
  &.promo_plp-ribbon-combo {
    width: 58px;
    height: 22px;
  }
  
  &.promo_plp-ribbon-fp {
    width: 82px;
    height: 22px;
  }
  
  &.promo_plp-ribbon-nxm {
    width: auto;
    height: 22px;
  }
  
  &.promo_plp-ribbon-ahp {
    width: 38px;
    height: 22px;
  }
  
  &.promo_plp-ribbon-cp {
    width: 28px;
    height: 22px;
  }

`}
`;

interface Props {
  productData: any;
}

const PromoIcons = (props) => {
  const { urlRibbons } = props;
  let { orderRibbons } = props;

  const location = useLocation();
  const inComparePage = ROUTES.COMPARE_ROUTE === location.pathname;

  if (!orderRibbons || orderRibbons.length === 0) {
    return null;
  }

  if (orderRibbons[0] === "PROMOICON_GR") {
    return (
      <ContainerStyledCardMedia
        image={urlRibbons[orderRibbons[0]]}
        title={orderRibbons[0]}
        className={"promo_plp-ribbon-gr"}
        component="img" />
    );
  }

  let classRibbonCero = "";
  let classRibbonUno = "";
  let promoIconSelectedCero: any = null;
  let promoIconSelectedUno: any = null;
  // let nxm = false
  if (inComparePage && orderRibbons[0] === "PROMOICON_CE") {
    classRibbonCero = "promo_plp-ribbon-sb";
    promoIconSelectedCero = (
      <ContainerStyledCardMedia
        image={urlRibbons[orderRibbons[0]]}
        className={classRibbonCero}
        title={orderRibbons[0]}
        component="img" />
    );
  }

  if (orderRibbons[0] === "PROMORIBBON_COMBO") {
    classRibbonCero = "promo_plp-ribbon-combo";
    promoIconSelectedCero = (
      <ContainerStyledCardMedia
        image={urlRibbons[orderRibbons[0]]}
        className={classRibbonCero}
        title={orderRibbons[0]}
        component="img" />
    );
  }

  if (orderRibbons[0] === "PROMOICON_FP") {
    classRibbonCero = "promo_plp-ribbon-fp";
    promoIconSelectedCero = (
      <ContainerStyledCardMedia
        image={urlRibbons[orderRibbons[0]]}
        className={classRibbonCero}
        title={orderRibbons[0]}
        component="img" />
    );
    orderRibbons = orderRibbons.filter((orderRibb) => !orderRibb.includes("PROMORIBBON_NXM"));
  }
  if (orderRibbons[1] === "PROMOICON_FP") {
    classRibbonUno = "promo_plp-ribbon-fp";
    promoIconSelectedUno = (
      <ContainerStyledCardMedia
        image={urlRibbons[orderRibbons[1]]}
        className={classRibbonUno}
        title={orderRibbons[1]}
        style={{ marginLeft: "10px" }}
        component="img" />
    );
  }

  if (orderRibbons[0] && orderRibbons[0].includes("PROMORIBBON_NXM")) {
    const nxm = JSON.parse(JSON.stringify(orderRibbons[0]));
    classRibbonCero = "promo_plp-ribbon-nxm";
    promoIconSelectedCero = (
      <ContainerStyledCardMedia
        image={urlRibbons[nxm]}
        className={classRibbonCero}
        title={nxm}
        component="img" />
    );
    orderRibbons = orderRibbons.filter((orderRibb) => !orderRibb.includes("PROMORIBBON_NXM"));
    orderRibbons.unshift(nxm);
  } else if (orderRibbons[1] && orderRibbons[1].includes("PROMORIBBON_NXM")) {
    classRibbonUno = "promo_plp-ribbon-nxm";
    promoIconSelectedUno = (
      <ContainerStyledCardMedia
        image={urlRibbons[orderRibbons[1]]}
        className={classRibbonUno}
        title={orderRibbons[1]}
        style={{ marginLeft: "10px" }}
        component="img" />
    );
  }

  if (orderRibbons[0] === "PROMORIBBON_AHP") {
    classRibbonCero = "promo_plp-ribbon-ahp";
    promoIconSelectedCero = (
      <ContainerStyledCardMedia
        image={urlRibbons[orderRibbons[0]]}
        className={classRibbonCero}
        title={orderRibbons[0]}
        component="img" />
    );
  }
  if (orderRibbons[1] === "PROMORIBBON_AHP") {
    classRibbonUno = "promo_plp-ribbon-ahp";
    promoIconSelectedUno = (
      <ContainerStyledCardMedia
        image={urlRibbons[orderRibbons[1]]}
        className={classRibbonUno}
        title={orderRibbons[1]}
        style={{ marginLeft: "10px" }}
        component="img" />
    );
  }

  if (orderRibbons[0] === "PROMOICON_CP") {
    classRibbonCero = "promo_plp-ribbon-cp";
    promoIconSelectedCero = (
      <ContainerStyledCardMedia
        image={urlRibbons[orderRibbons[0]]}
        className={classRibbonCero}
        title={orderRibbons[0]}
        component="img" />
    );
  }
  if (orderRibbons[1] === "PROMOICON_CP") {
    classRibbonUno = "promo_plp-ribbon-cp";
    promoIconSelectedUno = (
      <ContainerStyledCardMedia
        image={urlRibbons[orderRibbons[1]]}
        className={classRibbonUno}
        title={orderRibbons[1]}
        style={{ marginLeft: "10px" }}
        component="img" />
    );
  }

  return (
    <>
      <ContainerStyledBox display="flex">
        {promoIconSelectedCero}
        {promoIconSelectedUno}
      </ContainerStyledBox>
    </>
  );
};

export default PromoIcons;
