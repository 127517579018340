import { useSelector } from "react-redux";
import { useState, useEffect } from "react";
import { numberWithCommas, ribbonsPromoIconsImg } from "../../functions";
import { StyledBox } from "../box/styled-box";
import styled from "styled-components";
import { HdmPriceBox } from "../../components/hdm-price-box";
import Axios, { Canceler } from "axios";
import { useSite } from "../../../_foundation/hooks/useSite";
import { currentContractIdSelector } from "../../../redux/selectors/contract";
import { currentStoreSelector } from "../../../redux/selectors/current-store";
import { loginStatusSelector } from "../../../redux/selectors/user";
import { getRegularProPrice } from "../../functions/getPromoicons";
import axios from "axios";
import { cloneDeep } from "lodash-es";

export const ContainerStyledBox = styled(({ ...props }) => <StyledBox {...props} />)`
  ${({ theme, color }) => `

  &.product-card-precio {
    display: flex;
    padding-left: ${theme.spacing(2)}px;
    padding-right: ${theme.spacing(2)}px;
    margin-bottom: ${theme.spacing(2)}px;
  }

  .price-card-height {
    height: 71px;
  }

  .colorHighlight {
    color: ${theme.palette.text.highlight};
  }

  .colorGray300Line {
    color: ${theme.palette.gray["300"]};
    text-decoration: line-through;
  }

  .promo_plp-ribbon-sb {
    width: 37px;
    height: 24px;
  }

  .superscript-text-price {
    font-size: 16px;
  }

`}
`;

interface Props {
  parentProduct?: any;
  productData: any;
  quantityMeasure: string;
  weightMeasure: string;
  promociones: any;
  urlRibbons: any;
  isSmallSize?: any;
}

interface PropsPromocion {
  promoIcons: string[];
}

const ProductPrice = (props: Props) => {
  // componentsArr and partNumbers cant be outside because PLP fail
  const componentsArr: Array<object> = [];
  let partNumbers = "";
  const { parentProduct, productData, quantityMeasure, weightMeasure, promociones, urlRibbons, isSmallSize } = props;

  const extractProductData: any = cloneDeep(productData);
  // const extractProductData: any = JSON.parse(JSON.stringify(productData))
  
  const { mySite } = useSite();
  const contract = useSelector(currentContractIdSelector);
  const storeSelector = useSelector(currentStoreSelector);
  const currentStoreId = storeSelector.currentStore.stLocId;
  const [product, setProduct] = useState(parentProduct);
  const [productPriceData, setProductPriceData] = useState(extractProductData);
  const isB2B = mySite?.isB2B;
  const loginStatus = useSelector(loginStatusSelector);
  const catalogIdentifier: string = mySite ? mySite.catalogID : "";


  const getMainProductData = async (partNumber: string) => {
    const mainProduct: Array<object> = [];
    await Axios.get<any>(
      `/search/resources/api/v2/products?storeId=${mySite.storeID}&partNumber=${partNumber}&catalogId=${catalogIdentifier}&contractId=${contract}&langId=-5&physicalStoreId=${currentStoreId}`
    )
      .then((response) => {
        if (response && response.status === 200 && response?.data?.contents.length > 0) {
          const products = response.data.contents;
          products.forEach((p) => {
            mainProduct.push(p);
          });
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
    if (mainProduct && mainProduct.length > 0) {
      setProduct(mainProduct[0]);
    }
  };

  // PLP Process
  useEffect(() => {
    if (extractProductData?.type === "package" && product === undefined) {
      // We need to fetch product information for this SKU
      if (extractProductData.partNumber) {
        getMainProductData(extractProductData.partNumber);
      }
    }
  }, [product]);
  
  const getProductData = async (storeID, contract, currentStoreId) => {

    if (product && product !== null && componentsArr.length === 0) {
      // We need to fetch the components from this object
      if (product?.components?.length > 0) {
        const { components } = product;
        components.forEach((p) => {
          const offerPrice = p.price.filter((precio) => precio.usage === "Offer")[0];
          if (offerPrice?.value && parseFloat(offerPrice.value) > 0) {
            componentsArr.push(p);
          } else {
            partNumbers = `${partNumbers}&partNumber=${p?.partNumber}`;
          }
        });
      }

      if (partNumbers !== "") {

        let allProPrices
        let allProPricesIndex

        if(isB2B && loginStatus){

          const urlPrice = `/wcs/resources/store/11401/price?q=byPartNumbers${partNumbers}&langId=-5`;
          allProPrices =  await axios.get(urlPrice, {
          headers: {
              "Content-Type": "application/json",
          },
          data: {},
          })
          .then((response) => response.data)
          .then((response) => {
  
            // console.log('rc', rc)
            return response
            
          })
          .catch((error) => {
            console.log("errorLeadtime", "--", error);
          });
  
          allProPricesIndex = allProPrices?.EntitledPrice && allProPrices.EntitledPrice.reduce((acc, element) => {
        
            let proPriceData: any = element?.UnitPrice[0]
            if(proPriceData){
              proPriceData = proPriceData?.price?.value
            }
            if(proPriceData){        
              acc[element.partNumber] = proPriceData
            }
            return acc      
          }, {})

        }

        await Axios.get<any>(
          `/search/resources/api/v2/products?storeId=${storeID}${partNumbers}&catalogId=${catalogIdentifier}&contractId=${contract}&langId=-5&physicalStoreId=${currentStoreId}`
        )
          .then((response) => {
            if (response && response.status === 200 && response?.data?.contents.length > 0) {
              const products = response.data.contents;
              products.forEach((p) => {

                if(isB2B && loginStatus && allProPricesIndex && allProPricesIndex?.length !== 0){
                  
                  p.price.map(price => {
                    if (price.usage === 'Offer' && allProPricesIndex[p.partNumber]) {
                      price.value = allProPricesIndex[p.partNumber].toString(); // Change value property to "100"
                      return price; // Return true if usage is 'Offer'
                    }
                  });
                }

                // calculateDiyProPrice(p, 900)
                componentsArr.push(p);
              });
            }
          })
          .catch((error) => {
            console.log("error", error);
          });
      }
      if (componentsArr && componentsArr.length > 0) {
        setProductPriceData((value) => ({ ...value, ["componentes"]: componentsArr }));
      }
    }
  };
  // PDP Logic
  useEffect(() => {
    if (product?.type === "package" && productPriceData?.componentes === undefined) {
      getProductData(mySite.storeID, contract, currentStoreId);
    }
  }, [productPriceData, product]);

  function ahorroWithComas(ahorro: number) {
    const result = ahorro
      .toFixed(2)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return result;
  }

  const price: any = numberWithCommas(productPriceData);

  let isMeassure = false;
  if (quantityMeasure !== "" && quantityMeasure.toLocaleLowerCase() !== "c62") {
    isMeassure = true;
  }
  
  const PromocionPrecio = (props: PropsPromocion) => {
    const { promoIcons } = props;
    
    if(isB2B && loginStatus && !promoIcons.includes("PROMOICON_NLP") && !promoIcons.includes("PROMORIBBON_COMBO") && !isMeassure){

      price.proRegularPrice = getRegularProPrice(productPriceData.price)
    }

    if (promoIcons && promoIcons.includes("PROMOICON_GR")) {
      let ahorro: any = "0";

      if (quantityMeasure.toLocaleLowerCase() === "c62" || quantityMeasure === "") {
        if (price.listPrice && price.offerPrice) {
          ahorro = parseFloat(price.listPrice.replace(/,/g, "")) - parseFloat(price.offerPrice.replace(/,/g, ""));
          ahorro = ahorroWithComas(ahorro);
        }
      } else {
        if (price.listMedidaPrice && price.offerMedidaPrice) {
          ahorro =
            parseFloat(price.listMedidaPrice.replace(/,/g, "")) - parseFloat(price.offerMedidaPrice.replace(/,/g, ""));
          ahorro = ahorroWithComas(ahorro);
        }
      }

      if (quantityMeasure.toLocaleLowerCase() === "c62" || quantityMeasure === "") {
        if (price.listPrice && price.offerPrice) {
          if (parseFloat(price.listPrice.replace(/,/g, "")) <= parseFloat(price.offerPrice.replace(/,/g, ""))) {
            return <HdmPriceBox price={price} isSmallSize={isSmallSize} />;
          } else {
            return (
              <HdmPriceBox
                price={price}
                isMeassure={isMeassure}
                quantityMeasure={quantityMeasure}
                weightMeasure={weightMeasure}
                ahorro={ahorro}
                isSmallSize={isSmallSize}
              />
            );
          }
        }
      }

      if (quantityMeasure !== "") {
        if (price.listMedidaPrice && price.offerMedidaPrice) {
          if (
            parseFloat(price.listMedidaPrice.replace(/,/g, "")) <= parseFloat(price.offerMedidaPrice.replace(/,/g, ""))
          ) {
            return (
              <HdmPriceBox
                price={price}
                isMeassure={isMeassure}
                quantityMeasure={quantityMeasure}
                weightMeasure={weightMeasure}
                isSmallSize={isSmallSize}
              />
            );
          } else {
            return (
              <HdmPriceBox
                price={price}
                isMeassure={isMeassure}
                quantityMeasure={quantityMeasure}
                weightMeasure={weightMeasure}
                ahorro={ahorro}
                isSmallSize={isSmallSize}
              />
            );
          }
        }
      }

      return <HdmPriceBox price={price} ahorro={ahorro} isSmallSize={isSmallSize} />;
    }

    if (promoIcons && promoIcons.includes("PROMOICON_CE")) {
      //It's special buy promo
      const ribbonData = {img: urlRibbons["PROMOICON_CE"], name: "sb"};
      if (quantityMeasure.toLocaleLowerCase() === "c62" || quantityMeasure.toLocaleLowerCase() === "") {
        return <HdmPriceBox price={price} ribbonData={ribbonData} isSmallSize={isSmallSize} />;
      } else {
        return (
          <HdmPriceBox
            price={price}
            isMeassure={isMeassure}
            quantityMeasure={quantityMeasure}
            weightMeasure={weightMeasure}
            ribbonData={ribbonData}
            isSmallSize={isSmallSize}
          />
        );
      }
    }

    if (promoIcons && promoIcons.includes("PROMOICON_NLP")) {
      let ahorro: any = 0;
      if (quantityMeasure.toLocaleLowerCase() === "c62" || quantityMeasure.toLocaleLowerCase() === "") {
        if (price.listPrice && price.offerPrice) {
          ahorro = parseFloat(price.listPrice.replace(/,/g, "")) - parseFloat(price.offerPrice.replace(/,/g, ""));
          ahorro = ahorroWithComas(ahorro);
        }
      } else {
        if (price.listMedidaPrice && price.offerMedidaPrice) {
          ahorro =
            parseFloat(price.listMedidaPrice.replace(/,/g, "")) - parseFloat(price.offerMedidaPrice.replace(/,/g, ""));
          ahorro = ahorroWithComas(ahorro);
        }
      }

      if (quantityMeasure.toLocaleLowerCase() === "c62" || quantityMeasure.toLocaleLowerCase() === "") {
        if (price.listPrice && price.offerPrice) {
          if (parseFloat(price.listPrice.replace(/,/g, "")) <= parseFloat(price.offerPrice.replace(/,/g, ""))) {
            return <HdmPriceBox price={price} isSmallSize={isSmallSize} />;
          } else {
            //It's NLP promo
            const ribbonData = {img: urlRibbons["PROMOICON_NLP"], name: "nlp"};
            return (
              <HdmPriceBox
                price={price}
                ahorro={ahorro}
                ribbonData={ribbonData}
                isSmallSize={isSmallSize}
              />
            );
          }
        }
      }

      if (quantityMeasure !== "") {
        if (price.listMedidaPrice && price.offerMedidaPrice) {
          if (
            parseFloat(price.listMedidaPrice.replace(/,/g, "")) <= parseFloat(price.offerMedidaPrice.replace(/,/g, ""))
          ) {
            return (
              <HdmPriceBox
                price={price}
                isMeassure={isMeassure}
                quantityMeasure={quantityMeasure}
                weightMeasure={weightMeasure}
                isSmallSize={isSmallSize}
              />
            );
          } else {
            //It's NLP promo
            const ribbonData = {img: urlRibbons["PROMOICON_NLP"], name: "nlp"};
            return (
              <HdmPriceBox
                price={price}
                isMeassure={isMeassure}
                quantityMeasure={quantityMeasure}
                weightMeasure={weightMeasure}
                ahorro={ahorro}
                ribbonData={ribbonData}
                isSmallSize={isSmallSize}
              />
            );
          }
        }
      }

      return <HdmPriceBox price={price} ahorro={ahorro} isSmallSize={isSmallSize} />;
    }

    if (promoIcons && promoIcons.includes("PROMORIBBON_COMBO") && product?.type === "package" && isB2B && !loginStatus) {
     
        return (
          <HdmPriceBox
            price={price}
            isMeassure={isMeassure}
            quantityMeasure={quantityMeasure}
            weightMeasure={weightMeasure}
            isSmallSize={isSmallSize}
            isCombo={true}
          />
        );
    }


    let ahorro;
    if (quantityMeasure.toLocaleLowerCase() === "c62" || quantityMeasure.toLocaleLowerCase() === "") {
      if (price.listPrice && price.offerPrice) {
        ahorro = parseFloat(price.listPrice.replace(/,/g, "")) - parseFloat(price.offerPrice.replace(/,/g, ""));
        ahorro = ahorroWithComas(ahorro);
      }
    } else {
      if (price.listMedidaPrice && price.offerMedidaPrice) {
        ahorro =
          parseFloat(price.listMedidaPrice.replace(/,/g, "")) - parseFloat(price.offerMedidaPrice.replace(/,/g, ""));
        ahorro = ahorroWithComas(ahorro);
      }
    }

    if (quantityMeasure.toLocaleLowerCase() === "c62" || quantityMeasure.toLocaleLowerCase() === "") {
      if (price.listPrice && price.offerPrice) {
        if (parseFloat(price.listPrice.replace(/,/g, "")) <= parseFloat(price.offerPrice.replace(/,/g, ""))) {
          return <HdmPriceBox price={price} isSmallSize={isSmallSize} />;
        } else {
          return <HdmPriceBox price={price} ahorro={ahorro} isSmallSize={isSmallSize} />;
        }
      }
    }

    return (
      <HdmPriceBox price={price} isMeassure={isMeassure} quantityMeasure={quantityMeasure} weightMeasure={weightMeasure} isSmallSize={isSmallSize} />
    );
  };

  return <StyledBox>{<PromocionPrecio promoIcons={promociones} />}</StyledBox>;
};

export default ProductPrice;
