/*
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2021
 *
 *==================================================
 */
//Standard libraries

import { LazyLoadComponent } from "react-lazy-load-image-component";

//UI
import {
  StyledGrid,
  StyledButton,
  StyledTypography,
  StyledPaper,
  StyledSwatch,
  StyledProgressPlaceholder,
  StyledLink,
  StyledBox,
} from "../../elements";
import { Link } from "react-router-dom";
import { CardMedia } from "@material-ui/core";
import { triggerSelectItemDL } from "../../../components/custom-components/Datalayer-Utils/dataLayer-func";
import { useSite } from "../../../_foundation/hooks/useSite";
import { useSelector } from "react-redux";
import { loginStatusSelector } from "../../../redux/selectors/user";
import { proCustomerLevelSelector } from "../../../redux/selectors/organization";
import { currentStoreSelector } from "../../../redux/selectors/current-store";
import { currentContractIdSelector } from "../../../redux/selectors/contract";
import { onlineStoreRdc } from "../../../redux/selectors/hdmData";
import { getPesoMedida, getPromoicons, getUnidadMedida, numberWithCommas, ordenarJerarquia, ribbonsPromoIconsImg } from "../../functions/getPromoicons";
import ProductPrice from "../../elements/product-card/styled-product-price";
import { getStoreOnlyAvailable } from "../../functions";
import { useTranslation } from "react-i18next";

interface FeaturedCardProps {
  productInfo: any;
  displayPrice: number;
  offerPrice: number;
  attributeList: Array<any>;
  shopNow: string;
  informMarketingOfClick: any;
  formattedPriceDisplay: any;
}

export const FeatureCard = (props: FeaturedCardProps) => {
  const { productInfo, offerPrice, attributeList, shopNow, informMarketingOfClick, formattedPriceDisplay } = props;
  const partNumber = productInfo.partNumber;
  const { mySite } = useSite();
  const isB2B = mySite?.isB2B;
  const { t } = useTranslation();
  const storeConfData = useSelector(onlineStoreRdc);
  const loginStatus = useSelector(loginStatusSelector);
  const proCustomerLevel = useSelector(proCustomerLevelSelector);
  const currentStore = useSelector(currentStoreSelector);
  const currentMarketId = currentStore && currentStore?.currentStore?.marketId;
  const currentStoreId = currentStore && currentStore?.currentStore?.stLocId;
  const contract = useSelector(currentContractIdSelector);
  const itemAffiliation = storeConfData?.DL_ITEM_AFFILIATION;
  const to = productInfo?.seo?.href;
  //For product price
  const urlRibbons = ribbonsPromoIconsImg(storeConfData?.IMAGE_SERVER_HOSTNAME);
  const attributes = getStoreOnlyAvailable(productInfo, currentMarketId);
  const promociones = getPromoicons(attributes, isB2B, currentStoreId, currentMarketId);
  const quantityMeasure = productInfo["x_measurements.quantityMeasure"] ? getUnidadMedida(productInfo["x_measurements.quantityMeasure"]) : "";
  const weightMeasure = productInfo["x_measurements.weightMeasure"] ? getPesoMedida(productInfo["x_measurements.weightMeasure"]) : ""

  return (
    <>
      {productInfo && (
        <StyledPaper id={`featureCard_${productInfo.id}`} className="horizontal-padding-2 vertical-padding-4">
          <StyledGrid container alignItems="center" spacing={3}>
            <LazyLoadComponent placeholder={<StyledProgressPlaceholder className="vertical-padding-20" />}>
              <StyledGrid
                item
                xs={12}
                sm={6}
                lg={5}
                id={`featureCard_imageContainer_${productInfo.id}`}
                className="product-full-image">
                <StyledLink
                  onClick={informMarketingOfClick}
                  to={productInfo.seo?.href}
                  testId={`featureCard_imageRouter_${productInfo.id}`}>
                  <Link onClick = {() => {triggerSelectItemDL(
                      mySite.storeID,
                      isB2B && loginStatus && proCustomerLevel ? currentStore?.currentStore?.stLocId + "_" + proCustomerLevel : currentStore?.currentStore?.stLocId,
                      contract,
                      partNumber,
                      1,
                      itemAffiliation,
                      currentMarketId,
                      isB2B
                    )}} to={to} >
                  <CardMedia
                    image={
                      storeConfData?.IMAGE_SERVER_HOSTNAME +
                      "productos/" +
                      partNumber.replace("P", "") +
                      "/" +
                      partNumber.replace("P", "") +
                      ".jpg"
                    }
                    title={productInfo?.name}
                    src=""
                    component={"img"}
                    loading="lazy"
                    onError={({ currentTarget }) => {
                      currentTarget.onerror = null;
                      currentTarget.src = "https://cdn.homedepot.com.mx/assets/img/imagen-no-disponible.svg";
                    }}
                  />
                </Link>
                </StyledLink>
              </StyledGrid>
            </LazyLoadComponent>
            <StyledGrid
              item
              xs={12}
              sm={6}
              md={5}
              lg={6}
              id={`featureCard_describer_${productInfo.id}`}
              className="product-description">
              {productInfo?.manufacturer && (
                <StyledTypography variant="overline">{productInfo.manufacturer}</StyledTypography>
              )}
              <StyledTypography variant="h2" id={`featureCard_describer_${productInfo.id}`}>
                {productInfo.name}
              </StyledTypography>
              <div className="vertical-margin-2">
                {attributeList &&
                  attributeList.map((e: any, index: number) => (
                    <StyledSwatch
                      style={{
                        backgroundImage: `url("${e.image1path}")`,
                      }}
                      key={index}
                      className="product-swatch-medium"
                    />
                  ))}
              </div>
              <StyledBox>
                <StyledTypography variant={"bodySubText"} className="color-gray300">
                  {t("productBox.sku")} <span className="product-sku">{partNumber}</span>
                </StyledTypography>
              </StyledBox>
              <StyledTypography
                variant="subtitle2"
                id={`featureCard_description_${productInfo.id}`}
                className="text-content short-description">
                {productInfo?.shortDescription}
              </StyledTypography>
              {productInfo && quantityMeasure &&
                <ProductPrice
                  productData={productInfo}
                  weightMeasure={weightMeasure}
                  quantityMeasure={quantityMeasure}
                  promociones={promociones}
                  urlRibbons={urlRibbons}
                />
              }
              <StyledLink
                onClick={informMarketingOfClick}
                to={productInfo.seo?.href}
                testId={`featureCard_textRouter_${productInfo.id}`}>
                <StyledButton testId={`featureCard_textRouter_${productInfo.id}_shop_now`} color="secondary">
                  {shopNow}
                </StyledButton>
              </StyledLink>
            </StyledGrid>
          </StyledGrid>
        </StyledPaper>
      )}
    </>
  );
};
