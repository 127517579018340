import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { currentStoreSelector } from "../../redux/selectors/current-store";
import { loginStatusSelector, logonIdSelector, userIdSelector } from "../../redux/selectors/user";
import { EMPTY_STRING } from "../../constants/common";
import { isFetchingSelector, numItemsSelector, orderItemsSelector } from "../../redux/selectors/order";
import {
  hdmEmarsysCategoryTagData,
  hdmEmarsysSearchTagData,
  hdmEmarsysPDPTagData,
  guestUserDataSelector,
  hdmEmarsysTYPTagData,
  hdmEmarsysWidgetType,
  hdmEmarsysAdditionalReady,
  hdmEmarsysRegularReady,
  hdmEmarsysBundleItemsReady,
  onlineStoreRdc,
} from "../../redux/selectors/hdmData";
import { FETCH_HDM_DATA_REQUESTED } from "../../redux/action-types/hdmData";
import {
  CHECKOUT_SHIPPING,
  HOME,
  ORDER_CONFIRMATION,
  SEARCH,
  PRODUCT_PAGE_EMARSYS,
  CATALOG_PAGE_EMARSYS,
} from "../../constants/routes";
import { CART } from "../../constants/routes";
import { seoSelector } from "../../redux/selectors/seo";
import { CHILD_ROUTE_SEPARATOR } from "../constants/common";
import * as allRoutes from "../../constants/routes";
import axios from "axios";
import { useSite } from "./useSite";
import { triggerConversionsPinterestViewCategory } from "../../components/custom-components/conversion-api/pinterest/pinterest-conversion-utils";
import * as CryptoJS from 'crypto-js'; 

export const useEmarsysTags = (location) => {
  const numItemsCart = useSelector(numItemsSelector);
  const currentStore = useSelector(currentStoreSelector);
  const userLoggedIn = useSelector(loginStatusSelector);
  const isFetching = useSelector(isFetchingSelector);
  const orderItems = useSelector(orderItemsSelector);
  const itemQtys = useMemo( () => orderItems.map(item=>item.quantity),[orderItems]);
  const lenght = orderItems.length;
  const searchTermData = useSelector(hdmEmarsysSearchTagData);
  const categoryTagData = useSelector(hdmEmarsysCategoryTagData);
  const PDPTagData = useSelector(hdmEmarsysPDPTagData);
  const seoConfig = useSelector(seoSelector);
  const emarsysTYPTagData = useSelector(hdmEmarsysTYPTagData);
  const emarsysWidgetType = useSelector(hdmEmarsysWidgetType);
  const emarsysBundleItemsReady = useSelector(hdmEmarsysBundleItemsReady);
  const emarsysAdditionalReady = useSelector(hdmEmarsysAdditionalReady);
  const emarsysRegularReady = useSelector(hdmEmarsysRegularReady);
  const dispatch = useDispatch();
  const { mySite } = useSite();
  const isB2B = Boolean(mySite?.isB2B);

  const [emarsysfetchingTags, setEmarsysfetchingTags] = useState(false);
  const [evaluatingSeo, setEvaluatingSeo] = useState(false);
  const [emarsysCarruselReady, setEmarsysCarruselReady] = useState(false);
  const [currentLocation, setCurrentLocation] = useState("");
  const email = useSelector(logonIdSelector);
  const guestUserData = useSelector(guestUserDataSelector);
  const [emarsysTags, setEmarsysTags] = useState<Array<string>>([]);
  const home = ["availabilityZone", "cart"];
  const cart = ["availabilityZone", "cart"];
  const search = ["availabilityZone", "cart", "searchTerm", "tagSearch"];
  const category = ["availabilityZone", "cart", "category", "tagCategory"];
  // const pdp = ["availabilityZone", "cart", "view", "exclude"];
  let pdp = ["availabilityZone", "cart", "view"];
  const [hasGOTriggered, setHasGOTriggered] = useState(false);
  
  if(isB2B){
    pdp = ["availabilityZone", "cart"];
  }
  let checkout = ["availabilityZone", "cart", "setEmail"];
  let typ = ["availabilityZone", "cart", "setEmail", "purchase"];
  const storeConfData = useSelector(onlineStoreRdc);
  const userId = useSelector(userIdSelector);
  if (storeConfData?.ENABLE_EMARSYS_IDENTIFIER_FIELD !== undefined
    && storeConfData?.ENABLE_EMARSYS_IDENTIFIER_FIELD === 'true') {
      checkout = ["availabilityZone", "cart", "setCustomerId"];
      typ = ["availabilityZone", "cart", "setCustomerId", "purchase"];
  }

  const sha256value = (userIdVal) => {
    return CryptoJS.SHA256(userIdVal).toString(CryptoJS.enc.Hex);
  };

  const identifier: string = useMemo(() => {
    const _i = location?.pathname;

    if (_i.startsWith(PRODUCT_PAGE_EMARSYS)) {
      return decodeURI(_i.split(PRODUCT_PAGE_EMARSYS)[1]);
    }

    if (_i.startsWith(CATALOG_PAGE_EMARSYS)) {
      return decodeURI(_i.split(CATALOG_PAGE_EMARSYS)[1]);
    }

    if (_i && _i.split(CHILD_ROUTE_SEPARATOR).length > 0) {
      return decodeURI(_i.split(CHILD_ROUTE_SEPARATOR)[1]);
    }
    return "";
  }, [location]);

  const routes = useMemo(() => {
    const route: string[] = [];
    for (const key in allRoutes) {
      const procesedURL = decodeURI(allRoutes[key].split(CHILD_ROUTE_SEPARATOR)[1]);
      if (procesedURL !== EMPTY_STRING) {
        route.push(procesedURL);
      }
    }
    return route;
  }, [allRoutes]);

  const emarsysSendedTags = useMemo( () => {
    const tmpTags = {};
    // if(userLoggedIn && (!email || email === EMPTY_STRING)) return tmpTags
    if (emarsysTags.length > 0 && emarsysfetchingTags === true) {

      for (let i = 0; i < emarsysTags.length; i++) {
        switch (emarsysTags[i]) {
          case "availabilityZone":
            if (currentStore?.currentStore?.stLocId) {
              tmpTags["availabilityZone"] = ["availabilityZone", currentStore?.currentStore?.stLocId];
            }
            break;
          case "searchTerm":
            if (searchTermData?.searchTerm) {
              tmpTags["searchTerm"] = searchTermData.searchTerm;
            }
            break;
          case "tagSearch":
            if (searchTermData?.tag) {
              tmpTags["tagSearch"] = searchTermData.tag;
            }
            break;
          case "tagCategory":
            if (categoryTagData?.tag) {
              tmpTags["tagCategory"] = categoryTagData.tag;
            }
            break;
          case "category":
            if (categoryTagData?.category) {
              tmpTags["category"] = categoryTagData.category;
            }
            break;
          case "view":
            if (PDPTagData?.view) {
              tmpTags["view"] = PDPTagData.view;
            }
            break;
          case "exclude":
            if (PDPTagData?.exclude) {
              tmpTags["exclude"] = PDPTagData.exclude;
            }
            break;
          case "setEmail":
            if (userLoggedIn === false && guestUserData?.email) {
              tmpTags["setEmail"] = ["setEmail", guestUserData.email];
            }
            break;
            case "setCustomerId":
            if (storeConfData?.ENABLE_EMARSYS_IDENTIFIER_FIELD !== undefined
              && storeConfData?.ENABLE_EMARSYS_IDENTIFIER_FIELD === 'true') {
                if (userLoggedIn === false && userId !== null) { 
                  tmpTags["setCustomerId"] = ["setCustomerId", sha256value(userId)];
                }
            } 
            break;
            
          case "purchase":
            if (emarsysTYPTagData?.purchase) {
              tmpTags["purchase"] = emarsysTYPTagData.purchase;
            }
            break;
        }
      }
      if (storeConfData?.ENABLE_EMARSYS_IDENTIFIER_FIELD !== undefined
        && storeConfData?.ENABLE_EMARSYS_IDENTIFIER_FIELD === 'true') {
          if(userLoggedIn === true && !tmpTags["setCustomerId"]){
            tmpTags["setCustomerId"] = ["setCustomerId", sha256value(userId)];
          }
      } else if (userLoggedIn === true && !tmpTags["setEmail"]) {
        if ((email && email !== EMPTY_STRING) || email !== undefined) {
          tmpTags["setEmail"] = ["setEmail", email];
        }
      }
      if (isFetching === false) {
        tmpTags["cart"] = ["cart", []];

        if (orderItems.length > 0) {
          const items: any = [];

          for (let i = 0; i < orderItems.length; i++) {
            const itemobj = {
              item: orderItems[i]?.partNumber,
              price: parseFloat(orderItems[i].orderItemPrice),
              quantity: parseInt(orderItems[i].quantity),
            };
            if (!orderItems[i]?.partNumber?.includes("SI") && !orderItems[i]?.partNumber?.includes("TV")) {
              items.push(itemobj);
            }
          }
          tmpTags["cart"] = ["cart", items];
        }
      }
    }

    return tmpTags;
  }, [
    currentStore,
    emarsysTags,
    userLoggedIn,
    email,
    emarsysfetchingTags,
    isFetching,
    searchTermData,
    categoryTagData,
    PDPTagData,
    emarsysTYPTagData,
    orderItems
  ]);

  useEffect(() => {
    // setCurrentLocation(location?.pathname);
    setEmarsysTags([]);
    setEmarsysfetchingTags(true);
    setEmarsysTagPage(location.pathname);

  }, [location, userLoggedIn, email]);

  useEffect(() => {
    const _i = location?.pathname;
    if (isB2B && emarsysfetchingTags && (_i.startsWith(PRODUCT_PAGE_EMARSYS) && emarsysBundleItemsReady)) {
      sendEmarsysTags();

    }else if (isB2B && emarsysfetchingTags && !_i.startsWith(PRODUCT_PAGE_EMARSYS)){
      sendEmarsysTags();
    }else if (!isB2B && emarsysfetchingTags && emarsysCarruselReady){
      sendEmarsysTags();
    }
  }, [emarsysSendedTags, emarsysfetchingTags, emarsysCarruselReady, emarsysBundleItemsReady]);

  useEffect(() => {
    if (isB2B && seoConfig[identifier] && seoConfig[identifier].layout.containerName !== "product-page") {
      sendEmarsysTags();
    }
  }, [seoConfig, identifier]);

  useEffect(() =>{
    if (hasGOTriggered && orderItems.length > 0) {
      const items: any = [];
      for (let i = 0; i < orderItems.length; i++) {
        const priceTwoDecimals = parseFloat(orderItems[i].orderItemPrice).toFixed(2);
        const itemobj = {
          item: orderItems[i]?.partNumber,
          price: parseFloat(priceTwoDecimals),
          quantity: parseInt(orderItems[i].quantity),
        };
        if (!orderItems[i]?.partNumber?.includes("SI")) {
          items.push(itemobj);
        }
      }
      const itemsTag = ["cart", items];
      window.ScarabQueue.push(itemsTag);
      window.ScarabQueue.push(["go"]);
    }
  }, [lenght, ...itemQtys]);

  useEffect(() => {
    if (emarsysWidgetType) {
      if (emarsysWidgetType.count === 0) {
        setEmarsysCarruselReady(true);
      } else {
        if (emarsysWidgetType.count === 1 && (emarsysAdditionalReady || emarsysRegularReady)) {
          setEmarsysCarruselReady(true);
        }
        if (emarsysWidgetType.count >= 2 && emarsysAdditionalReady && emarsysRegularReady) {
          setEmarsysCarruselReady(true);
        }
      }
    }
  }, [emarsysWidgetType, emarsysAdditionalReady, emarsysRegularReady]);

  useEffect(() => {
    if (emarsysfetchingTags === false) {
      resetSelectors();
    }
  }, [emarsysfetchingTags]);

  useEffect(() => {
    if (evaluatingSeo === true && seoConfig[identifier]) {
      if (seoConfig[identifier]?.layout?.containerName) {
        if ((seoConfig[identifier].layout.containerName === "product-page" || seoConfig[identifier].layout.containerName === "kit-page") && !PDPTagData) return;
        if (seoConfig[identifier].layout.containerName === "category-landing-page" && !categoryTagData) return;
        switch (seoConfig[identifier].layout.containerName) {
          case "product-listing-page":
            if (identifier === "s") {
              setEmarsysTags(search);
            } else {
              setEmarsysTags(category);
            }
            break;
          case "category-landing-page":
            setEmarsysTags(category);
            triggerConversionsPinterestViewCategory(categoryTagData, isB2B, false); 
            break;
          case "product-page":
            setEmarsysTags(pdp);
            break;
          case "kit-page":
            setEmarsysTags(pdp);
            break;
          default:
            setEmarsysTags(home);
            break;
        }
        setEvaluatingSeo(false);
      }
    }
  }, [evaluatingSeo, identifier, seoConfig, PDPTagData, categoryTagData]);

  const setEmarsysTagPage = (pathname) => {
    const locationPath = pathname.slice(0, 3).trim();
    // return
    switch (pathname) {
      case HOME:
        setEmarsysTags(home);
        break;
      case SEARCH:
        setEmarsysTags(search);
        break;
      case CART:
        setEmarsysTags(cart);
        break;
      case CHECKOUT_SHIPPING:
        setEmarsysTags(checkout);
        break;
      case ORDER_CONFIRMATION:
        setEmarsysTags(typ);
        break;
      default:
        // if (routes.includes(identifier)) {
        if (routes[identifier]) {
          setEmarsysTags(home);
        } else {
          setEvaluatingSeo(true);
        }
        break;
    }
  };

  // ScarabQueue.push(['searchTerm', 'tornillo'])

  const sendEmarsysTags = () => {

    let canSendTags = true;
    if (emarsysTags.length > 0) {
    
    for (let i = 0; i < emarsysTags.length; i++) {
      if (!emarsysSendedTags[emarsysTags[i]]) {
          canSendTags = false;
          break;
        }
      }
      if (storeConfData?.ENABLE_EMARSYS_IDENTIFIER_FIELD !== undefined
        && storeConfData?.ENABLE_EMARSYS_IDENTIFIER_FIELD === 'true') {
          if(userLoggedIn === true && !emarsysSendedTags["setCustomerId"]){
            canSendTags = false;
          }       
      } else if (userLoggedIn === true && !emarsysSendedTags["setEmail"]) {
        canSendTags = false;
      }

      if (canSendTags === true) {

        for (const key in emarsysSendedTags) {
          addSearchTag(emarsysSendedTags[key], false);
        }
        addSearchTag(null, true);
      }
    }
  };
  const addSearchTag = (data, sendGo = true) => {

    if (data !== null) {
      if ((storeConfData?.ENABLE_EMARSYS_IDENTIFIER_FIELD !== undefined
        && storeConfData?.ENABLE_EMARSYS_IDENTIFIER_FIELD === 'true') && storeConfData?.ENABLE_EMARSYS_TEST_MODE !== undefined
        && storeConfData?.ENABLE_EMARSYS_TEST_MODE === 'true') {
          window.ScarabQueue.push(['testMode']);
        }
      window.ScarabQueue.push(data);
    }
    if (sendGo) {
      setEmarsysfetchingTags(false);
      window.ScarabQueue.push(["go"]);
      setHasGOTriggered(true);

      if(seoConfig && identifier !== "" && (seoConfig[identifier]?.layout?.containerName === "product-page" || seoConfig[identifier]?.layout?.containerName === "kit-page")){

        const query = {
          url: "",
          data: 'product-page',
          option: 'EMARSYS_PDP_TAG_FINISHED',
          fetch: false,
        };
        dispatch({ type: FETCH_HDM_DATA_REQUESTED, query });
      }
    }
  };
  const resetSelectors = () => {
    const emarsysTagData = [
      // "EMARSYS_SEARCH_TAG_DATA",
      "EMARSYS_CATEGORY_TAG_DATA",
      "EMARSYS_PDP_TAG_DATA",
      "EMARSYS_TYP_TAG_DATA",
      // "EMARSYS_WIDGETS_TYPES",
      "EMARSYSCARRUSEL_ADDITIONAL_READY",
      "EMARSYSCARRUSEL_REGULAR_READY",
      "EMARSYSBUNDLE_ITEMS_READY",
    ];
    setEmarsysCarruselReady(false);
    emarsysTagData.map((tag) => {
      const query = {
        url: "",
        data: null,
        option: tag,
        fetch: false,
      };
      dispatch({ type: FETCH_HDM_DATA_REQUESTED, query });
    });
  };
};
