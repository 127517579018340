import { useTranslation } from "react-i18next";

import { StyledBox, StyledTypography } from "../../elements";
import { numberWithCommas } from "../../functions";
import styled from "styled-components";
import { getRegularProPrice } from "../../functions/getPromoicons";
import { useSite } from "../../../_foundation/hooks/useSite";
import { loginStatusSelector } from "../../../redux/selectors/user";
import { useSelector } from "react-redux";
import { formatProductPrice } from "../../functions/utils";
import { WEIGHT_BOX, WEIGHT_LM, WEIGHT_MTK, WEIGHT_MTR } from "../../functions/constants";

export const ContainerStyledPriceBox = styled(({ ...props }) => <StyledBox {...props} />)`
  ${({ theme, color }) => `

  &.product-card-precio {
    display: flex;
    padding-left: ${theme.spacing(2)}px;
    padding-right: ${theme.spacing(2)}px;
    margin-bottom: ${theme.spacing(2)}px;
  }

  .price-card-height {
    height: 71px;
  }

  .colorHighlight {
    color: ${theme.palette.text.highlight};
  }

  .colorGray300Line {
    color: ${theme.palette.gray["300"]};
    text-decoration: line-through;
  }

  .promo_plp-ribbon-sb {
    width: 37px;
    height: 24px;
  }

  .superscript-text-price {
    font-size: 16px;
  }

  .superscript-small-price {
    font-size: 10px;
  }

`}
`;

interface Props {
  catentryData: any;
  promociones: any;
  quantityMeasure: any;
  weightMeasure: any;
  isSmallSize?: any;
}

const PrecioPesoMedida = (props: Props) => {
  const { 
      catentryData, 
      promociones, 
      quantityMeasure, 
      weightMeasure, 
      isSmallSize } = props;
  const { t } = useTranslation();
  const { mySite } = useSite();
  const isB2B = mySite?.isB2B;
  const loginStatus = useSelector(loginStatusSelector);


  const price: any = numberWithCommas(catentryData);

  let isMeassure = false;
  let ahorras = false;
  if (quantityMeasure === "m2" || quantityMeasure === "m") {
    isMeassure = true;
  }

  if (promociones) {
    if (promociones.includes("PROMOICON_NLP") || promociones.includes("PROMOICON_GR")) {
      ahorras = true;
    }
  }

  let isValidweightMeasure = false
  if(weightMeasure){
    if(quantityMeasure === WEIGHT_MTR && (weightMeasure === WEIGHT_BOX || weightMeasure === WEIGHT_LM)){
      isValidweightMeasure = true
    }
    if(quantityMeasure === WEIGHT_MTK && (weightMeasure === WEIGHT_BOX || weightMeasure === WEIGHT_LM)){
      isValidweightMeasure = true
    }
  }

  if (!isMeassure) return null;

  if (isSmallSize)
    return (
      <ContainerStyledPriceBox className="">
        <StyledBox className="">
          {isMeassure ? (
            <StyledTypography variant={"bodySubText"} fontWeight={600}>
              {t("productBox.byBox")}
            </StyledTypography>
          ) : null}
          <StyledTypography variant="textSecondaryPrice">
            <sup className="superscript-small-price">$</sup>
            {isMeassure ? price.offerUnidad : price.offerUnidad}
            <sup className="superscript-small-price">{isMeassure ? price.offerDecimal : price.offerDecimal}</sup>
          </StyledTypography>
        </StyledBox>
      </ContainerStyledPriceBox>
    );

    if(isB2B && loginStatus && !ahorras){
      price.proRegularPrice = getRegularProPrice(catentryData.price)
    }

  return (
    <>
      <StyledBox mb={1}>
        <StyledTypography variant="bodyBaseline" fontWeight="bold">
          {quantityMeasure !== "" && isValidweightMeasure  ? `por ${weightMeasure}` : ""}
        </StyledTypography>
      </StyledBox>
      <StyledBox>
        <StyledTypography className="product-price-per-unit" variant={"textSecondaryPrice"}>
          <sup style={{ fontSize: "12px" }}>$</sup>
          {isMeassure ? price.offerUnidad : price.offerUnidad}
          <sup style={{ fontSize: "12px" }}>{isMeassure ? price.offerDecimal : price.offerDecimal}</sup>
        </StyledTypography>
      </StyledBox>
      {price?.proRegularPrice ?
        <ContainerStyledPriceBox >
          <StyledTypography variant="bodySubText" component="span" className="color-gray300">
            Precio regular{" "}
          </StyledTypography>
          <StyledTypography variant="bodySubText" className="colorGray300Line" component="span">
          {formatProductPrice(price.proRegularPrice)}
          </StyledTypography>
        </ContainerStyledPriceBox>
        : ahorras && price.displayPrice !== "0.00" && !isSmallSize ? (
        <ContainerStyledPriceBox>
          <StyledTypography variant="bodySubText" className="colorGray300Line" >
            Antes {`$${price.displayPrice}`}
          </StyledTypography>
        </ContainerStyledPriceBox>
      ) : null}
    </>
  );
};

export default PrecioPesoMedida;
