import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { StyledBox } from "../box";
import { StyledRating } from "../rating/styled-rating";
import { StyledTypography } from "../typography";

import {
  triggerSelectItemDL,
} from "../../../components/custom-components/Datalayer-Utils/dataLayer-func";

import { loginStatusSelector } from "../../../redux/selectors/user";
import { useSelector } from "react-redux";
import { proCustomerLevelSelector } from "../../../redux/selectors/organization";
import { currentStoreSelector } from "../../../redux/selectors/current-store";
import { currentContractIdSelector } from "../../../redux/selectors/contract";
import { onlineStoreRdc } from "../../../redux/selectors/hdmData";
import { useSite } from "../../../_foundation/hooks/useSite";
import { logEventWithValues } from "../../functions/utils";
export const ContainerStyledBasicInfo = styled(({ ...props }) => <StyledBox {...props} />)`
  ${({ theme }) => `

  &.styled--productcard-basicinfo {
    ${theme.breakpoints.up("sm")} {
      padding-left: ${theme.spacing(2)}px;
      padding-right: ${theme.spacing(2)}px;
      padding-top: ${theme.spacing(2)}px;
      padding-bottom: ${theme.spacing(2)}px;
      height: 136px;
      
    }
    ${theme.breakpoints.down("sm")} {
      padding-left: ${theme.spacing(2)}px;
      padding-bottom: ${theme.spacing(2)}px;
      height: 136px;

      overflow-x: auto;
      overflow-y: hidden;
    }
  }

  .styled--productcard-title {

    ${theme.breakpoints.down("sm")} {
      display: flex;
      max-height: 56px;
      overflow: hidden;
      margin-bottom: ${theme.spacing(2)}px;
    }

    ${theme.breakpoints.up("sm")} {
      display: flex;
      max-height: 56px;
      overflow: hidden;
      margin-bottom: ${theme.spacing(2)}px;
    }

  }

  .styled--produtcard-rewiev {
    margin-bottom: ${theme.spacing(2)}px;
    display: flex;
    height: 20px;
  }

  .styled--rating-container {
    padding: 0;
    color: ${theme.palette.primary.main};
    .MuiRating-iconEmpty {
        color: ${theme.palette.gray["50"]};
    }
  }

  .styled--link-container {
    color: ${theme.palette.gray["500"]};
    text-decoration: none;
  }

 
`}
`;

interface Props {
  product: any;
  productData: any;
  to: any;
  isEmarsysProduct?: any;
}

const ProductCardBasicInfo = (props) => {
  const { productPartNumber, productManufacturer, productName, ratingPromedio, ratingReviews, to, isEmarsysProduct } = props;
  const { t } = useTranslation();
  const loginStatus = useSelector(loginStatusSelector);
  const proCustomerLevel = useSelector(proCustomerLevelSelector);
  const currentStore = useSelector(currentStoreSelector);
  const currentMarketId = currentStore && currentStore?.currentStore?.marketId;
  const currentStoreId = currentStore && currentStore?.currentStore?.stLocId;
  const contract = useSelector(currentContractIdSelector);
  const storeConfData = useSelector(onlineStoreRdc);
  const itemAffiliation = storeConfData?.DL_ITEM_AFFILIATION;
  const { mySite } = useSite();
  const isB2B = mySite?.isB2B;
  const firstWord = productName && productName?.split(" ")[0];
  const longWords = productManufacturer && firstWord && (productManufacturer + firstWord)?.toString()?.length > 15;
  const isMobileApp = window.navigator.userAgent.includes("THDMXAPP");

  return (
    <ContainerStyledBasicInfo className={"styled--productcard-basicinfo"}>
      <StyledBox className={"styled--productcard-title"}>
        <StyledTypography component="p">
          <StyledTypography variant={"bodyBaseline"} weight="bold" component="span" className="product-brand">
            {productManufacturer}
          </StyledTypography>
          {isEmarsysProduct && longWords && <br />}
          <Link
            onClick={(e) => {
              triggerSelectItemDL(
                mySite.storeID,
                isB2B && loginStatus && proCustomerLevel
                  ? currentStore?.currentStore?.stLocId + "_" + proCustomerLevel
                  : currentStore?.currentStore?.stLocId,
                contract,
                productPartNumber,
                1,
                itemAffiliation,
                currentMarketId,
                isB2B
              );
              if (isMobileApp) {
                logEventWithValues("navigateToPDP", to);
                e.preventDefault(); // Prevent the default link behavior
              }
            }}
            to={to}
            className="styled--link-container">
            <StyledTypography
              variant={"bodyBaseline"}
              component="span"
              className={
                !productManufacturer || (isEmarsysProduct && longWords)
                  ? "product-name"
                  : "left-margin-1 product-name"
              }>
              {productName}
            </StyledTypography>
          </Link>
        </StyledTypography>
      </StyledBox>

      <StyledBox className="styled--produtcard-rewiev">
        <StyledBox>
          <StyledRating
            name="read-only"
            value={Math.round(ratingPromedio)}
            readOnly
            size="small"
            className="styled--rating-container"
          />
        </StyledBox>
        <StyledBox className="left-margin-1">
          <StyledTypography variant={"bodySubText"} weight={600} component="span">
            {parseFloat(ratingPromedio).toFixed(1)}
          </StyledTypography>
          <StyledTypography variant={"bodySubText"} weight={600} component="span" className="left-margin-1">
            ({ratingReviews})
          </StyledTypography>
        </StyledBox>
      </StyledBox>
      <StyledBox>
        <StyledTypography variant={"bodySubText"} className="color-gray300">
          {t("productBox.sku")} <span className="product-sku">{productPartNumber}</span>
        </StyledTypography>
      </StyledBox>
    </ContainerStyledBasicInfo>
  );
};

export default ProductCardBasicInfo;
