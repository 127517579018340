import Axios, { Canceler } from "axios";
import { useState, useMemo } from "react";
import getDisplayName from "react-display-name";
import { useDispatch, useSelector } from "react-redux";

import { useSite } from "../../_foundation/hooks/useSite";
import { currentContractIdSelector } from "../../redux/selectors/contract";
import * as orderActions from "../../redux/actions/order";
import { useTranslation } from "react-i18next";

import { StyledButton } from "../elements/button";
import {
  installationPartNumber,
  hdmDrawerColorDataRdc,
  hdmColorsRdc,
  lineaGamaColorsRdc,
  onlineStoreRdc,
} from "../../redux/selectors/hdmData";
import { checkIfHasColorBase } from "./utils";
import { getCookieValue, setCookieCAPI } from "../../components/custom-components/conversion-api/cookieHandler";
import { triggerAddToCartDL } from "../../components/custom-components/Datalayer-Utils/dataLayer-func";
import { currentStoreSelector } from "../../redux/selectors/current-store";
import { loginStatusSelector } from "../../redux/selectors/user";
import { proCustomerLevelSelector } from "../../redux/selectors/organization";
import { LoaderActions } from "../../components/custom-components/skeleton-loader/actions/sl-actions";
import { OPEN_BACKDROP_LOADER_ACTION } from "../../redux/actions/backdrop-loader";
import withSkeletonLoader from "../../components/custom-components/skeleton-loader/hoc/withSkeletonLoader";
import { StyledSkeleton } from "../../components/custom-components/skeleton-loader/components/StyledSkeleton";
import { loaders } from "../../components/custom-components/skeleton-loader/components";
import { backdropLoaderStateAndSrcSelector } from "../../redux/selectors/backdrop-loader";
import { OPEN_CART_DRAWER_ACTION } from "../../redux/actions/drawer";
import { triggerConversionsPinterestAddToCart } from "../../components/custom-components/conversion-api/pinterest/pinterest-conversion-utils";
import { cartSelector } from "../../redux/selectors/order";

interface Props {
  partnumber: any;
  quantity: string;
  variant: string;
  wording: string;
  manufacturer: string;
  colorCodeSelected?: any;
  disabled?: boolean;
  attributes: any;
  handleClose?: () => void;
}

const AddProductCart = (props: Props) => {
  const { partnumber, quantity, variant, wording, manufacturer, attributes, disabled, colorCodeSelected, handleClose } =
    props;
  const { mySite } = useSite();
  const storeId: string = mySite ? mySite.storeID : "";
  const contract = useSelector(currentContractIdSelector);
  const [addItemActionTriggered, setAddItemActionTriggered] = useState<boolean>(false);
  const dispatch = useDispatch();
  const CancelToken = Axios.CancelToken;
  const cancels: Canceler[] = useMemo<Canceler[]>(() => [], []);
  const { t } = useTranslation();
  const hdmDrawerColorData = useSelector(hdmDrawerColorDataRdc);
  const hdmLineaGamaColor = useSelector(lineaGamaColorsRdc);
  const hdmColors = useSelector(hdmColorsRdc);
  let colorCode: any = null;
  const installationPartNumberData = useSelector(installationPartNumber);

  const widgetName = getDisplayName("ProductDetailsWidget");
  const currentStore = useSelector(currentStoreSelector);
  const storeConfData = useSelector(onlineStoreRdc);
  const loginStatus = useSelector(loginStatusSelector);
  const proCustomerLevel = useSelector(proCustomerLevelSelector);
  const cart = useSelector(cartSelector);
  const loaderState = useSelector(backdropLoaderStateAndSrcSelector);

  const payloadBase: any = {
    storeId: storeId,
    widget: widgetName,
    cancelToken: new CancelToken(function executor(c) {
      cancels.push(c);
    }),
  };

  const hasProductBaseColor = checkIfHasColorBase(hdmColors, { manufacturer, attributes });
  let baseGamaColorDefault: any = null;
  if (hasProductBaseColor) {
    const { branchColor, lineColor, baseColor } = hasProductBaseColor;
    const pinturasHDM = hdmLineaGamaColor;
    const colorBase = pinturasHDM.filter(
      (database) => database?.marca === branchColor && database?.linea === lineColor && database.gama === "#N/D"
    )[0];
    baseGamaColorDefault = colorBase?.colores.filter((color) => color?.base === baseColor)[0];
    colorCode = baseGamaColorDefault?.valor;
  }

  const handleAgregarCarrito = () => {
    dispatch(OPEN_CART_DRAWER_ACTION({source: "addProduct"}));
    dispatch(OPEN_BACKDROP_LOADER_ACTION({ src: LoaderActions.ADD_PRODUCT_TO_CART, idx: partnumber }));
    const hasInstallation = installationPartNumberData !== "";
    const installationQuantity = quantity[0];
    if (hasInstallation) {
      partnumber.push(installationPartNumberData);
      quantity.push(installationQuantity);
    }
    if (!quantity || quantity === "") {
      return;
    }

    let isMainPaint = false;

    if (hdmDrawerColorData?.partNumber) {
      if (typeof partnumber === "object" && partnumber.includes(hdmDrawerColorData?.partNumber)) {
        isMainPaint = true;
      }
    }

    // let colorCode: any = null
    // if(hdmDrawerColorData?.colorCodeSelected){
    //   colorCode = hdmDrawerColorData?.colorCodeSelected?.codigo !== 'N/A' ? hdmDrawerColorData?.colorCodeSelected?.codigo : hdmDrawerColorData?.colorCodeSelected?.valor
    // }
    const eventId = setCookieCAPI("mfbt");
    const fbc = getCookieValue("_fbc");
    const fbp = getCookieValue("_fbp");
    const userAgent = window.navigator.userAgent;
    const eventSource = window.location.href;
    const marketId = getCookieValue(`WC_MarketId_${storeId}`);

    const param = {
      partnumber: partnumber,
      quantity: quantity,
      contractId: contract,
      colorCode: colorCode
        ? colorCode
        : !isMainPaint
        ? null
        : hdmDrawerColorData?.colorCode
        ? hdmDrawerColorData?.colorCode
        : null,
      mfbt: eventId,
      fbc: fbc,
      fbp: fbp,
      userAgent: userAgent,
      marketId: marketId,
      ...payloadBase,
    };
    // console.log("entro currentSelection", props);
    // console.log("entro payloadBase", payloadBase);
    setAddItemActionTriggered(true);
    dispatch(orderActions.ADD_ITEM_ACTION(param));
    const itemAffiliation = storeConfData?.DL_ITEM_AFFILIATION;
    triggerAddToCartDL(
      mySite.storeID,
      mySite?.isB2B && loginStatus && proCustomerLevel
        ? currentStore?.currentStore?.stLocId + "_" + proCustomerLevel
        : currentStore?.currentStore?.stLocId,
      contract,
      partnumber,
      quantity,
      itemAffiliation,
      currentStore?.currentStore?.marketId,
      mySite?.isB2B
    );

    
    triggerConversionsPinterestAddToCart(
      mySite?.storeID,
      mySite?.isB2B && loginStatus && proCustomerLevel ? currentStore?.currentStore?.stLocId + "_" + proCustomerLevel : currentStore?.currentStore?.stLocId,
      contract,
      partnumber,
      quantity,
      currentStore?.currentStore?.marketId,
      mySite?.isB2B,
      cart?.orderId
    );
  };

  if (disabled)
    return (
      <StyledButton variant="contained" disableRipple={true} fullWidth disabled={disabled}>
        {wording}
      </StyledButton>
    );

  return (
    <StyledButton
      className="add-to-cart"
      variant={variant}
      disableRipple={true}
      fullWidth
      onClick={handleAgregarCarrito}
      disabled={quantity === "" || quantity === "0" ? true : false}>
      {wording}
    </StyledButton>
  );
};

export default AddProductCart;
