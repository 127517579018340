import React, { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { MenuItem, MenuList } from "@material-ui/core";
import { Divider } from "@material-ui/core";
import styled from "styled-components";
import {
  StyledCircularProgress,
  StyledList,
  StyledListItem,
  StyledTypography,
  StyledListItemText,
  StyledGrid,
} from "../../../hdm";
import { palette } from "../../../hdm/themes";
import { StyledBox } from "@hcl-commerce-store-sdk/react-component";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import { useNavigate } from "react-router";
import CardIcon from "../../custom-components/shared/CardIcon";
import { StyledBoxProgressHDM } from "./categories-styles";
import SendIcon from "../../../assets/homedepot/images/send-mail.svg";

import EspotHdm from "../../custom-components/shared/EspotHdm";
import { withUseJsonESpot } from "../../../hdm/widgets/json-espot";
import eSpotService from "../../../_foundation/apis/transaction/eSpot.service";
import { useSite } from "../../../_foundation/hooks/useSite";
import Axios, { Canceler } from "axios";
import HTMLReactParser from "html-react-parser";
import { CATALOG_PREFIX } from "../../../constants/routes";
import withSkeletonLoader from "../../custom-components/skeleton-loader/hoc/withSkeletonLoader";
import { loaders } from "../../custom-components/skeleton-loader/components";

const OPTIONS_MENU = [
  <EspotHdm espotName="Menu_SuperiorSection" />,
  "Departamentos",
  <EspotHdm espotName="Menu_inferiorSection1" />,
  <EspotHdm espotName="Menu_inferiorSection2" />,
  <EspotHdm espotName="Menu_inferiorSection3" />,
  <EspotHdm espotName="Menu_inferiorSection4" />,
  <EspotHdm espotName="Menu_inferiorSection5" />,
];

const StyledListMenuHDM = styled((props: any) => <StyledList {...props} />)`
  ${({ theme, isB2B }) => `
      border-top: 2px solid ${palette.primary.main};
      display: flex;
      flex-wrap: ${isB2B ? "wrap" : "nowrap"};
      align-items: center;
      justify-content: left;
      width: 100%;
      margin: 0 auto;
      max-width: 1440px;
      .MuiTypography-root {
        transition: all ease-in-out .2s;
        margin: 0 ${theme.spacing(2)}px;
        &:hover{
          color: ${palette.primary.main};
          cursor: pointer;
        }
      }
      ${theme.breakpoints.up(1023)}{
      .MuiTypography-root{
          font-size: 12.5px;
        }
      }
  `}
`;

const StyledBoxMenu = styled((props: any) => <StyledGrid {...props} />)`
  ${({ theme, isB2B }) => `
        background-color: ${isB2B ? theme.palette.gray["500"] : "white"};
        border-radius: 0;
        max-height: 800px;
        width: clac(100%);
        .MuiPaper-root{
            width: 300px;
            border-radius: 0;
            .MuiList-root{
                padding:0;
            }
        }

        .styled--underline-menucolor {
          color: ${theme.palette.background.paper};
          text-decoration-line: underline;  
        }

        .styled--underline-menucolor > a {
          color: ${theme.palette.background.paper};
        }

        .styled--underline-menucolor:hover {
          color: ${theme.palette.background.paper};
        }
        
        .styled--menu-colorgray100 {
          color: ${theme.palette.gray["100"]};
        }

        .styled--menu-colorgray100:hover {
          color: ${theme.palette.background.paper};
          text-decoration-line: underline;  
        }

  `}
`;

const StyledBoxCategories = styled(
  React.forwardRef<HTMLElement, any>((props, ref) => <nav ref={ref} {...props} />)
)`
  ${({ theme }) => `
        display: flex;
        position: absolute;
        background: white;
        box-shadow: 5px 1px 5px 0px rgba(145,145,145,0.31);
        -webkit-box-shadow: 5px 1px 5px 0px rgba(145,145,145,0.31);
        -moz-box-shadow: 5px 1px 5px 0px rgba(145,145,145,0.31);
        opacity: 0;
        height: calc(100vh - 121px);
        .MuiPaper-root{
            border-right: 1px solid ${palette.gray["50"]};
            width: 360px;
            border-radius: 0;
            .MuiList-root{
                padding:0;
            }
        }
  `}
`;

const MenuCategories = styled((props: any) => <MenuList {...props} />)`
  ${({ theme }) => `
        width: 360px;
        min-height: 400px;
        max-height: calc(${window.screen.height}px - 115px);
        overflow-y: auto;
        padding: 0;
        border-right: 1px solid ${palette.gray["50"]};
        .MuiPaper-root{
            border-right: 1px solid ${palette.gray["50"]};
            width: 300px;
            border-radius: 0;
            .MuiList-root{
                padding:0;
            }
        }
        .selected{
            background: ${palette.gray["400"]}!important;
            .MuiSvgIcon-root{
                color: white !important;
            }
            .MuiTypography-root {
                color: white;
            }
        }
        .gray{
            background:${palette.gray["50"]}!important;
        }
  `}
`;

const MenuSubCat = styled((props: any) => <MenuList {...props} />)`
  ${({ theme }) => `
        width: 360px;
        border-right: 1px solid ${palette.gray["50"]};
        // max-height: 700px;
        overflow-y: auto;
        padding-top: 0;
        .MuiPaper-root {
            width: 300px;
            position: absolute;
            left: 300px!important;
            border-radius: 0;
            .MuiList-root{
                padding:0;
            }
        }
        .selected{
            background: ${palette.gray["400"]}!important;
            .MuiSvgIcon-root{
                color: white !important;
            }
            .MuiTypography-root {
                color: white;
            }
        }
  `}
`;

const MenuChild = styled((props: any) => <MenuList {...props} />)`
  ${({ theme }) => `
        width: 360px;
        border-right: 1px solid ${palette.gray["50"]};
        max-height: 700px;
        overflow-y: auto;
        padding-top: 0;
        .MuiPaper-root {
            width: 300px;
            position: absolute;
            left: 300px!important;
            border-radius: 0;
            .MuiList-root{
                padding:0;
            }
        }
  `}
`;

export const MenuItemHDM = styled((props: any) => <MenuItem {...props} />)`
  ${({ theme, isB2B }) => `
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px 5px 10px 10px!important;
        border-bottom: 1px solid ${palette.gray["50"]};
        &:hover{
          background-color: ${isB2B ? theme.palette.gray["50"] : ""};
        }
        .MuiTypography-root{
            color: ${palette.gray["500"]};
        }
        .MuiSvgIcon-root{
            color: ${palette.primary.main};
            width: 15px;
            height: 15px;
        }
        &.super-sku-label-unavailable{
          opacity:60%;
        }
  `}
`;

export const StyledBoxContainer = styled((props: any) => <StyledBox {...props} />)`
  ${({ theme }) => `
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        width: 98%;
        margin: 0 auto;
  `}
`;

const StyledListLink = styled((props: any) => <StyledListItem {...props} />)`
  ${({ theme }) => `
        color: ${palette.secondary.main};
        cursor: pointer;
        padding: 0 0 0 ${theme.spacing(1)}px;
        margin-top: -${theme.spacing(2)}px;
  `}
`;

const INIT_CATEGORY = {
  seo: {
    href: "/",
  },
  name: "",
};

export const DATA_FEATURED = [
  { text: "Baños", link: "/banos", svg: SendIcon },
  { text: "Herramientas", link: "/herramientas", svg: SendIcon },
  { text: "Línea Blanca y Cocinas", link: "/linea-blanca-y-cocinas", svg: SendIcon },
  { text: "Organizadores y Clósets", link: "/organizadores-y-closets", svg: SendIcon },
  { text: "Pisos", link: "/pisos", svg: SendIcon },
  { text: "Ventilación y Calefacción", link: "/ventilacion-y-calefaccion", svg: SendIcon },
];

export const sortCategories = (categories: any) => {
  const toSort = categories.children ? categories.children : categories;
  const sort = toSort.sort((a, b) => {
    const nameA = a.name.toLowerCase();
    const nameB = b.name.toLowerCase();
    if (nameA < nameB) return -1;
    if (nameA > nameB) return 1;
    return 0;
  });
  return sort;
};

const MenuDesktop = ({ categories, ...props }) => {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [showMenu, setShowMenu] = useState(false);
  const [openSubCat, setOpenSubCat] = useState(false);
  const [openChildCat, setOpenChildCat] = useState(false);
  const [openFeatured, setOpenFeatured] = useState(false);
  const [subCatSelected, setSubCatSelected] = useState<any>(INIT_CATEGORY);
  const [childCatSelected, setChildCatSelected] = useState<any>(INIT_CATEGORY);

const menuRef = useRef<HTMLElement>(null);

  const { mySite } = useSite();
  const isB2B = Boolean(mySite?.isB2B);
  const storeID: string = mySite ? mySite.storeID : "";
  const catalogID: string = mySite ? mySite.catalogID : "";

  const CancelToken = Axios.CancelToken;
  const cancels: Canceler[] = [];

  const { t } = useTranslation();

  const { menuComponents } = props


  //Sorted
  const [categoriesSorted, setCategoriesSorted] = useState<any>();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>, opt) => {
    if (opt !== "Departamentos") return;
    setAnchorEl(document.getElementById("Departamentos"));
    setShowMenu(true);
    openFeaturedCategory();
    setTimeout(() => {
      const menu = document.getElementById("cont-menu");
      if (!menu) return;
      menu.style.opacity = "1";
    }, 100);
  };

  const openSubCategories = (subCategory) => {
    setSubCatSelected(INIT_CATEGORY);
    setChildCatSelected(INIT_CATEGORY);
    if (!subCategory.children) {
      navigateRoute(subCategory);
      return;
    }
    const subCatSort = {
      ...subCategory,
      children: sortCategories(subCategory.children),
    };
    setSubCatSelected(subCatSort);
    setOpenSubCat(true);
    setOpenChildCat(false);
    setOpenFeatured(false);
  };

  const openChildrenCategories = (childCategory) => {
    if (!childCategory.children) {
      navigateRoute(childCategory);
      return;
    }
    const childCatSort = {
      ...childCategory,
      children: sortCategories(childCategory.children),
    };
    setChildCatSelected(childCatSort);
    setOpenChildCat(true);
  };

  const openFeaturedCategory = () => {
    setOpenSubCat(true);
    setSubCatSelected(INIT_CATEGORY);
    setOpenFeatured(true);
    setOpenChildCat(false);
  };

  const navigateRoute = (categoryToNavigate) => {
    if (!categoryToNavigate.seo && categoryToNavigate.links)
      navigate(`${CATALOG_PREFIX}${categoryToNavigate.links.self.href}`, { replace: false });
    else navigate(`${CATALOG_PREFIX}${categoryToNavigate.seo.href}`, { replace: false });
    handleClose();
    clearData();
  };

  const handleClose = () => {
    setAnchorEl(null);
    setShowMenu(false);
    clearData();
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
      handleClose();
    }
  };
  
  useEffect(() => {
    if (showMenu) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showMenu]);

  const clearData = () => {
    setSubCatSelected(INIT_CATEGORY);
    setChildCatSelected(INIT_CATEGORY);
    setOpenFeatured(false);
    setOpenSubCat(false);
    setOpenChildCat(false);
  };

  useEffect(() => {
    const dep = document.getElementById("Departamentos")?.getBoundingClientRect();
    const menu = document.getElementById("cont-menu");
    if (!dep || !menu) return;
    menu.style.left = `${dep.x}px`;
  }, [showMenu]);

  useEffect(() => {
    const catSort = sortCategories(categories);
    setCategoriesSorted(catSort);
  }, [categories, categoriesSorted]);

  const DepartmentMenu = ({ ...props }) => {
    return (
      <StyledBoxContainer>
        {props.data &&
          props.data.length > 0 &&
          props.data[0]?.content?.cards?.map((card, index) => {
            return (
              <CardIcon data={card} key={index} index={index} closeMenu={() => setShowMenu(false)} display={true} />
            );
          })}
      </StyledBoxContainer>
    );
  };

  const getContent = (data) => {
    if (data?.content) {
      return HTMLReactParser(data.content);
    } else {
      return <></>;
    }
  };

  const MenuEspotOptionsComponent = (props) => {

    const { menuComponents } = props;
    const InnerMenuComponent = () => {
      return (
        <>
          {menuComponents &&
            menuComponents.length > 0 &&
            menuComponents.map((espot, index) => {
              if (index <= 1) {
                return <React.Fragment key={index}></React.Fragment>;
              } else {
                return (
                  <StyledTypography
                    id={`mainMenu_is${index - 1}`}
                    key={index}
                    variant={"bodyBaseline"}
                    aria-controls="simple-menu"
                    aria-haspopup="true"
                    className={`normal-white-space ${isB2B ? "styled--menu-colorgray100" : ""}`}>
                    {getContent(espot)}
                  </StyledTypography>
                );
              }
            })}
        </>
      )
    }

    return withSkeletonLoader(<InnerMenuComponent/>, menuComponents && menuComponents.length > 0, loaders.header.espotMenu)
  }

  return (
    <StyledBoxMenu isB2B={isB2B} border={3} id={"mainMenu"}>
      {/*Menu_SuperiorSection*/}
      <StyledListMenuHDM isB2B={isB2B}>
        <StyledTypography
          id="mainMenu_ss"
          variant={"bodyBaseline"}
          aria-controls="simple-menu"
          aria-haspopup="true"
          className={isB2B ? "styled--menu-colorgray100" : ""}>
          {props.menuComponents &&
            props.menuComponents.length > 0 &&
            props.menuComponents[1]?.content &&
            getContent(props.menuComponents[1])}
        </StyledTypography>

        {/*Departamentos*/}

        <StyledTypography
          variant={"bodyBaseline"}
          aria-controls="simple-menu"
          aria-haspopup="true"
          onClick={(e) => handleClick(e, "Departamentos")}
          id={"Departamentos"}
          style={showMenu && !isB2B ? { color: palette.primary.main } : null}
          className={isB2B ? "styled--underline-menucolor" : ""}
          component={isB2B ? "a" : "p"}>
          {"Departamentos"}
        </StyledTypography>

        {/*rest of the espots*/}

        <MenuEspotOptionsComponent menuComponents={props.menuComponents} />
      </StyledListMenuHDM>

      {showMenu && (
        <StyledBoxCategories ref={menuRef} id={"cont-menu"}>
          <MenuCategories
            id="menu-cat"
            // anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}>
            {!categoriesSorted.length && (
              <StyledBoxProgressHDM className={"circular"}>
                <StyledCircularProgress className={"circular"} />
              </StyledBoxProgressHDM>
            )}

            {!isB2B ? (
              <>
                <MenuItemHDM
                  id="departamento-destacados"
                  onClick={openFeaturedCategory}
                  className={openFeatured && "selected"}>
                  <StyledTypography variant={"bodyBaseline"}>Destacados</StyledTypography>
                  <ArrowForwardIosIcon />
                </MenuItemHDM>
              </>
            ) : (
              <>
                <MenuItemHDM isB2B={isB2B}>
                  <EspotHdm espotName="ProMobileMenu_Departments_SuperiorTrigger" />
                  {/* <ArrowForwardIosIcon /> */}
                </MenuItemHDM>
              </>
            )}

            <StyledListItem isB2B={isB2B} style={{ pointerEvents: "none", padding: "8px 10px" }}>
              <StyledListItemText
                className="line-height-menu"
                primary={
                  <StyledTypography variant={"headingFeatured"} weight="bold">
                    {t("Header.Menu.TodosAZ")}
                  </StyledTypography>
                }
              />
            </StyledListItem>
            <Divider />

            {categoriesSorted.map((category, index) => (
              <React.Fragment key={category.name}>
                <StyledListItem
                  id={`departamento-${index}`}
                  isB2B={isB2B}
                  button
                  onClick={() => openSubCategories(category)}
                  className={
                    category.name === subCatSelected.name && !childCatSelected.name
                      ? "selected"
                      : category.name === subCatSelected.name && childCatSelected.name && "gray"
                  }>
                  <StyledListItemText
                    primary={
                      <StyledTypography id={`menu_dept_${category.id}`} variant={"bodyBaseline"}>
                        {category.name}
                      </StyledTypography>
                    }
                  />
                  <ArrowForwardIosIcon
                    style={{ fontSize: 15, position: "absolute", right: "5px", color: palette.primary.main }}
                  />
                </StyledListItem>
                <Divider />
              </React.Fragment>
            ))}
          </MenuCategories>
          {openSubCat && (
            <MenuSubCat
              id="menu-sub"
              // anchorEl={anchorEl}
              open={openSubCat}
              onClose={handleClose}>
              {openFeatured && !isB2B ? (
                <StyledBox>
                  <MenuItemHDM style={{ borderBottom: "none", pointerEvents: "none", paddingBottom: "5px!important" }}>
                    <StyledTypography variant={"headingFeatured"} style={{ pointerEvents: "none" }}>
                      {t("Header.Menu.Destacados")}
                    </StyledTypography>
                  </MenuItemHDM>
                  <StyledBoxContainer>
                    <EspotHdm espotName='MobileMenu_Departments_SuperiorSection' />
                    {/* <DepartmentMenu data={props.menuComponents} />  */}
                  </StyledBoxContainer>
                </StyledBox>
              ) : (
                <>
                  {isB2B && <EspotHdm espotName="ProMobileMenu_Departments_SuperiorSection" />}
                </>
              )}
              {subCatSelected.name !== "" && subCatSelected.children && (
                <StyledBox>
                  <StyledListItem isB2B={isB2B} style={{ cursor: "pointer", background: "transparent", padding: "8px 10px" }}>
                    <StyledListItemText
                      className="line-height-menu"
                      primary={
                        <StyledTypography
                          id={`categoryTitle_${subCatSelected.id}`}
                          onClick={() => navigateRoute(subCatSelected)}
                          variant={"headingFeatured"}
                          style={{ textDecoration: "underline", width: "100%" }}>
                          {subCatSelected.name.toUpperCase()}
                        </StyledTypography>
                      }
                    />
                  </StyledListItem>
                  <Divider />
                  {console.log("subCatSelected.children", subCatSelected.children)}
                  {subCatSelected.children.map((subCat, index) => (
                    <React.Fragment key={subCat.name}>
                      <StyledListItem
                        id={`categoryLink_${subCatSelected.id}_${subCat.id}`}
                        isB2B={isB2B}
                        button
                        onClick={() => openChildrenCategories(subCat)}
                        className={subCat.name === childCatSelected.name && "selected"}>
                        <StyledListItemText
                          primary={<StyledTypography variant={"bodyBaseline"}>{subCat.name}</StyledTypography>}
                        />
                        <ArrowForwardIosIcon
                          style={{ fontSize: 15, position: "absolute", right: "5px", color: palette.primary.main }}
                        />
                      </StyledListItem>
                      <Divider />
                      {index === (subCatSelected.children.length - 1) ? (
                        <StyledListLink style={{ "margin-top": "10px" }}>
                          {!isB2B ? (
                            <>
                              <EspotHdm espotName={`${subCatSelected.identifier}_MenuCategory_Inferior_Section`} />
                            </>
                          ) : (
                            <>
                              <EspotHdm espotName="_ProMenuCategory_InferiorSection" />
                            </>
                          )}
                        </StyledListLink>
                      ) : ""}
                    </React.Fragment>
                  ))}
                </StyledBox>
              )}
            </MenuSubCat>
          )}

          {openChildCat && childCatSelected.name !== "" && childCatSelected.children && (
            <MenuChild
              id="menu-child"
              // anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleClose}>
              <StyledListItem isB2B={isB2B} style={{ cursor: "pointer", background: "transparent", padding: "8px 10px" }}>
                <StyledListItemText
                  className="line-height-menu"
                  primary={
                    <StyledTypography
                      id={`subcategoryTitle_${subCatSelected.id}_${childCatSelected.id}`}
                      onClick={() => navigateRoute(childCatSelected)}
                      variant={"headingFeatured"}
                      style={{ textDecoration: "underline", width: "100%" }}>
                      {childCatSelected.name.toUpperCase()}
                    </StyledTypography>
                  }
                />
              </StyledListItem>
              <Divider />
              {childCatSelected.children.map((childCat, index) => (
                <>
                  <StyledListItem
                    id={`subcategoryLink_${subCatSelected.id}_${childCatSelected.id}_${childCat.id}`}
                    isB2B={isB2B}
                    key={childCat.name}
                    button
                    onClick={() => navigateRoute(childCat)}>
                    <StyledListItemText
                      primary={<StyledTypography variant={"bodyBaseline"}>{childCat.name}</StyledTypography>}
                    />
                    <ArrowForwardIosIcon
                      style={{ fontSize: 15, position: "absolute", right: "5px", color: palette.primary.main }}
                    />
                  </StyledListItem>
                  <Divider />
                  {index === (childCatSelected.children.length - 1) ? (
                    <StyledListLink style={{ "margin-top": "10px" }}>
                      {!isB2B ? (
                        <>
                          <EspotHdm espotName={`${childCatSelected.identifier}_MenuCategory_Inferior_Section`} />
                        </>
                      ) : (
                        <>
                          <EspotHdm espotName="_ProMenuSubCategory_InferiorSection" />
                        </>
                      )}
                    </StyledListLink>
                  ) : ""}
                </>
              ))}
            </MenuChild>
          )}
        </StyledBoxCategories>
      )}
    </StyledBoxMenu>
  );
};

export default MenuDesktop;
