import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Axios, { Canceler } from "axios";

import CancelOutlinedIcon from "@material-ui/icons/CancelOutlined";
import { Divider } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";

import { StyledBox, StyledButton, StyledLink, StyledTypography, StyledGrid, StyledLinkTextSecondary } from "../../../hdm/elements";
import { palette } from "../../../hdm/themes";
import { ContainerStylediv, StyledSwipeableDrawersAgregarTarjeta } from "./container-checkout-profile";
import DatosFacturaForm from "./DatosFacturaForm";
import Direcciones from "./Direcciones";
import { PaymentMethodForm } from "./PaymentMethodForm";
import {
  ADDRESSFIELD3,
  ADDRESSLINE1,
  ADDRESSLINE2,
  ADDRESSLINE3,
  ADDRESSLINE3TXT,
  ADDRESS_BOOK,
  ADDRESS_LINE,
  EMPTY_STRING,
  FIELD1,
  FIELD3,
  FIRSTNAME,
  OTHER_COLONY,
} from "../../../constants/common";
import { PAYMENTMETHOD } from "../../../constants/routes";
import { useSite } from "../../../_foundation/hooks/useSite";
import styled from "styled-components";
import { AddressForm } from "../../widgets/address-form";
import { useDispatch, useSelector } from "react-redux";
import { onlineStoreRdc } from "../../../redux/selectors/hdmData";
import { addressDetailsSelector } from "../../../redux/selectors/account";
import addressUtil from "../../../utils/addressUtil";
import personContactService from "../../../_foundation/apis/transaction/personContact.service";
import getDisplayName from "react-display-name";
import { GET_ADDRESS_DETAIL_ACTION } from "../../../redux/actions/account";
import { HANDLE_SUCCESS_MESSAGE_ACTION } from "../../../redux/actions/success";
import { getExternalId } from "../../custom-components/conversion-api/pinterest/pinterest-conversion-utils";

const StyledAddressListTitle = styled((props: any) => <StyledGrid {...props} />)`
  ${({ theme }) => `
      color: ${theme.palette.gray["300"]};
      padding: ${theme.spacing(1)}px 10px;

      ${theme.breakpoints.down("md")} {
        padding-left: ${theme.spacing(2)}px !important;
        &.padding-top-1 {
          padding-top: ${theme.spacing(1)}px !important;
        }
      }
      
    `}
`;

interface Props {
  isOpen: boolean;
  misDirecciones: any;
  handleCloseAgregarTarjeta: () => void;
  handleSavedCard: () => void;
}
const AgregarNuevaTarjeta = (props: Props) => {
  const { t } = useTranslation();
  const { misDirecciones, isOpen, handleCloseAgregarTarjeta, handleSavedCard } = props;
  const filteredAddresses = misDirecciones.filter((address) => address.addressType !== "Billing");
  const [showDatosFacturacion, setShowDatosFacturacion] = useState(false);
  const [selectedAddress, setSelectedAddress] = useState<any>(null);
  const [datosFacturacion, setDatosFacturacion] = useState<any>(null);
  const [newDatosFacturacion, SetNewDatosFacturacion] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  const dispatch = useDispatch();
  const widgetName = getDisplayName(AgregarNuevaTarjeta);
  const storeConfData = useSelector(onlineStoreRdc);
  const addressDetails = useSelector(addressDetailsSelector);
  const CancelToken = Axios.CancelToken;
  const EDIT_SUCCESS_MSG = "success-message.EDIT_ADDRESS_SUCCESS";
  const cancels: Canceler[] = [];
  const payloadBase: any = {
    widget: widgetName,
    cancelToken: new CancelToken((c) => cancels.push(c)),
  };
  const { mySite } = useSite();

  const paymentMethodFormDataInit = {
    no_card: EMPTY_STRING,
    exp_date: EMPTY_STRING,
    cvv: EMPTY_STRING,
    name_card: EMPTY_STRING,
    lastname_card: EMPTY_STRING,
    microform: null,
    capture_context: EMPTY_STRING,
  };

  const addressFormDataInit = {
    firstName: EMPTY_STRING,
    lastName: EMPTY_STRING,
    addressLine1: EMPTY_STRING,
    addressLine2: EMPTY_STRING,
    addressLine3: "0",
    field1: EMPTY_STRING,
    field3: EMPTY_STRING,
    city: "0",
    country: "0",
    state: "0",
    zipCode: EMPTY_STRING,
    phone1: EMPTY_STRING,
    nickName: EMPTY_STRING,
    email1: EMPTY_STRING,
    addressType: "Billing",
  };

  const setAddressDataToForm = (formData) => {
    setDatosFacturacion({
      firstName: formData?.firstName ? formData?.firstName : addressDetails?.firstName,
      lastName: formData?.lastName ? formData?.lastName : addressDetails?.lastName,
      addressLine: formData?.addressLine,
      addressLine1: formData?.addressLine?.[0],
      addressLine2: formData?.addressLine?.[1],
      addressLine3: formData?.addressLine?.[2],
      field1: EMPTY_STRING,
      field3: formData?.field3,
      city: formData?.city,
      country: formData?.country ? formData?.country : "MX",
      state: formData?.state,
      zipCode: formData?.zipCode,
      nickName: formData?.nickName,
      addressType: isEdit ? "ShippingAndBilling" : "Billing",
    });
  };

  const [newPaymentMethodFormData, setNewPaymentMethodFormData] = useState<any>(paymentMethodFormDataInit);
  const [canSaveCard, setCanSaveCard] = useState(false);
  const [cardType, setCardType] = useState<any>("");
  const [isValidCC, setIsValidCC] = useState(false);

  const handleOpenDireccion = (option) => {
    setShowDatosFacturacion(!showDatosFacturacion);
    if (option === "editar") {
      setIsEdit(true);
      setAddressDataToForm(datosFacturacion);
    } else if (option === "nuevo") {
      setDatosFacturacion(addressFormDataInit);
      setIsEdit(false);
    } else {
      setIsEdit(false);
    }
  };

  const handleSelectedAddress = (direccion) => {
    setSelectedAddress(direccion);
    // setDatosFacturacion(direccion);
    setAddressDataToForm(direccion);
  };

  const handleCanSaveDireccion = (dataFactura) => {
    setDatosFacturacion(dataFactura);
  };

  let hasDirecciones = false;
  if (filteredAddresses.length !== 0) {
    hasDirecciones = true;
  }

  useEffect(() => {
    // console.log("canSaveCard", canSaveCard);

    if (datosFacturacion?.addressLine) {
      if (datosFacturacion?.addressLine[0] === "") {
        setCanSaveCard(false);
      } else if (datosFacturacion?.addressLine[1] === "") {
        setCanSaveCard(false);
      } else if (datosFacturacion?.addressLine[2] === "") {
        setCanSaveCard(false);
      } else if (datosFacturacion?.firstName === "") {
        setCanSaveCard(false);
      } else if (datosFacturacion?.lastName === "") {
        setCanSaveCard(false);
      }
    } else {
      if (datosFacturacion?.calle === "") {
        setCanSaveCard(false);
      } else if (datosFacturacion?.numeroExt === "") {
        setCanSaveCard(false);
      } else if (datosFacturacion?.colonia === "") {
        setCanSaveCard(false);
      } else if (datosFacturacion?.nombre === "") {
        setCanSaveCard(false);
      } else if (datosFacturacion?.apellidos === "") {
        setCanSaveCard(false);
      }
    }

    if (datosFacturacion?.zipCode === "") {
      setCanSaveCard(false);
    } else if (datosFacturacion?.state === "") {
      setCanSaveCard(false);
    } else if (datosFacturacion?.city === "") {
      setCanSaveCard(false);
    } else if (newPaymentMethodFormData?.capture_context === "") {
      setCanSaveCard(false);
    } else if (!isValidCC) {
      setCanSaveCard(false);
    } else if (cardType === "maestro" || cardType === "carnet" || cardType === "") {
      setCanSaveCard(false);
    } else if (newPaymentMethodFormData?.name_card === "") {
      setCanSaveCard(false);
    } else if (newPaymentMethodFormData?.lastname_card === "") {
      setCanSaveCard(false);
    } else if (datosFacturacion?.addressLine3 === "" || datosFacturacion?.addressLine3 === "0") {
      setCanSaveCard(false);
    } else if (datosFacturacion === null || newPaymentMethodFormData === null) {
      setCanSaveCard(false);
    } else {
      setCanSaveCard(true);
    }
  }, [datosFacturacion, newPaymentMethodFormData, cardType, setCardType, isValidCC]);

  const handleSaveTarjeta = async () => {
    let TotalSavedCardsInf = 0;
    const date = newPaymentMethodFormData?.exp_date.split("/");
    const externalId = getExternalId();

    await Axios.get(`/wcs/resources/store/${mySite?.storeID}/hdm/cybersource/retrieveSavedCardDetails`)
      .then((res) => {
        const savedCardsData: any = res?.data?.savedCardMap;
        TotalSavedCardsInf = Object.keys(savedCardsData).length;
        // setTotalSavedCardsState(Object.keys(savedCardsData).length);
      })
      .catch((error) => {
        console.log(error);
      });

    await newPaymentMethodFormData.microform.createToken(
      { expirationMonth: date[0], expirationYear: "20" + date[1] },
      async (error, transientToken) => {
        const payload = {
          transientToken: transientToken,
          captureContext: newPaymentMethodFormData.capture_context,
          cardHolderFirstName: newPaymentMethodFormData.name_card,
          cardHolderLastName: newPaymentMethodFormData.lastname_card,
          firstName: datosFacturacion?.firstName ? datosFacturacion?.firstName : addressDetails?.firstName,
          lastName: datosFacturacion?.lastName ? datosFacturacion?.lastName : addressDetails?.lastName,
          // address1: datosFacturacion.calle || datosFacturacion?.addressLine[0],
          // address2: datosFacturacion.numeroExt || datosFacturacion?.addressLine[2],
          // address3: datosFacturacion.colonia || datosFacturacion?.addressLine[3],
          address1: datosFacturacion?.addressLine?.[0] || datosFacturacion?.addressLine1,
          address2: datosFacturacion?.addressLine?.[1] || datosFacturacion?.addressLine2,
          address3: datosFacturacion?.addressLine?.[2] || datosFacturacion?.addressLine3,
          postalCode: datosFacturacion.zipCode,
          country: "MEXICO",
          state: datosFacturacion.state,
          city: datosFacturacion.city,
          counter: TotalSavedCardsInf,
          externalId: externalId,
        };

        await Axios.post(
          `/wcs/resources/store/${mySite?.storeID}/hdm/cybersource/accountCreatePaymentSubscription`,
          payload
        )
          .then(async (res) => {
            handleCloseAgregarTarjeta();
            handleSavedCard();
            // cancel();
          })
          .catch((error) => {
            console.log(error);
          });
      }
    );

    return;
  };

  const canContinueEditAdress = () => {
    if (
      datosFacturacion?.addressLine1 &&
      datosFacturacion?.addressLine1.length > 0 &&
      datosFacturacion?.addressLine2 &&
      datosFacturacion?.addressLine2.length > 0 &&
      datosFacturacion?.addressLine3 &&
      datosFacturacion?.addressLine3.length > 0 &&
      datosFacturacion?.city &&
      datosFacturacion?.city.length > 0 &&
      datosFacturacion?.state &&
      datosFacturacion?.state.length > 0 &&
      datosFacturacion?.zipCode &&
      datosFacturacion?.zipCode.length > 0
    ) {
      return true;
    } else {
      return false;
    }
  };

  /* Fuction to edit address */
  const validateGeolocationAddress = async (address: any) => {
    const apiKey = storeConfData?.googleAPIGeoLocateKey;
    const gpsAddress =
      address.state + "," + address.city + "," + address.addressLine3 + "," + address.field3 + "," + address.zipCode;

    const geocoder = new window["google"].maps.Geocoder();

    return await geocoder.geocode(
      {
        address: gpsAddress,
        componentRestrictions: {
          country: "MX",
        },
        language: "es",
        region: "MX"
      },
      function (results, status) {
        if (results && results.length > 0 && status === "OK") {
          const result = results[0];
          datosFacturacion[FIELD1] = result.geometry.location.lat() + "," + result.geometry.location.lng();
        }
      }
    );
  };

  const saveChanges = async () => {
    // remove leading and trailing white space from all form input fields.
    await validateGeolocationAddress(datosFacturacion);
    // const updatedAddressData = addressUtil.removeLeadingTrailingWhiteSpace(datosFacturacion);
    const updatedAddressData = datosFacturacion;
    const addressLine3 =
      updatedAddressData[ADDRESSLINE3] === OTHER_COLONY || updatedAddressData[ADDRESSLINE3] === "init"
        ? updatedAddressData[ADDRESSLINE3TXT]
        : updatedAddressData[ADDRESSLINE3];

    updatedAddressData[ADDRESS_LINE] = [
      updatedAddressData[ADDRESSLINE1],
      updatedAddressData[ADDRESSLINE2],
      addressLine3,
    ];
    if (updatedAddressData[FIELD3] && updatedAddressData[FIELD3].trim() !== EMPTY_STRING) {
      updatedAddressData[ADDRESSFIELD3] = updatedAddressData[FIELD3];
    }
    if (addressDetails?.firstName && addressDetails?.firstName !== EMPTY_STRING) {
      updatedAddressData[FIRSTNAME] = addressDetails.firstName;
    }

    if (addressDetails?.lastName && addressDetails?.lastName !== EMPTY_STRING) {
      updatedAddressData["lastName"] = addressDetails.lastName;
    }

    if (addressDetails?.email1 && addressDetails?.email1 !== EMPTY_STRING) {
      updatedAddressData["email1"] = addressDetails.email1;
    }

    if (addressDetails?.phone1 && addressDetails?.phone1 !== EMPTY_STRING) {
      updatedAddressData["phone1"] = addressDetails.phone1;
    }

    if (addressDetails?.addressType && addressDetails?.addressType !== EMPTY_STRING) {
      updatedAddressData["addressType"] = "ShippingAndBilling";
    }

    updatedAddressData[FIELD1] = datosFacturacion[FIELD1];

    if (updatedAddressData["nickName"] === EMPTY_STRING) {
      updatedAddressData["nickName"] = addressDetails.email1;
    }

    const requestParams = {
      nickName: updatedAddressData.nickName,
      body: updatedAddressData,
      ...payloadBase,
    };
    personContactService
      .updatePersonContact(requestParams)
      .then((res) => res.data)
      .then(async (addressData) => {
        if (addressData.addressId) {
          dispatch(GET_ADDRESS_DETAIL_ACTION({ ...payloadBase }));

          const successMessage = {
            key: EDIT_SUCCESS_MSG,
            messageParameters: {
              "0": updatedAddressData.nickName,
            },
          };
          dispatch(HANDLE_SUCCESS_MESSAGE_ACTION(successMessage));
          setIsEdit(false);
          setShowDatosFacturacion(false);
        }
      })
      .catch((e) => {
        console.log("Could not update the address", e);
      });
  };

  /* HOOKS  */
  useEffect(() => {
    setSelectedAddress(filteredAddresses[0]);
    // setDatosFacturacion(misDirecciones[0]);
    setAddressDataToForm(filteredAddresses[0]);
  }, []);

  useEffect(() => {
    setSelectedAddress((prevState) => (!showDatosFacturacion ? filteredAddresses[0] : prevState));
    setDatosFacturacion((prevState) => (!showDatosFacturacion ? filteredAddresses[0] : prevState));
    // setAddressDataToForm(datosFacturacion);
  }, [showDatosFacturacion]);

  useEffect(() => {
    if (!isOpen) {
      setShowDatosFacturacion(false);
      setNewPaymentMethodFormData(paymentMethodFormDataInit);
    } else if (isOpen && filteredAddresses?.length < 1) {
      setDatosFacturacion(addressFormDataInit);
      setShowDatosFacturacion(true);
    }
  }, [isOpen]);

  // useEffect(() => {
  //   if (isEdit) {
  //     setAddressDataToForm(datosFacturacion);
  //   }
  // }, [isEdit]);

  // const filteredAddresses = misDirecciones
  // console.log("methedit", isEdit )
  // console.log("meth", datosFacturacion )
  // console.log("meth2", misDirecciones.filter( address => address.addressType !== "Billing") )

  return (
    <>
      <StyledSwipeableDrawersAgregarTarjeta
        open={isOpen}
        onClose={handleCloseAgregarTarjeta}
        anchor="right"
        disableEnforceFocus>
        <ContainerStylediv className="full-height margin-0">
          <ContainerStylediv className="wrapper">
            <ContainerStylediv>
              <StyledBox p={2}>
                <StyledBox className="position-padding-10">
                  <CancelOutlinedIcon className="color-cursor" onClick={handleCloseAgregarTarjeta} />
                </StyledBox>
                <StyledTypography variant="textSegment" align="center">
                  {t("PaymentMethod.PaymentMethodMyCardsAddCardNew")}
                </StyledTypography>
              </StyledBox>
              <Divider className="divider-color" />
              <StyledBox className="left-padding">
                <StyledTypography variant="bodyBaseline" weight="semibold" component="span">
                  {t("PaymentMethod.PaymentMethodMyCardsAddCardDetails")}
                </StyledTypography>
              </StyledBox>
            </ContainerStylediv>
            <ContainerStylediv className="content">
              <StyledBox mb={2}>
                <PaymentMethodForm
                  cid={"newPaymentMethod"}
                  setPaymentMethodFormData={setNewPaymentMethodFormData}
                  paymentMethodFormData={newPaymentMethodFormData}
                  page={PAYMENTMETHOD}
                  fromCheckOutProfiles
                  setCardType={setCardType}
                  cardType={cardType}
                  setIsValidCC={setIsValidCC}
                  isValidCC={isValidCC}
                />
                <StyledGrid item xs={12}>
                  <StyledBox width="50%" display="inline-block" className="top-left-padding">
                    <StyledTypography variant="bodyBaseline" weight="semibold" component="span">
                      {t("PaymentMethod.PaymentMethodInvoiceAddress")}
                    </StyledTypography>
                  </StyledBox>

                  {filteredAddresses?.length > 0 ? (
                    <>
                      {/* Add new address button link */}
                      <StyledBox
                        width="50%"
                        display="inline-block"
                        className="right-align-text"
                        onClick={() => handleOpenDireccion("nuevo")}>
                        {!showDatosFacturacion ? (
                          <StyledBox className="styled-add-address">
                            <AddIcon
                              color="primary"
                              className="icon-plus"
                              // onClick={() => handleOpenDireccion("nuevo")}
                            />
                            <StyledLinkTextSecondary className="left-margin" color="secondary">{t("AddressBook.AddrMsg")}</StyledLinkTextSecondary>
                          </StyledBox>
                        ) : (
                          <StyledLinkTextSecondary className="left-margin" color="secondary">{t("AddressBook.Cancel")}</StyledLinkTextSecondary>
                        )}
                      </StyledBox>
                    </>
                  ) : null}
                </StyledGrid>

                {!showDatosFacturacion ? (
                  <>
                    <StyledAddressListTitle item xs={12} className="padding-top-1">
                      <StyledTypography variant="bodySubText">
                        {t("Payment.Labels.billingAddressList")}
                      </StyledTypography>
                    </StyledAddressListTitle>
                    <StyledBox>
                      <Direcciones
                        logonId={addressDetails.logonId}
                        misDirecciones={filteredAddresses}
                        selectedAddress={selectedAddress}
                        isEditable={true}
                        handleSelectedAddress={handleSelectedAddress}
                        handleOpenDireccion={handleOpenDireccion}
                      />
                    </StyledBox>
                  </>
                ) : null}

                {showDatosFacturacion && !isEdit ? (
                  <>
                    {/* <DatosFacturaForm
                      handleCanSaveDireccion={handleCanSaveDireccion}
                      isOpen={isOpen}
                      selectedAddress={selectedAddress}
                      isEdit={isEdit}
                    /> */}
                    {/* New address form */}
                    {/* <StyledBox 
                      // px={1}
                    > */}
                    <AddressForm
                      cid="newAddress"
                      // setAddressFormData={setAddressDataToForm}
                      setAddressFormData={setDatosFacturacion}
                      addressFormData={datosFacturacion}
                      edit={isEdit}
                      // userLoggedIn={userLoggedIn}
                      usableAddresses={filteredAddresses}
                      fromMyAccount
                      fromCheckOutProfiles
                      // page={page}
                    />
                    {/* </StyledBox> */}
                  </>
                ) : showDatosFacturacion && isEdit ? (
                  <>
                    {/* Edit address form */}
                    {/* <DatosFacturaForm
                        handleCanSaveDireccion={handleCanSaveDireccion}
                        isOpen={isOpen}
                        selectedAddress={selectedAddress}
                        isEdit={isEdit}
                      /> */}
                    <AddressForm
                      cid="editAddress"
                      // setAddressFormData={setAddressDataToForm}
                      setAddressFormData={setDatosFacturacion}
                      addressFormData={datosFacturacion}
                      edit={isEdit}
                      // userLoggedIn={userLoggedIn}
                      usableAddresses={filteredAddresses}
                      fromCheckOutProfiles
                      // page={page}
                    />
                  </>
                ) : null}
              </StyledBox>
            </ContainerStylediv>
            <ContainerStylediv>
              {!isEdit ? (
                <>
                  {/* SAVE CARD BUTTONS */}
                  <StyledBox className="container-fixdown-drawer">
                    <StyledBox mb={2} px={2} className="container-fixdown">
                      <StyledButton
                        className="save-card-button"
                        variant="contained"
                        disabled={!canSaveCard}
                        onClick={() => handleSaveTarjeta()}>
                        {t("PaymentMethod.PaymentMethodSaveCard")}
                      </StyledButton>
                      <StyledBox align="center">
                        {/* <StyledLink onClick={handleCloseAgregarTarjeta}>
                      {t("PaymentMethod.PaymentMethodSaveCardCancel")}
                    </StyledLink> */}
                        <StyledButton
                          className="save-card-button"
                          variant="textSecondary"
                          onClick={handleCloseAgregarTarjeta}>
                          {t("PaymentMethod.PaymentMethodSaveCardCancel")}
                        </StyledButton>
                      </StyledBox>
                    </StyledBox>
                  </StyledBox>
                </>
              ) : (
                <>
                  {/* EDIT ADDRESS BUTTONS */}
                  <StyledGrid
                    container
                    justifyContent="flex-end"
                    spacing={1}
                    className="checkoutprofile-edit-buttons-container">
                    <StyledGrid item xs={12} className="checkoutprofile-confirm-edit">
                      <StyledButton
                        variant="outlined"
                        testId="checkout-address-submit"
                        color="primary"
                        disabled={!canContinueEditAdress()}
                        // onClick={() => submit(isFirstAddress ? isFirstAddress : createNew, editAddress)}>
                        onClick={() => saveChanges()}>
                        {t("CheckoutProfile.saveAddress")}
                      </StyledButton>
                    </StyledGrid>
                    <StyledGrid item xs={12} className="checkoutprofile-cancel-edit">
                      <StyledButton
                        testId="paymentmethod-book-cancel"
                        size="small"
                        variant="textSecondary"
                        onClick={() => {
                          setIsEdit(false);
                          setShowDatosFacturacion(false);
                        }}>
                        {t("AddressBook.Cancel")}
                      </StyledButton>
                    </StyledGrid>
                  </StyledGrid>
                </>
              )}
            </ContainerStylediv>
          </ContainerStylediv>
        </ContainerStylediv>
        {/* </ContainerCheckoutProfile> */}
      </StyledSwipeableDrawersAgregarTarjeta>
    </>
  );
};

export default AgregarNuevaTarjeta;
