import React, { ChangeEvent, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

//UI
import {
  StyledButton,
  StyledPaper,
  StyledContainer,
  StyledBreadcrumbs,
  StyledLink,
  StyledTypography,
  StyledGrid,
  StyledBox,
  StyledTextField,
  StyledTooltip,
  StyledIconButton,
} from "../../../hdm/elements";
import { StyledFormHelperText } from "../../../hdm";
import { palette } from "../../../hdm/themes";
import { withStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core/styles";
import { ClickAwayListener, Divider } from "@material-ui/core";
import { InputAdornment } from "@material-ui/core";
import { CreditCardSharp } from "@material-ui/icons";
import { useSite } from "../../../_foundation/hooks/useSite";
import Axios, { Canceler } from "axios";
import { CHECKOUT, EMPTY_STRING } from "../../../constants/common";
import { ContainerPaymentMethod } from "./container-payment-method";

import HelpIcon from "../../../assets/homedepot/images/HelpIcon";
import InputMask from "react-input-mask";
import { StyledTooltipHdm } from "../../custom-components/styled-tooltip-hdm";
import EspotHdm from "../../custom-components/shared/EspotHdm";

import visa from "../../../assets/homedepot/images/visa-color.png";
import mc from "../../../assets/homedepot/images/mastercard-color.png";
import amex from "../../../assets/homedepot/images/amex-color.png";
import carnet from "../../../assets/homedepot/images/Carnet-01.png";
import { useSelector } from "react-redux";
import { onlineStoreRdc } from "../../../redux/selectors/hdmData";

interface PaymentMethodFormProps {
  cid: string;
  setPaymentMethodFormData: any; //payment method form data setter fn
  paymentMethodFormData: any; //payment method form data
  page?: string; // page name
  edit?: boolean;
  fromCheckOutProfiles?: boolean;
  cardType: any, 
  setCardType: any,
  setIsValidCC: (boolean) => void;
  isValidCC: boolean;
}

const PaymentMethodForm: React.FC<PaymentMethodFormProps> = (props: any) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const cid = props.cid;
  const setPaymentMethodFormData = props.setPaymentMethodFormData;
  const paymentMethodFormData = props.paymentMethodFormData;
  const fromCheckOutProfiles = props.fromCheckOutProfiles;
  const page = props.page ? props.page : CHECKOUT; // Default page is checkout
  const edit = props.edit ? props.edit : false;
  const storeConfData = useSelector(onlineStoreRdc);
  const cardType = props.cardType;
  const setCardType = props.setCardType;

  const isMobile = false; //useMediaQuery(theme.breakpoints.down("sm"));
  const [open, setOpen] = React.useState(false);

  const [expDate, setExpDate] = useState(false);
  const [cardNum, setCardNum] = useState(false);
  const [cvv, setCvv] = useState(false);
  const [firstName, setFirstName] = useState(false);
  const [lastName, setLastName] = useState(false);
  const { mySite } = useSite();
  const CancelToken = Axios.CancelToken;
  const cancels: Canceler[] = [];
  const isValidCC = props.isValidCC;
  const setIsValidCC = props.setIsValidCC;
  // const [cardType, setCardType] = useState("");
  const [isValidCVV, setIsValidCVV] = useState(false);

  const handleBlur = (input: string) => {
    switch (input) {
      case "cardNum":
        setCardNum(true);
        break;
      case "expDate":
        setExpDate(true);
        break;
      case "cvv":
        setCvv(true);
        break;
      case "firstName":
        setFirstName(true);
        break;
      case "lastName":
        setLastName(true);
        break;
    }
  };

  const validateExpDate = (expiryDate = "") => {
    let dateData = expiryDate;
    if (dateData === "") {
      dateData = paymentMethodFormData.exp_date;
    }

    if (dateData.indexOf("_") === -1) {
      const date = dateData.split("/");
      const today = new Date();
      const mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
      const yy = today
        .getFullYear()
        .toString()
        .substr(today.getFullYear().toString().length - 2);
      if (parseInt(date[0]) > 12 || date[0] === "00") {
        return false;
      }
      if (parseInt(date[1]) > parseInt(yy)) {
        setExpDate(false);
        return true;
      }
      if (parseInt(date[1]) === parseInt(yy)) {
        if (parseInt(date[0]) >= parseInt(mm)) {
          setExpDate(false);
          return true;
        }
      }
    }
    return false;
  };

  function handleChange(event: ChangeEvent<HTMLInputElement>) {
    const newPaymentMethodFormData = { ...paymentMethodFormData };
    const regexLetras = /[A-ZÑa-zñ\s]/;
    const regexNumeros = new RegExp("^[0-9]*$");
    const patt = new RegExp(/^[-_ 0-9\s]+$/g);

    if (event.target.name == "no_card" || event.target.name == "cvv") {
      const isValid = regexNumeros.test(event.target.value);
      if (!isValid) {
        return;
      }
    }

    if (event.target.name && event.target.name.trim() !== EMPTY_STRING) {
      newPaymentMethodFormData[event.target.name] = event.target.value;
      setPaymentMethodFormData(newPaymentMethodFormData);
    }

    switch (event.target.name) {
      case "no_card":
        setCardNum(false);
        break;
      case "exp_date":
        setExpDate(false);
        break;
      case "cvv":
        setCvv(false);
        break;
      case "name_card":
        setFirstName(false);
        break;
      case "lastname_card":
        setLastName(false);
        break;
      default:
        break;
    }
  }

  const generateMicroForm = async (idCard = "", onlyCvv = false) => {
    const payload = {
      storeId: mySite?.storeID,
      orderId: "100",
      flowType: "fromMyAccount",
      cancelToken: new CancelToken(function executor(c) {
        cancels.push(c);
      }),
    };

    await Axios.post(`/wcs/resources/store/${mySite?.storeID}/hdm/cybersource/generateFlexPublicKey`, payload)
      .then((res) => {
        window.instanceFlex(res.data.captureContext);
        const captureContext = res.data.captureContext;
        const flex = window.flex;
        const newData = { ...paymentMethodFormData };
        const customStyles = {
          "@font-face": {
            "font-family": "Open Sans",
            "font-style": "normal",
            "font-weight": 400,
            src:
              'url("' +
              storeConfData?.IMAGE_SERVER_HOSTNAME +
              'assets/fonts/Open_Sans/OpenSans-Regular.woff2") format("woff2")',
          },
          input: {
            "font-size": "12px",
            "font-family": '"Open Sans","Helvetica Neue",Arial,sans-serif,"Open Sans Light"',
            color: "#333",
            "font-weight": 400,
          },
          "::placeholder": {
            "font-size": "15px",
            "font-family": '"Open Sans","Helvetica Neue",Arial,sans-serif,"Open Sans Light"',
            color: "#999",
            "font-weight": 400,
          },
          invalid: { color: "red" },
          focus: { color: "green" },
        };
        const microform = flex.microform({ styles: customStyles });
        if (!onlyCvv) {
          const number = microform.createField("number", { placeholder: t("CheckoutProfile.cardDetails.cardNumber") });
          number.on("change", function (data) {    
            if (data?.card && data?.card.length > 0) {
              setCardType(data.card[0].name);
              setIsValidCC(data.valid);
              setCardNum(false);
            } else{
              setCardType("");
            }
          });
          number.on("blur", function () {
            handleBlur("cardNum");
          });
          number.load("#number-container");
        }

        const securityCode = microform.createField("securityCode", {
          placeholder: t("CheckoutProfile.cardDetails.cvv").toUpperCase(),
        });

        securityCode.load("#securityCode-container" + idCard);

        securityCode.on("change", function (data) {
          setIsValidCVV(data.valid);
          setCvv(false);
        });
        securityCode.on("blur", function (data) {
          handleBlur("cvv");
        });

        newData["microform"] = microform;
        newData["capture_context"] = captureContext;
        setPaymentMethodFormData(newData);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    setCardType("");
    generateMicroForm();
  }, []);

  return (
    <ContainerPaymentMethod fromCheckOutProfiles={fromCheckOutProfiles}>
      <form id="flexForm">
        <StyledGrid container spacing={12}>
          <StyledGrid item xs={12}>
            <StyledBox
              className={
                "cyberSource--flex-container" + (cardNum && !isValidCC ? " cyberSource--flex-containerError" : "")
              }>
              <img
                src={visa}
                className={
                  cardType.toLocaleLowerCase() === "visa"
                    ? "cyberSource--img-cardLogo lazyload"
                    : "cyberSource--img-hideLogo lazyload"
                }
              />
              <img
                src={mc}
                className={
                  cardType.toLocaleLowerCase() === "mastercard"
                    ? "cyberSource--img-cardLogo lazyload"
                    : "cyberSource--img-hideLogo lazyload"
                }
              />
              <img
                src={amex}
                className={
                  cardType.toLocaleLowerCase() === "amex"
                    ? "cyberSource--img-cardLogo lazyload"
                    : "cyberSource--img-hideLogo lazyload"
                }
              />
              <img
                src={carnet}
                className={
                  cardType.toLocaleLowerCase() === "maestro"
                    || cardType.toLocaleLowerCase() === "carnet"
                    ? "cyberSource--img-cardLogo lazyload"
                    : "cyberSource--img-hideLogo lazyload"
                }
              />
              <div id="number-container" className="cyberSource--flex-iFrame"></div>
            </StyledBox>
            {cardType === "maestro" || cardType === "carnet" ? (
              <StyledFormHelperText>{t("PaymentMethod.CarnetCardNotAllowed")}</StyledFormHelperText>
            ) : 
            (!isValidCC && cardNum && cardType === "") ? (
              <StyledFormHelperText>{t("AddressForm.Msgs.Required")}</StyledFormHelperText>
            ) :
            (!isValidCC && cardNum && cardType !== "none") || (isValidCC && cardNum && cardType === "none") ? (
              <StyledFormHelperText>{t("PaymentMethod.labelCardNumber")}</StyledFormHelperText>
            ) : (
              !isValidCC &&
              cardType !== "" &&
              cardType !== "visa" &&
              cardType !== "mastercard" &&
              cardType !== "amex" && <StyledFormHelperText>{t("PaymentMethod.labelCardNumber")}</StyledFormHelperText>
            )}
            {/* {cardNum && !isValidCC && <StyledFormHelperText>{t("AddressForm.Msgs.Required")}</StyledFormHelperText>}
            {!cardNum &&
              !isValidCC &&
              cardType !== "" &&
              cardType !== "visa" &&
              cardType !== "mastercard" &&
              cardType !== "amex" && <StyledFormHelperText>{t("PaymentMethod.labelCardNumber")}</StyledFormHelperText>} */}
          </StyledGrid>
          <StyledGrid item xs={12}>
            <InputMask
              mask="99/99"
              value={paymentMethodFormData.exp_date === "" ? null : paymentMethodFormData.exp_date}
              id={`exp_date`}
              name="exp_date"
              label={t("CheckoutProfile.cardDetails.dateExpSD")}
              onChange={handleChange}
              onBlur={(e) => {
                handleBlur("expDate");
              }}
              error={(expDate && paymentMethodFormData.exp_date === EMPTY_STRING) || (expDate && !validateExpDate())}
              helperText={
                expDate && paymentMethodFormData.exp_date === EMPTY_STRING
                  ? t("AddressForm.Msgs.Required")
                  : expDate && !validateExpDate()
                  ? t("OrderConfirmation.PaymentMethod.Cybersource.invalidDate")
                  : EMPTY_STRING
              }
              className="width-display right-margin">
              {(input) => <StyledTextField {...input} />}
            </InputMask>
            <StyledBox className="width-display">
              <StyledBox
                className={
                  "cyberSource--flex-container" + (cvv && !isValidCVV ? " cyberSource--flex-containerError" : "")
                }
                justifyContent={"space-between"}>
                <div id="securityCode-container" className="cyberSource--flex-iFrame"></div>
                <StyledTooltipHdm content={<EspotHdm espotName={mySite.isB2B ? "PRO_MyAccount_Cybersource-CVV-Form-Tooltip" : "MyAccount_Cybersource-CVV-Form-Tooltip"} />} />
              </StyledBox>
              {cvv && !isValidCVV && <StyledFormHelperText>{t("AddressForm.Msgs.Required")}</StyledFormHelperText>}
            </StyledBox>
          </StyledGrid>
          <StyledGrid item xs={12}>
            <StyledTextField
              id={"name_card"}
              name="name_card"
              label={"Nombre"}
              maxLength={40}
              onChange={handleChange}
              value={paymentMethodFormData.name_card}
              fullWidth
              required
              onInput={(e) => {
                e.target.value = e.target.value.slice(0, 40);
              }}
              onBlur={(e) => {
                handleBlur("firstName");
              }}
              helperText={
                firstName && paymentMethodFormData.name_card === EMPTY_STRING
                  ? t("AddressForm.Msgs.Required")
                  : EMPTY_STRING
              }
            />
          </StyledGrid>
          <StyledGrid item xs={12}>
            <StyledTextField
              id={"lastname_card"}
              name="lastname_card"
              label={"Apellidos"}
              onChange={handleChange}
              value={paymentMethodFormData.lastname_card}
              fullWidth
              required
              onInput={(e) => {
                e.target.value = e.target.value.slice(0, 40);
              }}
              onBlur={(e) => {
                handleBlur("lastName");
              }}
              helperText={
                lastName && paymentMethodFormData.lastname_card === EMPTY_STRING
                  ? t("AddressForm.Msgs.Required")
                  : EMPTY_STRING
              }
            />
          </StyledGrid>
        </StyledGrid>
      </form>
    </ContainerPaymentMethod>
  );
};

export { PaymentMethodForm };
