/*
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */
//Standard libraries
import React, { ChangeEvent, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Axios from "axios";
import { useTheme } from "@material-ui/core/styles";
//Custom libraries
import addressUtil from "../../../utils/addressUtil";
import { onlineStoreRdc } from "../../../redux/selectors/hdmData";

//Foundation libraries
import { useSite } from "../../../_foundation/hooks/useSite";

import { EMPTY_STRING, OTHER_COLONY } from "../../../constants/common";

//UI
import { StyledGrid, StyledTextField, StyledMenuItem, StyledTypography } from "../../../hdm";
import { StyledSelectHdm } from "../../custom-components/styled-select";
import EspotHdm from "../../custom-components/shared/EspotHdm";
import { useSelector } from "react-redux";
import { StyledTooltipHdm } from "../../custom-components/styled-tooltip-hdm";
import StatesCitiesList from "../../custom-components/styled-store-selector/json-stores-info/FormStatesCitiesList.json";
import { guestStatusSelector, loginStatusSelector } from "../../../redux/selectors/user";
import AddressContext from "../../../hdm/pages/checkout/address/AddressContext";
import { AddressList } from "../../../hdm/widgets/address-list";
import { organizationDetailsSelector } from "../../../redux/selectors/organization";
import { shipInfosSelector } from "../../../redux/selectors/order";
import { guestUserDataSelector } from "../../../redux/selectors/hdmData";
import { addressDetailsSelector } from "../../../redux/selectors/account";

interface AddressFormProps {
  cid: string;
  setAddressFormData: any; //address form data setter fn
  addressFormData: any; //address form data
  page?: string; // page name
  edit?: boolean;
  userLoggedIn?: boolean;
  usableAddresses?: any;
  createAddress?: any;
  fromMyAccount?: boolean;
  showUsableAddresses?: boolean;
  selectedAddressId?: any;
  setSelectedAddressId?: any;
  toggleEditAddress?: any;
  editAddress?: boolean;
  fromCheckOutProfiles?: boolean;
  continueCreatingNewCard?: boolean;
  setcontinueCreatingNewCard?: any;
  handleChangeAllReadyEdited?: () => void;
  canContinueNewCard?: () => void;
  fromCheckoutStep3SaveNewAddress?: boolean;
  fromCheckout?: boolean;
  isFormValid?: object;
  setIsFormValid?: (any) => void;
}

export enum CheckoutPageType {
  SHIPPING = "shipping",
  PAYMENT = "payment",
}

/**
 * Address Form component
 * to be resued to display form inputs on a shipping or billing address form
 * @param props
 */
const AddressForm: React.FC<AddressFormProps> = ({ fromCheckoutStep3SaveNewAddress = false, ...props }) => {
  const guestUserData = useSelector(guestUserDataSelector);
  const { t } = useTranslation();
  const theme = useTheme();
  const cid = props.cid;
  const fromMyAccount = props.fromMyAccount;
  const fromCheckOutProfiles = props.fromCheckOutProfiles;
  const fromCheckout = props.fromCheckout;
  const setAddressFormData = props.setAddressFormData;
  const isFormValid = props.isFormValid;
  const setIsFormValid = props.setIsFormValid;
  const addressFormData = props.addressFormData;
  const isGuest = useSelector(guestStatusSelector);
  const usableAddresses = props.usableAddresses;
  const editAddress = props.editAddress;
  const continueCreatingNewCard = props?.continueCreatingNewCard;
  const setcontinueCreatingNewCard = props?.setcontinueCreatingNewCard;
  const toggleEditAddress = props.toggleEditAddress;
  // const userEmail = props.userEmail;
  const page = props.page ? props.page : CheckoutPageType.SHIPPING; // Default page is checkout
  const defaultCountry = "México";
  const defaultCountryId = "MX";
  const defaultLanguage = "es";
  const defaultRegion = "MX";
  const GOOGLE_GEOCODER_SUCCESS_STATUS = "OK";
  const MEXICO_CITY = "Ciudad de Mexico";
  const countryArray = [
    { name: t("AddressForm.Labels.SelectCountry"), id: "0" },
    { name: "México", id: "MX" },
    { name: "Estados Unidos", id: "US" },
  ];
  let isProLeadPage = false;
  if (props?.page && props?.page === "proLead") {
    isProLeadPage = true;
  }
  const edit = props.edit ? props.edit : false;
  const userLoggedIn = props.userLoggedIn;
  const showUsableAddresses = fromMyAccount ? props?.showUsableAddresses : true;
  const INIT = "0";
  const canContinueNewCard = props?.canContinueNewCard;
  const addressDetails = useSelector(addressDetailsSelector);
  const isMainAddress = addressFormData?.nickName === addressDetails?.logonId;

  const [showInputStateAndCity, setShowInputStateAndCity]: any = useState(false);
  const [showInputCity, setShowInputCity]: any = useState(false);
  const [colonies, setColonies]: any = useState([INIT, OTHER_COLONY]);
  const [cities, setCities]: any = useState([INIT]);
  const [states, setStates]: any = useState([INIT]);
  const [errorFirstName, setErrorFirstName] = useState(false);
  const [errorLastName, setErrorLastName] = useState(false);
  const [errorZip, setErrorZip] = useState(false);
  const [errorAlias, setErrorAlias] = useState(false);
  const [errorStreet, setErrorStreet] = useState(false);
  const [errorExtNum, setErrorExtNum] = useState(false);
  const [errorZipCode, setErrorZipCode] = useState(false);
  const [errorColony, setErrorColony] = useState(false);
  const [errorColonyTxt, setErrorColonyTxt] = useState(false);
  const [errorCity, setErrorCity] = useState(false);
  const [errorState, setErrorState] = useState(false);
  const [nickNameNotAvailable, setNickNameNotAvailable] = useState(false);
  let isFirstAddress =
    usableAddresses?.length < 1
      ? true
      : (addressDetails?.contactList || addressDetails?.addressLine) === undefined
      ? true
      : false;
  const [cityDisabled, setCityDisabled] = useState(true);
  const [colonyDisabled, setColonyDisabled] = useState(true);
  const [stateDisabled, setStateDisabled] = useState(true);
  const [countryDisabled, setCountryDisable] = useState(false);
  const [googleOff, setGoogleOff] = useState(false);
  const [customTablesFlag, setCustomTablesFlag] = useState(false);
  const [otherCountry, setOtherCountry] = useState(false);
  const { mySite } = useSite();

  if (isProLeadPage) {
    isFirstAddress = true;
  }

  /*======== MY ACCOUNT DATA ========*/
  const orgAddressDetails = useSelector(organizationDetailsSelector);
  const usableShipInfos: any = useSelector(shipInfosSelector);
  const selectedAddressId = props.selectedAddressId ? props.selectedAddressId : "";
  const setSelectedAddressId = props.setSelectedAddressId ? props.setSelectedAddressId : null;

  // const addressReduxData = useSelector<any>(state => state?.user?.details?.addressLine);
  const loginStatusRedux = useSelector(loginStatusSelector);
  const storeConfData = useSelector(onlineStoreRdc);
  const [googleApiGeoKey, setGoogleApiGeoKey] = useState(null);
  // const isFirstAddressInMyAccountPage = !addressReduxData &&  page === "address-book" ? true : false;

  function handleChange(event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
    const newAdressFormData = { ...addressFormData };
    const regexLetras = /[A-ZÑa-zñ\s]/;
    const regexNumeros = new RegExp("^[0-9]*$");
    const patt = new RegExp(/^[-_ 0-9\s]+$/g);
    if (props?.handleChangeAllReadyEdited) {
      props?.handleChangeAllReadyEdited();
    }

    if (event.target.name == "addressLine2" || event.target.name == "zipCode") {
      const isValid = regexNumeros.test(event.target.value);
      if (!isValid) {
        return;
      }
    }

    if (event.target.name === "addressLine1" || event.target.name === "field3") {
      const patt = new RegExp(/^[A-Za-zñÑáéíóúÝÉÝÓÚ0-9´\s-]+$/g);
      const isValid = patt.test(event.target.value);
      if (!isValid && event.target.value.trim() !== "") {
        return;
      }
    }

    if (event.target.name === "firstName" || event.target.name === "lastName") {
      const patt = new RegExp(/^[A-Za-zñÑáéíóúÝÉÝÓÚ\s]+$/g);
      const isValid = patt.test(event.target.value);
      if (!isValid && event.target.value.trim() !== EMPTY_STRING) {
        return;
      }
    }

    if (event.target.name.trim() === "zipCode") {
      if (event.target.value.length < 5) {
        setGoogleOff(false)
        setShowInputStateAndCity(false);
        setShowInputCity(false)
        setCityDisabled(true)
        setColonyDisabled(true);
        setColonies([INIT, OTHER_COLONY]);
        setCities([INIT]);
        setStates([INIT]);
        setErrorColony(false);
        setErrorColonyTxt(false);
        setErrorCity(false);
        setCountryDisable(false);
      }
      newAdressFormData["addressLine3"] = INIT;
      newAdressFormData["city"] = INIT;
      newAdressFormData["state"] = INIT;
      newAdressFormData["addressLine3Txt"] = "";
    }

    if (event.target.name === "state" || event.target.name === "city") {
      newAdressFormData[event.target.name] = event.target.value;
      setAddressFormData(newAdressFormData);
      return;
    }

    if (event.target.name === "stateInput") {
      newAdressFormData["state"] = event.target.value;
      setAddressFormData(newAdressFormData);
      return;
    }

    if (event.target.name === "cityInput") {
      newAdressFormData["city"] = event.target.value;
      setAddressFormData(newAdressFormData);
      return;
    }

    if (event.target.name === "nickName") {
      if (
        (usableAddresses &&
          usableAddresses?.find(
            (address) => address.nickName.trim().toLowerCase() === event.target.value.trim().toLowerCase()
          )) ||
        (addressDetails &&
          addressDetails.contactList?.find(
            (address) => address.nickName.trim().toLowerCase() === event.target.value.trim().toLowerCase()
          ))
      ) {
        setNickNameNotAvailable(true);
      } else {
        setNickNameNotAvailable(false);
      }
    }

    // if (event.target.name === "addressLine3Txt") {
    //   newAdressFormData["addressLine3"] = event.target.value;
    //   setAddressFormData(newAdressFormData);
    //   return;
    // }

    if (event.target.name === "addressLine3") {
      if (event.target.value.trim() !== OTHER_COLONY) {
        newAdressFormData["addressLine3Txt"] = "";
        setErrorColonyTxt(false);
      }
    }

    if (event.target.name.trim() === "country") {
      setColonies([INIT, OTHER_COLONY]);
      setCities([INIT]);
      setStates([INIT]);
      setShowInputStateAndCity(false);
      setShowInputCity(false)
      newAdressFormData["addressLine3"] = INIT;
      setColonyDisabled(true);
      newAdressFormData["city"] = INIT;
      newAdressFormData["state"] = INIT;
      // setCityDisabled(true)
      setErrorColony(false);
      setErrorColonyTxt(false);
      setErrorState(false);
      setErrorCity(false);
    }

    if (event.target.name && event.target.name.trim() !== EMPTY_STRING) {
      if (event.target.name === "addressLine1" || event.target.name === "field3") {
        event.target.value = event.target.value.replace(/ {2,}/g, " ");
      }
      newAdressFormData[event.target.name] = event.target.value;
      setAddressFormData(newAdressFormData);
    }
  }

  // const handleResetInput = () => {

  // }

  useEffect(() => {

    if (addressFormData?.zipCode?.length === 5 && googleApiGeoKey) {
      if (page === "proLead") {
        findLocationByZip(addressFormData.zipCode);
      } else {
        handleZipCodeChange(addressFormData.zipCode);
      }
    } 

    if (errorZip === true || errorZipCode === true) {
      setErrorZip(false);
      setErrorZipCode(false);
      setIsFormValid && setIsFormValid({ ...isFormValid, isZipCodeValid: true})
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addressFormData?.zipCode, addressFormData?.country, googleApiGeoKey]);

  useEffect(() => {

    if(showUsableAddresses && addressFormData?.zipCode?.length !== 5 && (page === CheckoutPageType.PAYMENT || fromMyAccount)){
      setAddressFormData({
        ...addressFormData,
        country: "0"
      }
      );
      setCountryDisable(false)
    }

  }, [showUsableAddresses])

  useEffect(() => {
    if (edit) {
      if (addressFormData.zipCode) {
        debouncedSearchZipRest(addressFormData.zipCode, addressFormData, errorZipCode);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (storeConfData?.googleAPIGeoLocateKey) {
      setGoogleApiGeoKey(storeConfData?.googleAPIGeoLocateKey);
    }
  }, [storeConfData]);

  useEffect(() => {
    return;
    const newAdressFormData = { ...addressFormData };
    if (page === CheckoutPageType.PAYMENT && addressFormData.country === "US") {
      // newAdressFormData["addressLine3"] = OTHER_COLONY;
    } else if (fromMyAccount && addressFormData?.country === "") {
      setShowInputStateAndCity(false);
    } else if (fromMyAccount && addressFormData?.country === "0") {
      newAdressFormData["addressLine3"] = INIT;
      setShowInputStateAndCity(false);
    } else if (fromMyAccount && addressFormData?.country !== "MX" && addressFormData.country !== "") {
      newAdressFormData["addressLine3"] = OTHER_COLONY;
      newAdressFormData["city"] = "";
      newAdressFormData["state"] = "";
      setShowInputStateAndCity(true);
    } else if (fromMyAccount && addressFormData?.country === "MX") {
      newAdressFormData["addressLine3"] = INIT;
      setShowInputStateAndCity(false);
    }
    // else {
    //   newAdressFormData["addressLine3"] = INIT;
    // }

    setAddressFormData(newAdressFormData);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addressFormData?.country]);

  useEffect(() => {
    if (edit && googleApiGeoKey) {
      handleZipCodeChange(addressFormData["zipCode"]);
    }
  }, [googleApiGeoKey]);

  const handleZipCodeChange = (value: string) => {
    debouncedSearchZipRest(value, addressFormData, true);
  };

  const debouncedSearchZipRest = (value, addressFormDataDebounce, errorZipCode) => {
    setCustomTablesFlag(false);
    const apiKey = storeConfData?.googleAPIGeoLocateKey;
    const GoogleGeocoderStatus = storeConfData?.GOOGLE_GEOCODER_STATUS;
    if (value?.length < 5 || apiKey === "") {
      return false;
    }
    let country = defaultCountry;
    let countryId = defaultCountryId;
    const language = defaultLanguage;
    if (page === CheckoutPageType.PAYMENT || fromMyAccount) {
      const filtercountry = countryArray.filter((e) => e.id === addressFormData.country);
      if (filtercountry && filtercountry.length > 0) {
        country = filtercountry[0].name;
        countryId = filtercountry[0].id;
      }
    }
    const geocoder = new window["google"].maps.Geocoder();
    
    if(!geocoder?.geocode?.name || geocoder?.geocode?.name !== "geocode"){
      if(addressFormData.country !== "US"){
        findLocationByZip(addressFormData.zipCode);
      }
      if(addressFormData.country === "US"){
        noGoogleMode()
      }
      return
    }

    let googleApiParams
    if((page === CheckoutPageType.PAYMENT || fromMyAccount)){
      googleApiParams = {
        componentRestrictions: {
          postalCode: value,
        },
        language: defaultLanguage,
      }
    }else {
      googleApiParams = {
        componentRestrictions: {
          country: countryId,
          postalCode: value,
        },
        language: defaultLanguage,
        region: "MX"
      }
    }

    geocoder.geocode(googleApiParams,
    function (resultsApi, status) {
        if (GoogleGeocoderStatus !== GOOGLE_GEOCODER_SUCCESS_STATUS) {
          setGoogleOff(true);
          status = GoogleGeocoderStatus;
        } else {
          setGoogleOff(false);
        }

        let resultFounded = false
        const results: any = []
        const allResults: any = []
        resultsApi && resultsApi.map(result => {
          resultFounded = false
          if(result.address_components){
            result.address_components.map(address_component => {
              if(address_component.types.includes("country") && (address_component.short_name === "MX" || address_component.short_name === "US")  ){
                resultFounded = true
                result.country = address_component.short_name
              }
            })
          }
          if(resultFounded){
              allResults.push(result)
          }
        })

       
        if(allResults && allResults?.length === 1){
          if(allResults[0].country === "US" && (page === CheckoutPageType.PAYMENT || fromMyAccount) && addressFormData?.country !== "MX"){
            results.push(allResults[0])
          }else if (allResults[0].country === "MX" && addressFormData?.country !== "US"){
              results.push(allResults[0])
          }

        }else if(allResults && allResults?.length > 1){
          const dataSelected = allResults.find(result => result.country === countryId)
          if(dataSelected){
            results.push(dataSelected)
          }else {
            results.push(allResults[0])
          }
        }

        if (results && results?.length > 0 && status?.trim() === GOOGLE_GEOCODER_SUCCESS_STATUS && !googleOff) {
          setShowInputStateAndCity(false);

          const stateCity = results[0].address_components;
          const newAdressFormData = { ...addressFormDataDebounce };
          const postcode_localities = results[0].postcode_localities ? results[0].postcode_localities : [];
          const allColonias = results[0].postcode_localities ? results[0].postcode_localities : [];
          if (postcode_localities.length === 0) {
            stateCity.map(address_component => {
              if (address_component.types.includes("sublocality_level_1")) {
                allColonias.push(address_component.long_name);
              }
            })
            postcode_localities.push(stateCity[1]?.long_name);
          }

          if (postcode_localities.length !== 0) {
            // setColonies([INIT, ...postcode_localities, OTHER_COLONY]);
            // if(mySite.isB2B && postcode_localities.length === 1) {
            //   newAdressFormData["addressLine3"] = stateCity[1]?.long_name;
            // }
            setColonies([INIT, ...allColonias, OTHER_COLONY]);
            if (!edit) {
              if(allColonias.length === 1){
                newAdressFormData["addressLine3"] = allColonias[0];
              }else {
                newAdressFormData["addressLine3"] = INIT;
              }
            }

            // if (postcode_localities.length === 1) {
            //   setColonies(postcode_localities);
            //   if (!edit) {
            //     newAdressFormData["addressLine3"] = postcode_localities[0];
            //   }
            // } else {
            //   setColonies([INIT, ...postcode_localities, OTHER_COLONY]);
            //   if (!edit) {
            //     newAdressFormData["addressLine3"] = INIT;
            //   }
            // }
            // setColonyDisabled(true);
          } else {
            if (addressFormData.country === "MX") {
              findLocationByZip(addressFormData.zipCode);
            }
          }
          newAdressFormData["zipCode"] = value;
          newAdressFormData["country"] = results[0].country

          if (edit && newAdressFormData["addressLine3"] !== INIT) {
            const foundColony = postcode_localities.includes(newAdressFormData["addressLine3"]);
            if (!foundColony) {
              newAdressFormData["addressLine3Txt"] = newAdressFormData["addressLine3"];
              newAdressFormData["addressLine3"] = OTHER_COLONY;
            }
          }
          if (stateCity.length > 0) {
            setShowInputCity(false);

            const state = stateCity.filter((e) => e.types.includes("administrative_area_level_1"));
            if (state.length > 0) {
              const jsonStates = StatesCitiesList.formStatesObject;
              const stateShortName = state[0].short_name;
              let stateName = state[0].long_name;
              jsonStates.map((e) => {
                if (e.googlestateabbr === stateShortName) {
                  stateName = e.totstate;
                }
              });
              setStates([stateName]);
              newAdressFormData["state"] = stateName;
            }

            const locality = stateCity.filter((e) => e.types.includes("locality") || e.types.includes("administrative_area_level_2"))
            if (locality.length > 0) {
              if (locality[0].long_name === "Ciudad de México" || locality[0].long_name === "Ciudad de Mexico") {
                setCitiesSelect(toNormalForm(locality[0].long_name), value);
                setCityDisabled(false);
              } else {
                setCities([locality[0].long_name]);
                newAdressFormData["city"] = locality[0].long_name;
                setCityDisabled(true);
                setStateDisabled(true);
              }
            } else {
              if (state.length > 0) {
                if (page === CheckoutPageType.PAYMENT && addressFormData.country === "US") {
                  setShowInputStateAndCity(true);
                  newAdressFormData["state"] = INIT;
                }
                setCityDisabled(false);
                setCitiesSelect(toNormalForm(state[0].long_name));
                if (newAdressFormData["city"] === EMPTY_STRING) {
                  newAdressFormData["city"] = INIT;
                }
                setShowInputCity(true)
                if(!edit){
                  newAdressFormData["city"] = EMPTY_STRING
                }else if(edit && newAdressFormData["city"] === INIT){
                  newAdressFormData["city"] = EMPTY_STRING
                }

              } else {
                if (page === CheckoutPageType.PAYMENT && addressFormData.country === "US") {
                  setShowInputStateAndCity(true);
                  newAdressFormData["state"] = INIT;
                }
                if (
                  (page === CheckoutPageType.SHIPPING || page === CheckoutPageType.PAYMENT) &&
                  addressFormData.country === "MX"
                ) {
                  findLocationByZip(addressFormData.zipCode);
                } else {
                  newAdressFormData["state"] = INIT;
                  newAdressFormData["addressLine3"] = showInputStateAndCity ? OTHER_COLONY : INIT;
                  setColonies([INIT, OTHER_COLONY]);
                  setStates([INIT]);
                  setErrorZip(true);
                  setCityDisabled(true);
                }
                return;
              }
            }
            if (page !== CheckoutPageType.PAYMENT){
              if ((newAdressFormData["addressLine3"] === EMPTY_STRING && edit)) {
                newAdressFormData["addressLine3"] = INIT;
              }
            }

            setErrorZip(false);
            setIsFormValid && setIsFormValid({...isFormValid, isZipCodeValid: true});
          } else {
            if (addressFormData.country === "MX") {
              findLocationByZip(addressFormData.zipCode);
            } else {
              noGoogleMode();
            }
          }
          setAddressFormData(newAdressFormData);
        }
        if ((results && results?.length === 0 && country !== "US") || status !== GOOGLE_GEOCODER_SUCCESS_STATUS || googleOff) {
          if (
            (page === CheckoutPageType.SHIPPING ||
              page === CheckoutPageType.PAYMENT ||
              ((!results || (results && results?.length === 0)) && status === "ZERO_RESULTS")) &&
              addressFormData.country === "MX"
            ) {
            findLocationByZip(addressFormData.zipCode);
          } else {
            noGoogleMode();
          }
        } else if(results && results?.length === 0 && status?.trim() === GOOGLE_GEOCODER_SUCCESS_STATUS && !googleOff){
          noGoogleMode()
        }
      }
    );
  };

  const findLocationByZip = async (zipValue) => {
    setCustomTablesFlag(true);
    setShowInputStateAndCity(false);
    const newAdressFormData = { ...addressFormData };

    let addressLine3
    if(newAdressFormData?.zipCode === zipValue && edit){
      addressLine3 = newAdressFormData.addressLine3
    }
    await Axios.get(
      `/wcs/resources/store/${mySite.storeID}/locationFetch/byZipcode?responseFormat=json&zipCode=${zipValue}`
    )
      .then((res) => {
        if (res && res?.data) {
          const responseApi = res.data.response.result;
          if (responseApi?.state?.name) {
            setErrorZip(false);
            setIsFormValid && setIsFormValid({...isFormValid, isZipCodeValid: true});
            let stateValue = capitalizeFirst(responseApi.state.name.toLowerCase());
            const jsonStates = StatesCitiesList.formStatesObject;
            jsonStates.map((e) => {
              if (e.xstatesabbr === responseApi.state.id) {
                stateValue = e.totstate;
              }
            });
            const jsonColonies = responseApi.colonies;
            const jsonCities = responseApi.city.sort((a, b) => {
              if (a.name < b.name) return -1
              if (a.name > b.name) return 1
              return 0
            });
            const arrayColonies: string[] = [];
            jsonColonies.map((e) => {
              arrayColonies.push(capitalizeFirst(e.name.toLowerCase()));
            });
            const arrayCities: string[] = [];
            jsonCities.map((e) => {
              arrayCities.push(capitalizeFirst(e.name.toLowerCase()));
            });
            setStates([stateValue]);

            if(jsonColonies.length === 1 && !edit){
              newAdressFormData["addressLine3"] = arrayColonies[0];
            }else {
              newAdressFormData["addressLine3"] = addressLine3 ? addressLine3 : INIT;
            }

            newAdressFormData["addressLine3Txt"] = EMPTY_STRING;
            newAdressFormData["state"] = stateValue;
            newAdressFormData["country"] = "MX";
            setCities([INIT, ...arrayCities]);
            setColonies([INIT, ...arrayColonies, OTHER_COLONY]);
            setColonyDisabled(true);
            setStateDisabled(true);
            setGoogleOff(false);
            if (stateValue.toLowerCase() === "ciudad de mexico" || stateValue.toLowerCase() === "ciudad de méxico") {
              setCityDisabled(false);
            } else {
              newAdressFormData["city"] = arrayCities[0];
              setCityDisabled(true);
            }
            // setCountryDisable(true)
            setAddressFormData(newAdressFormData);
          } else {
            if (!isProLeadPage) {
              setErrorZip(true);
            }
            noGoogleMode();
          }
        } else {
          noGoogleMode();
        }
      })
      .catch((error) => {
        noGoogleMode();
      });
  };

  const noGoogleMode = async () => {
    setCustomTablesFlag(false);
    // setGoogleOff(true);
    // setShowInputStateAndCity(false);
    const newAdressFormData = { ...addressFormData };
    if (page === CheckoutPageType.PAYMENT && addressFormData.country === "US") {
      setShowInputStateAndCity(true);

      const initState = await setStatesSelect();
      if (edit) {
        setCitiesSelect(toNormalForm(addressFormData.state));

        if (newAdressFormData["addressLine3"] !== INIT) {
          newAdressFormData["addressLine3Txt"] = addressFormData.addressLine3;
          newAdressFormData["addressLine3"] = OTHER_COLONY;
        } else {
          newAdressFormData["addressLine3"] = INIT;
          newAdressFormData["addressLine3Txt"] = EMPTY_STRING;
        }
      } else {
        newAdressFormData["addressLine3"] = OTHER_COLONY;
        newAdressFormData["addressLine3Txt"] = EMPTY_STRING;
        newAdressFormData["city"] = EMPTY_STRING;
        newAdressFormData["state"] = EMPTY_STRING;
        setCitiesSelect(initState);
      }
      setAddressFormData(newAdressFormData);
      setColonyDisabled(false);
      setCityDisabled(false);
      setStateDisabled(false);
      setErrorZip(false);
    } else {
      // newAdressFormData["state"] = INIT;
      // newAdressFormData["addressLine3"] = showInputStateAndCity ? OTHER_COLONY : INIT;
      // newAdressFormData["addressLine3Txt"] = EMPTY_STRING;
      if(edit){
        newAdressFormData["addressLine3Txt"] = addressFormData.addressLine3;
        newAdressFormData["addressLine3"] = OTHER_COLONY;
      }else {
        newAdressFormData["addressLine3"] = OTHER_COLONY;
        newAdressFormData["addressLine3Txt"] = EMPTY_STRING;
        newAdressFormData["city"] = EMPTY_STRING;
        newAdressFormData["state"] = EMPTY_STRING;
      }
      setAddressFormData(newAdressFormData);
      setIsFormValid && setIsFormValid({...isFormValid, isZipCodeValid: false});
      setColonies([INIT, OTHER_COLONY]);
      setStates([INIT]);
      setErrorZip(true);
      setColonyDisabled(true);
      setCityDisabled(true);
      setStateDisabled(true);
      setErrorCity(false)
      setErrorState(false)
      setShowInputStateAndCity(true)
    }
  };

  const setStatesSelect = async () => {
    const jsonStates = StatesCitiesList.formStatesObject;
    const arrayStates: string[] = [];
    await jsonStates.map((e) => {
      arrayStates.push(e.totstate);
    });
    setStates([INIT, ...arrayStates]);
    return arrayStates[1];
  };

  useEffect(() => {

    if(googleOff && addressFormData.addressLine3 === OTHER_COLONY){
      setCitiesSelect(toNormalForm(addressFormData.state));
    }else if (googleOff && addressFormData.state !== INIT && !customTablesFlag) {
      const newAdressFormData = { ...addressFormData };
      newAdressFormData["city"] = INIT;
      setAddressFormData(newAdressFormData);
      setCitiesSelect(toNormalForm(addressFormData.state));
    }
  }, [addressFormData?.state]);

  const setCitiesSelect = async (state, zipCode: string = "") => {
    let jsonCities: string[] = [];
    const jsonColonies: string[] = [];
    if (mySite.isB2B && state?.normalize("NFD")?.replace(/[\u0300-\u036f]/g, "") === MEXICO_CITY && zipCode) {
      await Axios.get(
        `/wcs/resources/store/${mySite.storeID}/locationFetch/byZipcode?responseFormat=json&zipCode=${zipCode}`
      ).then((res) => {
        const responseApi = res.data.response.result;

        if (responseApi?.state?.name) {
          const apiCities = responseApi.city.sort((a, b) => {
            if (a.name < b.name) return -1
            if (a.name > b.name) return 1
            return 0
          });
          apiCities.map((e) => {
            jsonCities.push(capitalizeFirst(e.name.toLowerCase()));
          });
          const apiColonies = responseApi.colonies.sort((a, b) => {
            if (a.name < b.name) return -1
            if (a.name > b.name) return 1
            return 0
          });
          apiColonies.map((e) => {
            jsonColonies.push(capitalizeFirst(e.name.toLowerCase()));
          });
        }
      })
      .catch(error => {
        console.log("error", error)
      })
    } else if (StatesCitiesList?.formCitiesObject[state]) {
      jsonCities = StatesCitiesList.formCitiesObject[state].split("|");
    }
    setCities([INIT, ...jsonCities]);
    setColonies([INIT, ...jsonColonies, OTHER_COLONY]);
  };

  const capitalizeFirst = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  const toNormalForm = (str) => {
    return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
  };

  // Checkout step3 confirmBillingAddress validator
  const canContinueSaveNewCard = () => {
    if (
      (!isGuest &&
        page !== "payment" &&
        addressFormData?.firstName &&
        addressFormData?.firstName.length > 0 &&
        addressFormData?.lastName &&
        addressFormData?.lastName.length > 0 &&
        addressFormData?.addressLine1 &&
        addressFormData?.addressLine1.length > 0 &&
        addressFormData?.addressLine2 &&
        addressFormData?.addressLine2.length > 0 &&
        // Colony select and Colony input
        ((addressFormData?.addressLine3 &&
          addressFormData?.addressLine3.length > 1 &&
          addressFormData?.addressLine3 !== "Mi colonia no aparece") ||
          (addressFormData?.addressLine3Txt && addressFormData?.addressLine3Txt.length > 0)) &&
        addressFormData?.city &&
        addressFormData?.city.length > 1 &&
        addressFormData?.country &&
        addressFormData?.country.length > 0 &&
        addressFormData?.state &&
        addressFormData?.state.length > 0 &&
        addressFormData?.zipCode &&
        addressFormData?.zipCode.length > 0 &&
        addressFormData?.nickName &&
        addressFormData?.nickName.length > 0) ||
      (isGuest &&
        addressFormData?.firstName &&
        addressFormData?.firstName.length > 0 &&
        addressFormData?.lastName &&
        addressFormData?.lastName.length > 0 &&
        addressFormData?.addressLine1 &&
        addressFormData?.addressLine1.length > 0 &&
        addressFormData?.addressLine2 &&
        addressFormData?.addressLine2.length > 0 &&
        // Colony select and Colony input
        ((addressFormData?.addressLine3 &&
          addressFormData?.addressLine3.length > 1 &&
          addressFormData?.addressLine3 !== "Mi colonia no aparece") ||
          (addressFormData?.addressLine3Txt && addressFormData?.addressLine3Txt.length > 0)) &&
        addressFormData?.city &&
        addressFormData?.city.length > 1 &&
        addressFormData?.country &&
        addressFormData?.country.length > 0 &&
        addressFormData?.state &&
        addressFormData?.state.length > 0 &&
        addressFormData?.zipCode &&
        addressFormData?.zipCode.length > 0) ||
      // Checkout step 3
      (!isGuest &&
        page === "payment" &&
        addressFormData?.firstName &&
        addressFormData?.firstName.length > 0 &&
        addressFormData?.lastName &&
        addressFormData?.lastName.length > 0 &&
        addressFormData?.addressLine1 &&
        addressFormData?.addressLine1.length > 0 &&
        addressFormData?.addressLine2 &&
        addressFormData?.addressLine2.length > 0 &&
        // Colony select and Colony input
        ((addressFormData?.addressLine3 &&
          addressFormData?.addressLine3.length > 1 &&
          addressFormData?.addressLine3 !== "Mi colonia no aparece") ||
          (addressFormData?.addressLine3Txt && addressFormData?.addressLine3Txt.length > 0)) &&
        addressFormData?.city &&
        addressFormData?.city.length > 1 &&
        addressFormData?.country &&
        addressFormData?.country.length > 0 &&
        addressFormData?.state &&
        addressFormData?.state.length > 0 &&
        addressFormData?.zipCode &&
        addressFormData?.zipCode.length > 0)
    ) {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    if ((page === "payment" || page === "shipping") && !fromMyAccount && setcontinueCreatingNewCard !== undefined) {
      setcontinueCreatingNewCard(canContinueSaveNewCard());
    }
  }, [addressFormData]);

  const itemColonies = React.Children.toArray(
    colonies && colonies.length > 0 ? (
      colonies.map((colony, index) => {
        if (colony === OTHER_COLONY && isProLeadPage) return;

        return (
          <StyledMenuItem
            key={colony}
            value={colony === INIT ? "0" : colony}
            className={"styledSelect--menuItems-color"}
            fullWidth>
            <StyledTypography variant="bodyBaseline">
              {colony === INIT && isProLeadPage
                ? t("ProLeadForm.Labels.selectAnOption")
                : colony === INIT
                ? t("AddressForm.Labels.SelectColony")
                : colony}
            </StyledTypography>
          </StyledMenuItem>
        );
      })
    ) : (
      <StyledMenuItem value={isProLeadPage ? "0" : ""} className={"styledSelect--menuItems-color"} fullWidth>
        <StyledTypography variant="bodyBaseline">
          {isProLeadPage ? t("ProLeadForm.Labels.selectAnOption") : t("AddressForm.Labels.SelectColony")}
        </StyledTypography>
      </StyledMenuItem>
    )
  );

  const arrayCities = React.Children.toArray(
    cities && cities.length > 0 ? (
      cities.map((city, index) => (
        <StyledMenuItem value={city === INIT ? "0" : city} className={"styledSelect--menuItems-color"} fullWidth>
          <StyledTypography variant="bodyBaseline">
            {city === INIT && isProLeadPage
              ? t("ProLeadForm.Labels.selectAnOption")
              : city === INIT
              ? mySite.isB2B && fromCheckout
                ? t("AddressForm.Labels.SelectMunicipalityDelegation")
                : t("AddressForm.Labels.SelectMunicipality")
              : city}
          </StyledTypography>
        </StyledMenuItem>
      ))
    ) : (
      <StyledMenuItem value={isProLeadPage ? "0" : ""} className={"styledSelect--menuItems-color"} fullWidth>
        <StyledTypography variant="bodyBaseline">
          {isProLeadPage ? t("ProLeadForm.Labels.selectAnOption") : t("AddressForm.Labels.City")}
        </StyledTypography>
      </StyledMenuItem>
    )
  );

  const arrayCountries = React.Children.toArray(
    countryArray && countryArray.length > 0 ? (
      countryArray.map((country, index) => {
        if(country.id === "0" && addressFormData?.country !== "0") return null
        return(
          <StyledMenuItem key={country.id} value={country.id} className={"styledSelect--menuItems-color"} fullWidth>
            <StyledTypography variant="bodyBaseline">
              {country.id === INIT ? t("AddressForm.Labels.SelectCountry") : country.name}
            </StyledTypography>
          </StyledMenuItem>
        )
      })
    ) : (
      <StyledMenuItem value={""} className={"styledSelect--menuItems-color"} fullWidth>
        <StyledTypography variant="bodyBaseline">{t("AddressForm.Labels.City")}</StyledTypography>
      </StyledMenuItem>
    )
  );

  const itemState = React.Children.toArray(
    states && states.length > 0 ? (
      states.map((state, index) => (
        <StyledMenuItem value={state === INIT ? "0" : state} className={"styledSelect--menuItems-color"} fullWidth>
          <StyledTypography variant="bodyBaseline">
            {state === INIT && isProLeadPage
              ? t("ProLeadForm.Labels.selectAnOption")
              : state === INIT
              ? t("AddressForm.Labels.SelectState")
              : state}
          </StyledTypography>
        </StyledMenuItem>
      ))
    ) : (
      <StyledMenuItem value={INIT} className={"styledSelect--menuItems-color"} fullWidth selected>
        <StyledTypography variant="bodyBaseline">
          {isProLeadPage ? t("ProLeadForm.Labels.selectAnOption") : t("AddressForm.Labels.State")}
        </StyledTypography>
      </StyledMenuItem>
    )
  );

  const resetAddressLine3ZipCodeBlur = () => {
    if (!isProLeadPage) {
      setErrorColony(false);
    }
    const newAdressFormData = { ...addressFormData };
    newAdressFormData["addressLine3Txt"] = "";
    if (
      (!customTablesFlag && googleOff) ||
      (page === CheckoutPageType.PAYMENT && addressFormData.country !== "MX") ||
      (fromMyAccount && addressFormData.country !== "MX")
    ) {
      newAdressFormData["addressLine3"] = OTHER_COLONY;
    } else if (mySite.isB2B && colonies.length === 3) {
      newAdressFormData["addressLine3"] = addressFormData["addressLine3"];
    } else {
      if(googleOff){
        newAdressFormData["addressLine3"] = OTHER_COLONY;
      }
      newAdressFormData["addressLine3"] = INIT;
    }

    setAddressFormData(newAdressFormData);
  };

  const handleBlur = (input: string) => {
    switch (input) {
      case "firstName":
        setErrorFirstName(true);
        break;
      case "lastName":
        setErrorLastName(true);
        break;
      case "NickName":
        setErrorAlias(true);
        break;
      case "Street":
        setErrorStreet(true);
        break;
      case "ExtNum":
        setErrorExtNum(true);
        break;
      case "ZipCode":
        if (addressFormData.zipCode.length > 0 && addressFormData.zipCode.length <= 4) {
          setErrorZip(true);
          setGoogleOff(false);
        }
        if (addressFormData.country !== "US") {
          resetAddressLine3ZipCodeBlur();
          setErrorZipCode(true);
        }
        break;
      case "Colony":
        setErrorColony(true);
        break;
      case "ColonyTxt":
        setErrorColonyTxt(true);
        break;
      case "City":
        setErrorCity(true);
        break;
      case "State":
        setErrorState(true);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (page === "shipping" && !fromMyAccount) {
      addressFormData.firstName = guestUserData.firstName;
      addressFormData.lastName = guestUserData.lastName;
    }
  }, [addressFormData, page]);

  const ESPOT_TOOLTIP = mySite.isB2B
    ? "PRO_DeliveryDetail_AddressForm-Tooltip"
    : "Checkout_DeliveryDetail_AddressForm-Tooltip";

  return (
    <StyledGrid
      container
      spacing={isProLeadPage ? 2 : fromCheckOutProfiles ? 0 : 1}
      style={fromCheckOutProfiles ? { padding: "0px 10px" } : {}}>
      {fromMyAccount && !showUsableAddresses && !fromCheckOutProfiles ? (
        <>
          <StyledGrid item xs={12}>
            <AddressContext.Provider
              value={{
                toggleEditAddress: toggleEditAddress,
                setEditAddressFormData: setAddressFormData,
                // orgAddressDetails: orgAddressDetails,
              }}>
              <AddressList
                cid="shipping"
                addressList={usableAddresses}
                handleReadOnlyShipping={true}
                setSelectedAddressId={setSelectedAddressId}
                // selectedAddressId={selectedAddressId || EMPTY_STRING}
                selectedAddressId={selectedAddressId}
                // hideEdit={true}
                // readOnlyShipping={false}
                fromMyAccount={fromMyAccount}
              />
            </AddressContext.Provider>
          </StyledGrid>
        </>
      ) : null}

      {/* Alias textfield */}
      {(!isFirstAddress && loginStatusRedux && !fromMyAccount && page === "shipping") ||
      (!isFirstAddress && !mySite.isB2B && page === "address-book") ||
      (page === "address-book" && mySite.isB2B) ||
      page === "checkout-profile" ? (
        <StyledGrid xs={12}>
          <StyledTextField
            className="form-myaccount"
            required
            id={`${cid}-nickName`}
            name="nickName"
            label={t("AddressForm.Labels.NickName")}
            onChange={handleChange}
            value={isMainAddress ? t("Account.Labels.MainAddress") : addressFormData.nickName}
            inputProps={{ maxLength: 30 }}
            onBlur={(e) => {
              handleBlur("NickName");
            }}
            fullWidth
            autoComplete="nickname"
            disabled={isFirstAddress && loginStatusRedux ? false : edit}
            error={
              (errorAlias &&
                (addressFormData.nickName == EMPTY_STRING ||
                  !addressUtil.validateNickName(addressFormData.nickName))) ||
              nickNameNotAvailable ||
              (!edit && !addressUtil.validateNickName(addressFormData.nickName))
            }
            helperText={
              errorAlias &&
              (addressFormData.nickName == EMPTY_STRING || !addressUtil.validateNickName(addressFormData.nickName))
                ? t("AddressForm.Msgs.Required")
                : nickNameNotAvailable
                ? page === "address-book"
                  ? t("AddressForm.Msgs.AliasNotAvailableMyAccount")
                  : t("AddressForm.Msgs.AliasNotAvailable")
                : !edit && !addressUtil.validateNickName(addressFormData.nickName)
                ? t("AddressForm.Msgs.InvalidAddressName")
                : EMPTY_STRING
            }
          />
        </StyledGrid>
      ) : null}

      {(fromMyAccount && showUsableAddresses) || (fromMyAccount && fromCheckOutProfiles) || page === "payment" ? (
        <>
          {/* firstName textfield */}
          <StyledGrid xs={12} md={fromCheckOutProfiles ? 12 : 6}>
            <StyledTextField
              className="form-myaccount"
              required
              id={`${cid}-street`}
              name="firstName"
              label={t("BuyerOrganizationRegistration.FirstName")}
              onChange={handleChange}
              onBlur={(e) => {
                handleBlur("firstName");
              }}
              error={errorFirstName && addressFormData?.firstName === EMPTY_STRING}
              value={addressFormData?.firstName}
              inputProps={{ maxLength: 40 }}
              fullWidth
              helperText={
                errorFirstName && addressFormData?.firstName === EMPTY_STRING
                  ? t("AddressForm.Msgs.Required")
                  : EMPTY_STRING
              }
            />
          </StyledGrid>

          {/* lastName textfield */}
          <StyledGrid xs={12} md={fromCheckOutProfiles ? 12 : 6}>
            <StyledTextField
              className="form-myaccount"
              required
              id={`${cid}-street`}
              name="lastName"
              label={t("BuyerOrganizationRegistration.LastName")}
              onChange={handleChange}
              onBlur={(e) => {
                handleBlur("lastName");
              }}
              error={errorLastName && addressFormData?.lastName === EMPTY_STRING}
              value={addressFormData?.lastName}
              inputProps={{ maxLength: 40 }}
              fullWidth
              helperText={
                errorLastName && addressFormData?.lastName === EMPTY_STRING
                  ? t("AddressForm.Msgs.Required")
                  : EMPTY_STRING
              }
            />
          </StyledGrid>
        </>
      ) : null}

      {showUsableAddresses || fromCheckOutProfiles ? (
        <>
          {/* Street textfield */}
          <StyledGrid xs={12} md={fromMyAccount && !fromCheckOutProfiles ? 6 : 12}>
            <StyledTextField
              className="form-myaccount"
              required
              id={`${cid}-street`}
              name="addressLine1"
              label={t("AddressForm.Labels.Address1")}
              onChange={handleChange}
              onBlur={(e) => {
                handleBlur("Street");
              }}
              error={errorStreet && addressFormData?.addressLine1 == EMPTY_STRING}
              value={addressFormData?.addressLine1}
              inputProps={{ maxLength: isProLeadPage ? 40 : 99 }}
              fullWidth
              autoComplete="address-street"
              helperText={
                errorStreet && addressFormData?.addressLine1 == EMPTY_STRING
                  ? t("AddressForm.Msgs.Required")
                  : EMPTY_STRING
              }
            />
          </StyledGrid>

          {/* Exterior number textfield */}
          <StyledGrid xs={12} md={fromCheckOutProfiles ? 12 : 6}>
            <StyledTextField
              className="form-myaccount"
              required
              id={`${cid}-address2`}
              name="addressLine2"
              label={t("AddressForm.Labels.ExtNum")}
              onChange={handleChange}
              error={errorExtNum && addressFormData?.addressLine2 == EMPTY_STRING}
              onBlur={(e) => {
                handleBlur("ExtNum");
              }}
              value={addressFormData?.addressLine2}
              inputProps={{ maxLength: 9 }}
              fullWidth
              autoComplete="address-extNum"
              helperText={
                errorExtNum && addressFormData?.addressLine2 == EMPTY_STRING
                  ? t("AddressForm.Msgs.Required")
                  : EMPTY_STRING
              }
              onKeyPress={(event) => {
                if (!/[0-9]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
              InputProps={{
                endAdornment: isProLeadPage ? null : (
                  <StyledTooltipHdm content={<EspotHdm espotName={ESPOT_TOOLTIP} />} />
                ),
              }}
            />
          </StyledGrid>

          {/* Address indications textfield */}
          {isProLeadPage ? null : (
            <StyledGrid xs={12} md={fromCheckOutProfiles ? 12 : 6}>
              <StyledTextField
                className="form-myaccount"
                id={`${cid}-indications`}
                name="field3"
                label={t("AddressForm.Labels.Indications")}
                value={addressFormData?.field3}
                onChange={handleChange}
                inputProps={{ maxLength: 49 }}
                fullWidth
                autoComplete="address-indications"
              />
            </StyledGrid>
          )}

          {/* Zipcode textfield */}
          <StyledGrid xs={12} md={fromCheckOutProfiles ? 12 : 6}>
            <StyledTextField
              className="form-myaccount"
              required
              id={`${cid}-zipCode`}
              name="zipCode"
              label={
                mySite.isB2B && fromCheckout ? t("AddressForm.Labels.ZipCodeB2B") : t("AddressForm.Labels.ZipCode")
              }
              value={addressFormData?.zipCode}
              inputProps={{ maxLength: 5, inputMode: "numeric", pattern: "[0-9]*" }}
              onChange={handleChange}
              onBlur={(e) => {
                handleBlur("ZipCode");
              }}
              fullWidth
              autoComplete="postal-code"
              helperText={
                errorZipCode && addressFormData?.zipCode == EMPTY_STRING
                  ? t("AddressForm.Msgs.Required")
                  : errorZip
                  ? page === CheckoutPageType.PAYMENT
                    ? t("AddressForm.Msgs.ZipErrorCard")
                    : page === "address-book" || (fromCheckOutProfiles && isMainAddress)
                    ? t("AddressForm.Msgs.ZipCodeError")
                    : mySite.isB2B && !fromCheckout
                    ? t("AddressForm.Msgs.ZipErrorNot")
                    : t("AddressForm.Msgs.ZipError")
                  : EMPTY_STRING
              }
              onKeyPress={(event) => {
                if (!/[0-9]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
              error={(errorZipCode && addressFormData?.zipCode == EMPTY_STRING) || errorZip}
            />
          </StyledGrid>
          {!showInputStateAndCity ? (
            <>
              <StyledGrid
                className="form-myaccount-selected"
                xs={12}
                md={fromCheckOutProfiles ? 12 : 6}
                // style={
                //   page === CheckoutPageType.PAYMENT && addressFormData?.country !== "MX" ? { display: "none" } : {}
                // }
              >
                {/* Colony select */}
                <StyledSelectHdm
                  required
                  label={
                    isProLeadPage
                      ? t("ProLeadForm.Labels.colony")
                      : mySite.isB2B && (addressFormData?.addressLine3 === INIT || addressFormData?.addressLine3 === "")
                      ? t("AddressForm.Labels.SelectColony")
                      : t("AddressForm.Labels.Colony")
                  }
                  name="addressLine3"
                  handleChange={handleChange}
                  value={addressFormData?.addressLine3}
                  items={itemColonies}
                  disabled={colonies.length === 1 || googleOff ? true : false}
                  onBlur={() => handleBlur("Colony")}
                  autoComplete="address-colony"
                  helperText={
                    (errorColony && addressFormData?.addressLine3 === INIT) || errorColony
                      ? t("AddressForm.Msgs.Required")
                      : ""
                  }
                  error={errorColony && addressFormData?.addressLine3 === INIT}
                />
              </StyledGrid>
            </>
          ) : null}

          {(addressFormData?.addressLine3 === OTHER_COLONY ||
            // (page === CheckoutPageType.PAYMENT && addressFormData?.country !== "MX") ||
            !colonyDisabled) && (
            <StyledGrid
              xs={12}
              md={fromMyAccount && !fromCheckOutProfiles && addressFormData.country !== "MX" ? 6 : 12}>
              {/* Colony textfield */}
              <StyledTextField
                className="form-myaccount"
                required
                id={`${cid}-address3Txt`}
                name="addressLine3Txt"
                label={t("AddressForm.Labels.ColonyName")}
                onChange={handleChange}
                error={errorColonyTxt && addressFormData?.addressLine3Txt === EMPTY_STRING}
                onBlur={(e) => {
                  handleBlur("ColonyTxt");
                }}
                value={addressFormData?.addressLine3Txt}
                fullWidth
                helperText={
                  errorColonyTxt && addressFormData?.addressLine3Txt === EMPTY_STRING
                    ? t("AddressForm.Msgs.Required")
                    : EMPTY_STRING
                }
                InputProps={{
                  endAdornment: <StyledTooltipHdm content={t("AddressForm.Labels.ColonyTooltip")} />,
                }}
              />
            </StyledGrid>
          )}

          {/* City textfield */}
          {!showInputStateAndCity && !showInputCity ? (
            <>
              <StyledGrid xs={12} md={fromCheckOutProfiles ? 12 : 6} className="form-myaccount-selected">
                {/* City textfield */}
                <StyledSelectHdm
                  required
                  id={`${cid}-city`}
                  disabled={cityDisabled}
                  name="city"
                  label={
                    mySite.isB2B && (addressFormData?.city === INIT || addressFormData?.city === "")
                      ? fromCheckout
                        ? t("AddressForm.Labels.SelectMunicipalityDelegation")
                        : t("AddressForm.Labels.SelectMunicipality")
                      : t("AddressForm.Labels.Municipality")
                  }
                  handleChange={handleChange}
                  value={addressFormData?.city}
                  fullWidth
                  items={arrayCities}
                  onBlur={(e) => {
                    handleBlur("City");
                  }}
                  helperText={
                    (errorCity && addressFormData?.city == INIT) || errorCity ? t("AddressForm.Msgs.Required") : ""
                  }
                  error={errorCity && addressFormData?.city == INIT}
                />
              </StyledGrid>
            </>
          ) : (
            <>
              <StyledGrid xs={12} md={fromCheckOutProfiles ? 12 : 6}>
                <StyledTextField
                  className="form-myaccount"
                  required
                  id={`${cid}-city`}
                  name="cityInput"
                  label={t("AddressForm.Labels.Municipality")}
                  onChange={handleChange}
                  value={addressFormData?.city}
                  fullWidth
                  onBlur={(e) => {
                    handleBlur("City");
                  }}
                  inputProps={{ maxLength: 40 }}
                  helperText={
                    errorCity && addressFormData?.city === EMPTY_STRING ? t("AddressForm.Msgs.Required") : EMPTY_STRING
                  }
                  error={errorCity && addressFormData?.city == EMPTY_STRING}
                />
              </StyledGrid>
            </>
          )}

          <StyledGrid
            xs={12}
            md={fromCheckOutProfiles ? 12 : 6}
            className={!showInputStateAndCity ? "form-myaccount-selected" : ""}>
            {/*TODO: states need to reload based on country selection and can either be dropdown
          (fetch list from transaction server) or textbox 
            <StyledTextField
              required
              id={`${cid}-state`}
              name="state"
              label={t("AddressForm.Labels.State")}
              onChange={handleChange}
              value={addressFormData.state}
              inputProps={{ maxLength: 40 }}
              disabled={true}
              fullWidth
              autoComplete="state"
            />*/}
            {!showInputStateAndCity ? (
              <>
                {/* State textfield */}
                <StyledSelectHdm
                  required
                  disabled={stateDisabled}
                  id={`${cid}-state`}
                  name="state"
                  label={
                    mySite.isB2B && (addressFormData?.state === INIT || addressFormData?.state === "")
                      ? t("AddressForm.Labels.SelectState")
                      : t("AddressForm.Labels.State")
                  }
                  handleChange={handleChange}
                  onBlur={(e) => {
                    handleBlur("State");
                  }}
                  value={addressFormData?.state}
                  fullWidth
                  autoComplete="state"
                  items={itemState}
                  helperText={
                    (errorState && addressFormData?.state == INIT) || errorState ? t("AddressForm.Msgs.Required") : ""
                  }
                  error={errorState && addressFormData?.state == INIT}
                />
              </>
            ) : (
              <>
                <StyledGrid xs={12} md={12}>
                  <StyledTextField
                    className="form-myaccount"
                    required
                    id={`${cid}-state`}
                    name="stateInput"
                    label={t("AddressForm.Labels.State")}
                    onChange={handleChange}
                    onBlur={(e) => {
                      handleBlur("State");
                    }}
                    fullWidth
                    value={addressFormData?.state}
                    autoComplete="state"
                    helperText={
                      errorState && addressFormData?.state === EMPTY_STRING
                        ? t("AddressForm.Msgs.Required")
                        : EMPTY_STRING
                    }
                    error={errorState && addressFormData?.state == EMPTY_STRING}
                  />
                </StyledGrid>
              </>
            )}
          </StyledGrid>

          {page === CheckoutPageType.PAYMENT || fromMyAccount ? (
            <StyledGrid
              xs={12}
              md={fromMyAccount && !fromCheckOutProfiles ? 6 : 12}
              className="form-myaccount-selected">
              {/* Country textfield */}
              <StyledSelectHdm
                required
                disabled={countryDisabled}
                id={`${cid}-country`}
                name="country"
                label={t("AddressForm.Labels.Country")}
                handleChange={handleChange}
                value={addressFormData?.country}
                fullWidth
                // defaultValue={arrayCountries[0]}
                items={arrayCountries}
                onBlur={(e) => {
                  handleBlur("Country");
                }}
                helperText={
                  errorColonyTxt && addressFormData?.addressLine3Txt === EMPTY_STRING
                    ? t("AddressForm.Msgs.Required")
                    : EMPTY_STRING
                }
              />
            </StyledGrid>
          ) : null}
        </>
      ) : null}
    </StyledGrid>
  );
};

export { AddressForm };
