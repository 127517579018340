/*
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */
//Standard libraries
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
//UI
import { StyledTypography, StyledBreadcrumbs, StyledChip } from "../../elements";

import HomeIcon from "@material-ui/icons/Home";
import { useDispatch, useSelector } from "react-redux";
import { currentSelectedProduct } from "../../../redux/selectors/catalog";
import { resetProductListAction } from "../../../redux/actions/catalog";
import { hdmPDPproductDataRdc, hdmStoreBuyableExclude } from "../../../redux/selectors/hdmData";
import { useLocation } from "react-router";
import { ACCOUNT, CATALOG_PREFIX, WISH_LIST_DISPLAY_VIEW } from "../../../constants/routes";
import { SLPDPBreadCrumb } from "./../../../components/custom-components/skeleton-loader/components/elements/PDP";
import { SLPLPBreadCrumbComponent } from "../../../components/custom-components/skeleton-loader/components/elements/PLP";
import withSkeletonLoader from "../../../components/custom-components/skeleton-loader/hoc/withSkeletonLoader";
import { getCookieValue } from "../../../components/custom-components/conversion-api/cookieHandler";

interface BreadcrumbWidgetProps {
  cid: string;
  breadcrumbs: Array<any>;
}

/**
 * Breadcrumb widget component
 * @summary displays breadcrumb trail
 * `@prop {any} props` have following properties:
 * `@property {string} cid(required)` Unique identifier for layouts.
 * `@property {array} breadcrumbs(required)` It is an array and each element contains info such as a breadcrumb and link etc.
 */

const StyledBreadcrumbContainer = styled((props: any) => <StyledTypography {...props} />)`
  ${({ theme }) => `
      & .breadcrumb-first-element{
        display: flex;
        align-items: center;
        & svg{
          font-size: 16px;
          color: ${theme.palette.secondary.main};
        }
      }

      & .breadcrumb-last-element{
        border-radius: 3px;
        box-shadow: none;
        color: ${theme.palette.gray[300]};
        padding: ${theme.spacing(1)}px ${theme.spacing(2)}px;
        overflow: inherit;
        }
      }
      
      & .breadcrumb-chip{
        border-radius: 3px;
        box-shadow: none;
        color: ${theme.palette.secondary.main};
        padding: ${theme.spacing(1)}px ${theme.spacing(2)}px;
        cursor: pointer;
      }

      & li.MuiBreadcrumbs-li:last-child {
        overflow: inherit;
      }

  `}
`;

export const BreadcrumbWidget: React.FC<BreadcrumbWidgetProps> = (props: any) => {
  const { breadcrumbs, cid, disabled } = props;

  /* Check if currently on PDP page */
  const [onPDP, setPDP] = useState(false);
  const productListState = useSelector((state: any) => state.catalog.productList);
  const currentProduct = useSelector(currentSelectedProduct);
  const storeBuyableExclude = useSelector(hdmStoreBuyableExclude);
  const hdmPDPproductData = useSelector(hdmPDPproductDataRdc);
  const location: any = useLocation();
  const urlWishList = location && location.pathname.includes(WISH_LIST_DISPLAY_VIEW);

  const isSearchPage = location.pathname.startsWith("/s");
  const isMobileApp = window.navigator.userAgent.includes("THDMXAPP");
  const dispatch = useDispatch();

  useEffect(() => {
    setPDP(
      productListState.length >= 1 || (breadcrumbs?.length !== 0 && breadcrumbs[0]?.type_ === "FACET_ENTRY_CATEGORY")
        ? false
        : true
    );
  }, [productListState, breadcrumbs]);

  useEffect(() => {
    currentProduct && dispatch(resetProductListAction());

    if(location?.pathname?.startsWith(ACCOUNT)) {
      const homeMainContainer = document.getElementById("home--footer-container");
      if(homeMainContainer){
        homeMainContainer.classList.remove('footer-main-container');
      }
    }
  }, []);

  if (storeBuyableExclude || isMobileApp) return null;

  return breadcrumbs && breadcrumbs.length ? (
    <StyledBreadcrumbContainer variant="bodySubText" component="div">
      <StyledBreadcrumbs>
        <Link to={"/"} className="breadcrumb-first-element">
          <StyledChip className="breadcrumb-chip" avatar={<HomeIcon />} label={"Inicio"} />
        </Link>
        {breadcrumbs.map((breadcrumb: any, index: number) =>
          index < breadcrumbs.length - 1 && breadcrumb.seo && breadcrumb.seo.href ? (
            <>
              {breadcrumb.disabled ? (
                <StyledChip
                  className="breadcrumb-chip"
                  label={breadcrumb.label}
                  disabled={breadcrumb.disabled ? true : false}
                />
                
              ) : (
                <Link
                  to={
                    breadcrumb?.type_ === "FACET_ENTRY_CATEGORY" && !urlWishList
                      ? `${CATALOG_PREFIX}${breadcrumb.seo.href}`
                      : breadcrumb.seo.href
                  }
                  color="inherit"
                  state={{ breadCrumbTrailEntryView: breadcrumbs.slice(0, index + 1) }}
                  key={breadcrumb.value}
                  id={`breadcrumb_a_1_${cid}_${index}`}>
                  <StyledChip className="breadcrumb-chip" label={breadcrumb.label} />
                </Link>
              )}
            </>
          ) : (
            !onPDP &&
            !urlWishList &&
            !currentProduct && (
              breadcrumb.label?.includes("COMBO") ? 
              <>
                <StyledChip
                  key={`${breadcrumb.value}_${index}`}
                  className="breadcrumb-last-element"
                  label={`SKU ${hdmPDPproductData?.partNumber}`}
                />
              </>
              :
              <StyledChip
                key={`${breadcrumb.value}_${index}`}
                className="breadcrumb-last-element"
                label={breadcrumb.label}
              />
            )
          )
        )}
        {/*Add Sku at the end of the breadcrumb*/}
        {currentProduct && (
          <StyledChip className="breadcrumb-chip color-gray200" label={`SKU ${currentProduct}`} disabled={true} />
        )}
      </StyledBreadcrumbs>
    </StyledBreadcrumbContainer>
  ) : (
    withSkeletonLoader(<></>, isSearchPage, <SLPLPBreadCrumbComponent padding/>)
  );
};
