/*
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */

export const CONSTANTS = {
  thumbnail: "thumbnail",
  name: "name",
  orderItemInventoryStatus: "orderItemInventoryStatus",
  quantity: "quantity",
  orderItemPrice: "orderItemPrice",
  orderItemActions: "orderItemActions",
  bopis: "PickupInStore",
  delivery: "Mail",
  split: "split",
  bopisShipType: "PICK",
  deliveryShipType: "SHP_CART",
  x_realtimeInventory: "true",
  x_bopis: "PICK",
  x_d: "SHP",
  x_split: "",
  x_page: "cart",
  x_pageLoad: "all",
  x_deliverType: "SHP_CART",
  error_fleet: "_ERR_FLEET_DOWN",
  som_service_not_available: "CWXCO2000E",
  error_server: "ERR_INTERNAL_SERVER_ERROR",
  x_pageCartItemUpdate: "cart",
  x_pageLoadCartItemUpdate: "itemupdate",
  x_transfer: "TRANSFER_CART",
  installService: "Install",
  CART_BACKDROP_LOADER_TIME: 4000,
  icons: {
    openPay: "layout/checkout/PBT-Black.svg",
    paypal: "layout/checkout/Paypal.png",
    oxxoPay: "layout/checkout/oxxo-pay.png",
    oxxo: "layout/checkout/oxxo.png",
  },
  stringMonths: {
    "01": "ene",
    "02": "feb",
    "03": "mar",
    "04": "abr",
    "05": "may",
    "06": "jun",
    "07": "jul",
    "08": "ago",
    "09": "sep",
    "10": "oct",
    "11": "nov",
    "12": "dic",
  },
  numberMonths: {
    ene: "01",
    feb: "02",
    mar: "03",
    abr: "04",
    may: "05",
    jun: "06",
    jul: "07",
    ago: "08",
    sep: "09",
    oct: "10",
    nov: "11",
    dic: "12",
  },
  promotions: {
    "Artículo de Regalo": "Con regalo",
    "Promoción Cruzada": "Ahorra más",
    "Precio Mayoreo": "Precio mayoreo",
  },
  nameCookieShippingMethod: "WC_Shipping_Content",
  cookieHomeDelivery: "HomeDelivery",
  cookieBopis: "BOPIS",
  cookieSplit: "SplitOrder",
  paypalErrors: ["ERR_PAY_AT_STORE_ERR_MSG_GENERIC","ERR_PAYPAL_GENERIC","ERR_PAYPAL_TRANSACTION_REJECTED"],
  codErrors: ["ERR_PAY_AT_STORE_DISABLED"],
  oxxoErrors: ["_ERR_COMMAND_EXCEPTION"]
};
