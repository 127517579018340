import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useSite } from "../../../_foundation/hooks/useSite";
import { onlineStoreRdc } from "../../../redux/selectors/hdmData";
import { currentStoreSelector } from "../../../redux/selectors/current-store";
import { loginStatusSelector } from "../../../redux/selectors/user";
import { userIdSelector } from "../../../redux/selectors/user";

import axios from "axios";
import { getCookieValue, setCookieCAPI } from "./cookieHandler";
import * as DLUtils from "../Datalayer-Utils/dataLayer-func";
import BTUpdateEvent from "../blue-triangle-hdm/BTUpdateEvent";
import { saveVTFlag, verifyVT } from "../blue-triangle-hdm/blue-triangle-functions";
import { triggerConversionTikTokPageView, triggerConversionTikTokSuscribeEvent } from "./tiktok/tiktok-conversion-utils";
import { getExternalId } from "./pinterest/pinterest-conversion-utils";
import { removeGuestMetaAttributes } from "./meta/meta-convertions-utils";

const PageViewEvent = () => {
  const uId = useSelector(userIdSelector);
  const storeConfData = useSelector(onlineStoreRdc);
  const isCAPIEnabled = storeConfData?.ENABLE_CAPI_TARGET_CALL;
  const isBTEnabled = storeConfData?.BT_ACTIVE_FLAG;
  const btEnvironment = storeConfData?.BT_ENVIRONMENT;
  const currentStore = useSelector(currentStoreSelector);
  const storeDetails = currentStore.currentStore;
  const userLoggedIn = useSelector(loginStatusSelector);

  const eventIdName = "_mfbt";
  const { mySite } = useSite();

  const [previousURL, setPreviousURL] = useState("");
  const [storeExist, setStoreExist] = useState(true);
  const [eventTriggered, setEventTriggered] = useState(true);
  const [counterStore, setCounterStore] = useState(0);

  useEffect(() => {
    setCounterStore(counterStore + 1);
    if (storeDetails && storeExist) {
      if (userLoggedIn) {
        if (counterStore > 3) {
          triggerPageViewDL();
          setEventTriggered(false);
          setPreviousURL(window.location.pathname);
          setStoreExist(false);
        }
      } else {
        triggerPageViewDL();
        setEventTriggered(false);
        setPreviousURL(window.location.pathname);
        setStoreExist(false);
      }
    }
  }, [storeDetails]);

  useEffect(() => {
    const handleURLChange = () => {
      const currentPt = window.location.pathname;
      if (storeDetails) {
        if (!currentPt.includes("mapa-sitio")) {
          triggerPageViewDL();
          setEventTriggered(false);
          setPreviousURL(window.location.pathname);
        }
      } else {
        setStoreExist(true);

        if (window.location.pathname.includes("mapa-sitio") && eventTriggered) {
          triggerPageViewDL();
          setEventTriggered(false);
        }
      }
    };
    saveVTFlag(previousURL);
    removeGuestMetaAttributes();

    if (storeConfData) {
      if (isBTEnabled && isBTEnabled != "false") {
        try {
          btStartEvent(btEnvironment);
        } catch (error) {
          console.log("Error triggering bt", error);
        }
      }
      console.log(`PageName: ${verifyVT()}`);
    }

    try {
      handleURLChange();
    } catch (e) {
      console.log("PageView DL error: " + e);
    }

    try {
      if (window.location.pathname.includes("suscripcion-completa")) {
        triggerSuscribeEvent();
      }
    } catch (e) {
      console.log("PageView CAPI error: " + e);
    }
  }, [window.location.pathname]);

  const triggerSuscribeEvent = async () => {
    const eventId = setCookieCAPI("mfbt");
    const fbc = getCookieValue("_fbc");
    const fbp = getCookieValue("_fbp");
    const userAgent = window.navigator.userAgent;
    const eventSource = window.location.href;
    const externalId = getExternalId();
    const email = localStorage.getItem("susEmail");
    await axios
      .get(
        `/wcs/resources/store/${mySite.storeID}/hdm/facebook/subscribe?responseFormat=json&mfbt=${eventId}&fbc=${fbc}&fbp=${fbp}&userAgent=${userAgent}&eventSource=${eventSource}&email=${email}&externalId=${externalId}`
      )
      .then((res) => {
        if (res && res?.data) {
          console.log("Service working good ");
        }
        console.log("Response value suscribe:  " + res);
      })
      .catch((error) => {
        console.log("Error suscribe: " + error);
      });
      triggerConversionTikTokSuscribeEvent(mySite.storeID, email);
    localStorage.removeItem("susEmail");
  };

  /**Responsible to trigger DataLayer logic related evPageView*/
  const triggerPageViewDL = async () => {
    const userAgent = navigator.userAgent;
    let [actualShopCity, actualShopState, actualShopName] = DLUtils.storeDetailsStruct(storeDetails);

    let prevURL = "NA";
    if (previousURL && previousURL != "") {
      prevURL = previousURL;
    }

    if (!actualShopCity && window.location.href.includes("mapa-sitio")) {
      [actualShopCity, actualShopState, actualShopName] = DLUtils.getStoreMapSite();
    }

    window["dataLayer"].push({
      event: "evPageViewInfo",
      page_actual_domain: decodeURI(window.location.href),
      page_user_came: decodeURI(prevURL),
      page_actual_path: decodeURI(DLUtils.currentPath()),
      user_weekday: DLUtils.userWeekday(),
      user_monthday: DLUtils.userMonthday(),
      user_time: DLUtils.userTime(),
      user_browser: DLUtils.userBrowser(userAgent),
      user_device: DLUtils.userDevice(userAgent),
      user_os: DLUtils.userOS(userAgent),
      paid_media_source: "NA",
      paid_media_medium: "Organic",
      paid_media_campaign: "NA",
      paid_media_content: "NA",
      paid_media_term: "NA",
      paid_media_gclid: "NA",
      paid_media_fbclid: "NA",
      user_status_login: userLoggedIn, //TODO
      user_id: DLUtils.validateUserId(uId, userLoggedIn), //TODO
      actual_shop_city: actualShopCity, //TODO
      actual_shop_state: actualShopState,
      actual_shop_name: actualShopName,
    });
  };

  /**Set start event to BlueTriangle*/
  const btStartEvent = async (btEnvironment) => {
    const pageNameVal = verifyVT();
    if (btEnvironment) {
      window["bttUT"].start({ pageName: pageNameVal, txnName: btEnvironment });
    }
  };

  return (
    <>
      <BTUpdateEvent />
    </>
  );
};

export default PageViewEvent;
