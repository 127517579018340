//Standard libraries
import React, { useEffect, useRef, useState } from "react";
//Foundation libraries
import {
  ContactUsChannelsContainer,
  ContactUsContainer,
  ContactUsWrapper,
} from "./ContactUsStyles";
import { StyledBox, StyledGrid, StyledTypography } from "../../../hdm";
import EspotHdm from "../../custom-components/shared/EspotHdm";
import { StyledDivider } from "../../header/categories-menu/categories-styles";

// Icons
import { useMediaQuery } from "@material-ui/core";
import LiveHelpIcon from "@material-ui/icons/LiveHelp";
import { ReactComponent as WhatsappIcon } from "../../../assets/homedepot/images/whatsapp.svg";
import SmsIcon from "@material-ui/icons/Sms";
import { ReactComponent as FaceAgent } from "../../../assets/homedepot/images/face-agent.svg";
import EmailIcon from "@material-ui/icons/Email";
import StoreIcon from "@material-ui/icons/Store";
import RoomIcon from "@material-ui/icons/Room";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";

// Utils
import { useTheme } from "@material-ui/core/styles";
import DangerouslySetHtmlContent from "../../custom-components/DangerouslySetHtmlContent/DangerouslySetHtmlContent";
import { CLOSE_BACKDROP_LOADER_ACTION } from "../../../redux/actions/backdrop-loader";
import { useDispatch } from "react-redux";

interface ContactUsUiProps {
  json?: any;
}
const ContactUsUi = (props: ContactUsUiProps) => {
  /* Initial state */

  const cardIcons = [<LiveHelpIcon />, <WhatsappIcon />, <SmsIcon />, <FaceAgent />, <EmailIcon />, <StoreIcon />];

  let channelData: any = {};
  try {
    channelData = props?.json? JSON.parse(props?.json) : {};
  }
  catch(e){
    console.error("Error parsing channels",e)
  }

  // Responsive functions
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.between(601, 1024));
  const isMobile = useMediaQuery(theme.breakpoints.down(600));

  // Espot cards refs
  const leftEspot = useRef<HTMLDivElement | null>(null);
  const rightEspot = useRef<HTMLDivElement | null>(null);

  const [showInferiorEspots, setShowInferiorEspots] = useState({
    showLeftEspot: false,
    showRightEspot: false,
  });

  const { showLeftEspot, showRightEspot } = showInferiorEspots;

  // Validate if inferior Espots have data
  useEffect(() => {
    setTimeout(() => {
      const leftEspotContent = !!leftEspot?.current?.innerHTML;
      const rightEspotContent = !!rightEspot?.current?.innerHTML;

      setShowInferiorEspots((prevState): any => ({
        ...prevState,
        showLeftEspot: leftEspotContent ? true : false,
        showRightEspot: rightEspotContent ? true : false,
      }));
    }, 300);
  }, [leftEspot, rightEspot]);

  const hyperLinkHandler = (linkText) => {
    if (linkText.includes("correo")) {
      window.location.href = "mailto:callcenter@homedepot.com.mx";
    }
  };

  return (
    <>
      <ContactUsWrapper id="Contact-us">
        <ContactUsContainer container>
          {/* Main title */}
{/*           <StyledGrid item xs={12}>
            <StyledBox className="middle-line" />
            <StyledTypography variant="headingMainTitle" component="h1" className="contact-us-main-title">
              {"CONTÁCTANOS"}
            </StyledTypography>
          </StyledGrid> */}

          {/* Superior Espot */}
{/*           <StyledGrid item lg={12} className="vertical-margins">
            <EspotHdm espotName="ContactUs_SuperiorSectionTop" />
          </StyledGrid> */}

          {/* inferior Espots */}
{/*           <StyledGrid container xs={12} className="espot-cards-container">
            <StyledGrid
              item
              xs={isMobile || isTablet ? 12 : 6}
              className={`vertical-margins ${showLeftEspot ? "espot-card" : null}`}>
              <div ref={leftEspot}>
                <EspotHdm espotName="ContactUs_SuperiorSectionLeft" />
              </div>
            </StyledGrid>
            <StyledGrid
              item
              xs={isMobile || isTablet ? 12 : 6}
              className={`vertical-margins ${showRightEspot ? "espot-card" : null}`}>
              <div ref={rightEspot}>
                <EspotHdm espotName="ContactUs_SuperiorSectionRight" />
              </div>
            </StyledGrid>
          </StyledGrid> */}

          {/* Channels section */}
          <ContactUsChannelsContainer container lg={12}>
            {/* Channels title */}
            <StyledGrid item lg={12} className="vertical-margins">
              <StyledBox className="reviews-container divider">
                <StyledTypography
                  variant="headingSubhead"
                  component="h2"
                  className="channels-title primary-color-divider">
                  {channelData?.MainTitle}
                </StyledTypography>
                <StyledBox className="gray-color-divider" />
              </StyledBox>
            </StyledGrid>

            {/* Channels cards */}
            <StyledGrid spacing={1} container className="cards-container">
              {channelData?.contactUsChannels?.map((channel, index) => (
                <StyledGrid item xs={12} md={12} lg={4} className="channel-card vertical-margins" key={channel?.title}>
                  {/* title */}
                  <StyledGrid container lg={12} className="margin-bottom-5">
                    {cardIcons[index]}
                    <StyledTypography variant="headingFeatured" className="channel-title">
                      {channel?.title}
                    </StyledTypography>
                  </StyledGrid>
                  {/* description */}
                  <StyledGrid item lg={12} variant="bodyBaseline">
                    <StyledTypography className="margin-bottom-10">
                      <DangerouslySetHtmlContent html={channel?.description} />
                    </StyledTypography>
                    <StyledDivider className="margin-bottom-10" />
                  </StyledGrid>
                  {/* Assitance */}
                  <StyledGrid item lg={12} className="margin-bottom-10 margin-top-5">
                    <StyledTypography variant="bodySubText" weight="bold">
                      {channel?.virtualAssistant?.subtitle}
                    </StyledTypography>
                    <StyledTypography variant="bodySubText">{channel?.virtualAssistant?.content}</StyledTypography>
                  </StyledGrid>
                  {/* schedules */}
                  <StyledGrid item lg={12} className="margin-bottom-10">
                    <StyledTypography variant="bodySubText" weight="bold">
                      {channel?.schedules?.subtitle}
                    </StyledTypography>
                    <StyledTypography variant="bodySubText">{channel?.schedules?.weekdays}</StyledTypography>
                    <StyledTypography variant="bodySubText">{channel?.schedules?.weekends}</StyledTypography>
                  </StyledGrid>
                  {/* Hyperlink */}
                  <StyledGrid container lg={12}>
                    <DangerouslySetHtmlContent html={channel?.hyperlinkTitle} />
                  </StyledGrid>
                </StyledGrid>
              ))}
            </StyledGrid>
          </ContactUsChannelsContainer>
        </ContactUsContainer>
      </ContactUsWrapper>
    </>
  );
};

export default ContactUsUi;
